import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import SessionApi from '../../../src/api/sessionApi';
import useAnalytics from '../../../src/hooks/useAnalytics';

const Login: React.FC = (props: any) => {
  const namePage = 'login';
  useAnalytics(namePage);
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);

  useEffect(() => {
    console.log(get(page, 'params.token', null));
    let token = get(page, 'params.token', null);
    if (token !== null) {
      localStorage.setItem('token', token);
    }
    SessionApi.loginAndRedirect(`${window.location.origin}/funnel`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <span style={{ display: 'none' }} data-test-id='pageId'>
      {namePage}
    </span>
  );
};

export default Login;
