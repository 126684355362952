import { O2Indent, O2Section, O2InfoMessage } from 'o2-theme-react';
import React from 'react';
import PageTitle from '../../../src/components/common/PageTitle';
import { useSelector } from 'react-redux';
import Langtext from '../../../src/components/common/Langtext';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const CannotUpdateFw: React.FC = (props: any) => {
  const namePage = 'cannotUpdateFirmware';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const serviceId = useSelector<any, any>(state => state.pages.selectedValueId);

  return (
    <>
      <PageTitle
        to='/funnel/select-hdm-function'
        backlink={<Langtext text='funnel.cannotUpdateFirmware.topLink' />}
      >
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.cannotUpdateFirmware.title' />
      </PageTitle>
      <O2Section>
        <O2Indent>
          {serviceId}-{page.params.modemName}
        </O2Indent>
        <O2Indent>
          <O2Indent>
            <Langtext text='funnel.cannotUpdateFirmware.text' />
          </O2Indent>
          <O2Indent>
            <O2InfoMessage color='good'>
              <Langtext text='funnel.cannotUpdateFirmware.lineText' />
            </O2InfoMessage>
          </O2Indent>
        </O2Indent>
      </O2Section>
    </>
  );
};

export default CannotUpdateFw;
