import { O2Indent, O2Section } from 'o2-theme-react';
import React from 'react';

import PageTitle from '../../../src/components/common/PageTitle';
import Langtext from '../../../src/components/common/Langtext';
import useAnalytics from '../../../src/hooks/useAnalytics';

const Error: React.FC = () => {
  const namePage = 'error';
  useAnalytics(namePage);

  return (
    <>
      <PageTitle>
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.error.title' />
      </PageTitle>
      <O2Section>
        <O2Indent>
          <p>
            <Langtext text='funnel.error.description' />
          </p>
        </O2Indent>
      </O2Section>
    </>
  );
};

export default Error;
