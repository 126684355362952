import React, { useEffect } from 'react';
import {
  // useDispatch,
  useSelector,
} from 'react-redux';
import PageTitle from '../../../src/components/common/PageTitle';
import Langtext from '../../../src/components/common/Langtext';
import {
  O2Addon,
  O2AddonContent,
  O2Indent,
  O2InfoMessage,
  O2Button,
} from 'o2-theme-react';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const NotSupportedModem = () => {
  const namePage = 'notSupportedModem';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  // const state = useSelector<any, any>(state => state);
  const notSupportedModem = useSelector<any, any>(
    state => state.pages.pages.notSupportedModem
  );
  const { isRedirect } = notSupportedModem;
  const config = useSelector<any, any>(state => state.config.o2Config);
  const link = config['link.notSupportedModem'];

  const goToExternal = () => {
    window.location.href = link;
  };

  useEffect(() => {
    if (isRedirect) goToExternal();

    // eslint-disable-next-line
  }, [notSupportedModem]);

  if (isRedirect) return null;

  return (
    <>
      <PageTitle>
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.notSupportedModem.title' />
      </PageTitle>

      <O2Indent style={{ marginTop: '16px' }}>
        <O2InfoMessage color='internal'>
          <O2Addon reversed>
            <O2AddonContent>
              <O2Indent>
                <Langtext text='funnel.notSupportedModem.msg' />
              </O2Indent>
            </O2AddonContent>
          </O2Addon>
        </O2InfoMessage>
      </O2Indent>

      <O2Indent style={{ marginTop: '16px' }}>
        <O2Button color='primary' onClick={() => goToExternal()}>
          <Langtext text='funnel.notSupportedModem.btn' />
        </O2Button>
      </O2Indent>
    </>
  );
};

export default NotSupportedModem;
