import {
  O2Addon,
  O2AddonContent,
  O2AddonPane,
  O2Indent,
  O2Pane,
} from 'o2-theme-react';
import React from 'react';
import { Link } from 'react-router-dom';

import O2Steps from './O2Steps';

const Step = (props: any) => {
  return (
    <O2Pane color={props.isActive ? 'white' : 'grey'}>
      <O2Addon reversed>
        <O2AddonPane indent>
          <O2Steps num={props.step} isActive={props.isActive} />
        </O2AddonPane>
        <O2AddonContent>
          <O2Addon>
            <O2AddonContent>
              <O2Indent>
                <h5 className='o2web-typo__heading'>{props.title}</h5>
                <p>{props.subTitle}</p>
              </O2Indent>
              <O2Indent>{props.text}</O2Indent>
              {props.children}
            </O2AddonContent>
            <O2AddonPane>
              {props.link && <Link to={props.link}>{props.linkText}</Link>}
              {!props.link && (
                <a
                  href='/'
                  onClick={e => {
                    e.preventDefault();
                    props.linkOnClick();
                  }}
                >
                  {props.linkText}
                </a>
              )}
            </O2AddonPane>
          </O2Addon>
        </O2AddonContent>
      </O2Addon>
    </O2Pane>
  );
};

export default Step;
