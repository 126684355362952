import Langtext from '../../../src/components/common/Langtext';
import PageTitle from '../../../src/components/common/PageTitle';
import TableRow from '../../../src/components/common/TableRow';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import get from 'lodash/get';
import {
  O2Button,
  O2Indent,
  O2Loader,
  O2Pane,
  O2Section,
  O2Table,
  O2TableBody,
  O2TableHeader,
  O2TableHeaderCell,
  O2TableRow,
  O2Wrap,
} from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';
import { Trouble } from '../../../src/api/types';
import { I18n } from 'react-i18nify-lite';

// import LazyLoadHook from '../../../src/components/common/LazyLoadHook';
const TroubleList: any = (props: any) => {
  const namePage = 'troubleList';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();

  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const stateTroubles = useSelector<any, any>(state =>
    get(state, `pages.pages[${namePage}].troubles`, null)
  );

  const lazyload = useSelector<any, any>(
    state => state.pages.pages[namePage].lazyLoad
  );
  const historyStatusCssClass = useSelector<any, any>(
    state => state.appCache.codeLists.historyStatusCssClass
  );
  const historyTypeCssClass = useSelector<any, any>(
    state => state.appCache.codeLists.historyTypeCssClass
  );
  const lazyLoadBatch = useSelector<any, any>(
    state => state.pages.lazyLoadBatch
  );
  const [troubles, setTroubles] = useState([]);
  //LOAD MORE UNUSED FIX WITH LAZYLOAD TURN ON
  // eslint-disable-next-line
  const [loadMore, setLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);
  // const [isFetching, setIsFetching] = LazyLoadHook(fetchMoreItems, loadMore);

  useEffect(() => {
    if (!page.troubles) {
      dispatch(
        ttsProcess({
          page: 'main',
          params: {
            action: 'troubleList',
          },
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTroubles(stateTroubles);
  }, [stateTroubles]);

  useEffect(() => {
    setLoadMore(true);
    if (!lazyload) {
      setLoadMore(false);
    }
    // eslint-disable-next-line
  }, [page.lazyLoad, lazyLoadBatch]);

  function fetchMoreItems() {
    setLoading(true);
    setTimeout(() => {
      dispatch(
        ttsProcess({
          page: namePage,
          params: {
            lazyloadBatch: lazyLoadBatch + 1,
          },
        })
      );
      setLoadMore(false);
      setLoading(false);
      // setIsFetching(false);
    }, 3000);
  }

  const getCorrectStateText = (trouble: Trouble): string | React.ReactNode => {
    if (
      trouble.type !== '' &&
      trouble.type !== null &&
      I18n.t(`funnel.codelist.historyTypeText.${trouble.type}`) !== trouble.type
    ) {
      return (
        <Langtext text={`funnel.codelist.historyTypeText.${trouble.type}`} />
      );
    } else {
      return (
        <Langtext text={`funnel.codelist.historyStatusText.${trouble.state}`} />
      );
    }
  };

  const getCorrectCssClass = (trouble: Trouble): string => {
    if (
      trouble.type !== '' &&
      trouble.type !== null &&
      historyTypeCssClass[trouble.type]
    ) {
      return historyTypeCssClass[trouble.type];
    } else {
      return historyStatusCssClass[trouble.state];
    }
  };

  const formatDate = (date: any) => {
    let dateObj = new Date(Date.parse(date));
    let year = String(dateObj.getFullYear());
    let month = String(dateObj.getMonth() + 1);
    let day = String(dateObj.getDate());
    let hour = String(dateObj.getHours());
    let currentMinutes: any = dateObj.getMinutes();
    currentMinutes = ('0' + currentMinutes).slice(-2);
    let minute = String(currentMinutes);

    if (Number(day) < 10) {
      day = '0' + day;
    }
    if (Number(month) < 10) {
      month = '0' + month;
    }
    return `${day}.${month}.${year} ${hour}:${minute}`;
  };

  return (
    <>
      <PageTitle>
        <Langtext text='funnel.troubleList.title' />
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
      </PageTitle>
      <O2Section>
        <O2Section outdent>
          {troubles && troubles.length > 0 && (
            <>
              <O2Table>
                <O2TableHeader>
                  <O2TableRow>
                    <O2TableHeaderCell>
                      <Langtext text='funnel.troubleList.issue' />
                    </O2TableHeaderCell>
                    <O2TableHeaderCell>
                      <Langtext text='funnel.troubleList.service' />
                    </O2TableHeaderCell>
                    <O2TableHeaderCell>
                      <Langtext text='funnel.troubleList.setupDate' />
                    </O2TableHeaderCell>
                    <O2TableHeaderCell>
                      <Langtext text='funnel.troubleList.status' />
                    </O2TableHeaderCell>
                    <O2TableHeaderCell></O2TableHeaderCell>
                  </O2TableRow>
                </O2TableHeader>
                <O2TableBody>
                  {historyStatusCssClass &&
                    troubles.map((row: any) => (
                      <TableRow
                        type={row.type}
                        history={props.history}
                        key={shortid.generate()}
                        // troubleId={row.troubleId}
                        troubleId={row.id}
                        issue={
                          <Langtext
                            text={`funnel.codelist.historyTroubleNames.${row.troubleId}`}
                          />
                        }
                        service={row.serviceId}
                        serviceType={row.serviceType}
                        testId='troubleLinks'
                        date={formatDate(row.created)}
                        state={getCorrectStateText(row)}
                        statusClass={getCorrectCssClass(row)}
                      />
                    ))}
                </O2TableBody>
              </O2Table>
              {lazyload && !loading && (
                <O2Indent>
                  <O2Button
                    data-test-id='lazyloadButton'
                    onClick={() => fetchMoreItems()}
                    style={{ marginTop: '16px' }}
                  >
                    <Langtext text='funnel.troubleList.loadMore' />
                  </O2Button>
                </O2Indent>
              )}
            </>
          )}
          {loading && (
            <O2Pane noBorder>
              <O2Wrap center>
                <O2Loader />
              </O2Wrap>
            </O2Pane>
          )}
          {troubles && troubles.length === 0 && (
            <O2Pane>
              <O2Loader noResults={true}>
                <Langtext text='funnel.troubleList.emptyHistoryMsg' />
              </O2Loader>
            </O2Pane>
          )}
        </O2Section>
      </O2Section>
    </>
  );
};

export default TroubleList;
