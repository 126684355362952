import { O2Indent } from 'o2-theme-react';
import React, { useState } from 'react';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import get from 'lodash/get';
import HTMLBody from '../../../src/components/common/HTMLBody';
import { useSelector } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const Information: React.FC = () => {
  const namePage = 'information';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const [destinationId] = useState(get(page, 'ttsParams.destinationId', null));

  return (
    <SimpleTextPage
      title={
        destinationId ? (
          <Langtext
            text={`funnel.codelist.destinationToTitle.${destinationId}`}
          />
        ) : (
          <Langtext text='funnel.information.title' />
        )
      }
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        {destinationId && <HTMLBody id={destinationId} />}
        {!destinationId && <Langtext text='funnel.information.text' />}
      </O2Indent>
    </SimpleTextPage>
  );
};

export default Information;
