import Langtext from '../../../src/components/common/Langtext';
import PageTitle from '../../../src/components/common/PageTitle';
import { clearSuccess } from '../../../src/redux/actions/pageActions';
import { ttsProcessWaiting } from '../../../src/redux/actions/ttsActions';
import { O2Indent, O2Section } from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const OutagesWaiting: React.FC = (props: any) => {
  const history = useHistory();
  useHandleInappropriateRefresh();
  const timeout = useSelector<any, any>(
    state => state.config.o2Config['other.waiting.timeout']
  );
  const delay = useSelector<any, any>(
    state => state.config.o2Config['other.waiting.checkDelay']
  );
  const waitingSuccess = useSelector<any, any>(
    state => state.pages.waitingSuccess
  );
  const serviceId = useSelector<any, any>(
    state => state.paramsHolder.serviceId
  );
  const intervalPercent = 100 / (Number(timeout) / Number(delay));
  const namePage = 'waiting';
  const { setEventName } = useAnalytics(namePage);
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const [progress, setProgress] = useState<number>(0);
  const [time, setTime] = useState<number>(0);
  const addProgress = (value: number) => {
    setProgress(value);
  };

  useEffect(() => {
    setEventName('funnel_diagnosticsResult');

    // return () => setEventName('funnel_diagnosticsResult');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const timer = window.setInterval(() => {
      setTime(time + 1);
    }, Number(delay) * 1000);
    return () => {
      window.clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [time]);

  useEffect(() => {
    if (page) {
      if (progress >= 100) {
        if (serviceId !== null) {
          history.push('/funnel/information-chat');
        }
      } else {
        if (serviceId !== null) {
          if (!waitingSuccess.isSuccess) {
            dispatch(
              ttsProcessWaiting({
                page: namePage,
                params: {
                  serviceId: serviceId,
                },
              })
            );
          }
          if (waitingSuccess.isSuccess) {
            addProgress(99);
            dispatch(clearSuccess());
            props.history.push(waitingSuccess.nextPage);
          }
          addProgress(progress + intervalPercent);
        } else {
          if (!waitingSuccess.isSuccess) {
            dispatch(
              ttsProcessWaiting({
                page: namePage,
                params: {},
              })
            );
          }
          if (waitingSuccess.isSuccess) {
            addProgress(99);
            dispatch(clearSuccess());
            props.history.push(waitingSuccess.nextPage);
          }
          addProgress(progress + intervalPercent);
        }
      }
    }
    // eslint-disable-next-line
  }, [time, waitingSuccess]);

  return (
    <>
      <PageTitle>
        <Langtext text='funnel.waiting.title' />
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
      </PageTitle>

      <O2Section>
        <O2Indent standalone>
          <div className='o2-usagebar'>
            <div className='o2-usagebar__info'>
              <div className='o2-usagebar__value'>
                <Langtext text='funnel.waiting.text' />
              </div>
            </div>
            <div className='o2-usagebar__bar-row'>
              <div
                className='o2-usagebar__bar-state o2-usagebar__bar-float-number js-bar-width'
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        </O2Indent>
      </O2Section>
    </>
  );
};

export default OutagesWaiting;
