import { combineReducers } from 'redux';

import StoreState from '../store/storeState';
import user from './userReducer';
import config from './configReducer';
import pages from './pageReducer';
import appCache from './appCacheReducer';
import paramsHolder from './paramsHolderReducer';
import kahlInfo from './kahlInfoReducer';
import analytics from './analyticsReducer';
import sharedObjects from './sharedObjectsReducer';

const rootReducer = combineReducers<StoreState>({
  user,
  config,
  pages,
  appCache,
  paramsHolder,
  kahlInfo,
  analytics,
  sharedObjects
});

export default rootReducer;
