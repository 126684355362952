import { O2Indent, O2Button, O2Icon } from 'o2-theme-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const TechnicianVisitNoDate: React.FC = (props: any) => {
  const namePage = 'termNotFound';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();
  const troubleId = useSelector<any, any>(
    state => state.pages.pages['setTerm'].troubleId
  );
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);
  const serviceId = useSelector<any, any>(
    state => state.paramsHolder.serviceId
  );

  const proceed = () => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: serviceId,
          },
          ttsParams: {
            ...ttsParams,
            flowType: 'TT_TERM_CHANGE',
            ttId: troubleId,
          },
        },
        props
      )
    );
  };

  return (
    <SimpleTextPage
      title={<Langtext text='funnel.termNotFound.title' />}
      backlink={<Langtext text='funnel.termNotFound.topLink' />}
      backlinkTo='/funnel/trouble-list'
      hasBacklink={true}
    >
      <O2Indent>
        <Langtext text='funnel.termNotFound.text' />
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
      </O2Indent>
      <O2Indent>
        <O2Button
          color='transparent'
          firstLink={true}
          onClick={() => proceed()}
        >
          <O2Icon name='chevron-left' size='sm' indentRight />
          <Langtext text='funnel.termNotFound.button' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default TechnicianVisitNoDate;
