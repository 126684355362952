import { Wireframes } from '../reducers/types';
import WireframesAPI from '../../api/wireframesApi';

export type Action = {
  type: 'INIT_WIREFRAMES';
  payload: Wireframes | any;
};

const initWireframes = (data: any): Action => {
  return {
    type: 'INIT_WIREFRAMES',
    payload: data,
  };
};

export const getWireframes = (): any => {
  const api = new WireframesAPI();
  return (dispatch: any) => {
    api
      .wireframesCall()
      .then((res: any) => {
        dispatch(initWireframes(res.data));
      })
      .catch((err: any) => console.log(err));
  };
};
