import { sendGet } from './requestUtils';

const CODELIST_API_PATH = '/api/codelist/public/v1/codelist';

const codeListMap: any = {
  destinationToPage: 'destinationToPage',
  destinationToChatPage: 'destinationToChatPage',
  destinationToChatType: 'destinationToChatType',
  historyStatusCssClass: 'historyStatusCssClass',
  historyTypeText: 'historyTypeText',
  historyTypeCssClass: 'historyTypeCssClass',
  hdmTripplePlayToImage: 'hdmTripplePlayToImage',
  destinationToOption1Link: 'destinationToOption1Link',
  destinationToOption2Link: 'destinationToOption2Link',
  ttsServiceTypeToGroup: 'ttsServiceTypeToGroup',
  ttsServiceTypeHistoryName: 'ttsServiceTypeHistoryName',
  destinationToOption1Action: 'destinationToOption1Action',
  destinationToOption2Action: 'destinationToOption2Action',
};

export default class CodeListAPI {
  async codeListCall(endpoint: string) {
    return sendGet<any>(
      `${CODELIST_API_PATH}/${codeListMap[endpoint]}`,
      null,
      'json'
    );
  }

  public getCodeListEndpoints() {
    return codeListMap;
  }
}
