import Langtext from '../../../src/components/common/Langtext';
import PageTitle from '../../../src/components/common/PageTitle';
import { saveTroubleId } from '../../../src/redux/actions/pageActions';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import {
  O2Button,
  O2ButtonGroup,
  O2Field,
  O2FieldContent,
  O2FieldControls,
  O2Icon,
  O2Indent,
  O2InlineState,
  O2Loader,
  O2Pane,
  O2Section,
  O2Wrap,
} from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import JournalApi from '../../../src/api/journalApi';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';
import { Trouble } from '../../../src/api/types';

const TroubleListDetails: any = (props: any) => {
  const dispatch = useDispatch();
  const namePage = 'troubleDetail';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const page = useSelector<any, any>(state => state.pages.pages['troubleList']);
  const pageOriginal = useSelector<any, any>(
    state => state.pages.pages['troubleDetail']
  );
  const [trouble, setTrouble] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const historyStatusCssClass = useSelector<any, any>(
    state => state.appCache.codeLists.historyStatusCssClass
  );
  const historyTypeCssClass = useSelector<any, any>(
    state => state.appCache.codeLists.historyTypeCssClass
  );
  const user = useSelector<any, any>(state => state.user);
  const journalApi = new JournalApi();

  const getCorrectStateText = (trouble: Trouble): string | React.ReactNode => {
    if (
      trouble.type !== '' &&
      trouble.type !== null &&
      I18n.t(`funnel.codelist.historyTypeText.${trouble.type}`) !== trouble.type
    ) {
      return (
        <Langtext text={`funnel.codelist.historyTypeText.${trouble.type}`} />
      );
    } else {
      return (
        <Langtext text={`funnel.codelist.historyStatusText.${trouble.state}`} />
      );
    }
  };

  const getCorrectCssClass = (trouble: Trouble): string => {
    if (
      trouble.type !== '' &&
      trouble.type !== null &&
      historyTypeCssClass[trouble.type]
    ) {
      return historyTypeCssClass[trouble.type];
    } else {
      return historyStatusCssClass[trouble.state];
    }
  };

  useEffect(() => {
    const params = props.match.params;
    if (page.troubles) {
      const id = params.ttid ? params.ttid : params.id;
      // let troubles = page.troubles.filter(
      //   (item: any) => item.troubleId === Number(id)
      // );
      let troubles = page.troubles.filter(
        (item: any) => item.id === Number(id)
      );
      setTrouble(troubles[0]);
      setLoading(false);
    } else {
      const id = params.ttid ? params.ttid : params.id;
      // let troubles = pageOriginal.troubles.filter(
      //   (item: any) => item.troubleId === Number(id)
      // );
      let troubles = pageOriginal.troubles.filter(
        (item: any) => item.id === Number(id)
      );
      setTrouble(troubles[0]);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    const params = props.match.params;
    if (params.deeplink === 'history' && params.ttid) {
      setLoading(true);
      dispatch(
        ttsProcess({
          page: 'main',
          params: {
            action: 'troubleList',
            ttId: params.ttid,
          },
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  const startTtsProcess = (params: any) => {
    setLoading(true);
    dispatch(saveTroubleId(params.ttId));
    dispatch(
      ttsProcess(
        {
          page: 'troubleDetail',
          params: {
            serviceId: params.serviceId,
          },
          ttsParams: params,
        },
        props
      )
    );
  };

  const notWorkingRedirect = (trouble: any) => {
    journalApi.pingJournal({
      event: 'AGREE_WITH_TICKET_RESOLUTION',
      username: user?.userInfo?.username,
      params: {
        serviceId: trouble.serviceId,
        ttId: trouble.id.toString(),
      },
    });
    history.push('/funnel/information-chat?id=troubleNotSolved');
  };

  const formatDate = (date: any) => {
    let dateObj = new Date(date);
    let year = String(dateObj.getFullYear());
    let month = String(dateObj.getMonth() + 1);
    let day = String(dateObj.getDate());
    let hour = String(dateObj.getHours());
    let currentMinutes: any = dateObj.getMinutes();
    currentMinutes = ('0' + currentMinutes).slice(-2);
    let minute = String(currentMinutes);

    if (Number(day) < 10) {
      day = '0' + day;
    }
    if (Number(month) < 10) {
      month = '0' + month;
    }
    return `${day}.${month}.${year} ${hour}:${minute}`;
  };

  return (
    <>
      <PageTitle>
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.troubleDetail.title' />
      </PageTitle>

      {!loading && trouble && (
        <O2Section>
          <O2Indent>
            <O2Pane>
              <O2Field
                compact
                label={I18n.t('funnel.troubleDetail.serviceType')}
              >
                <O2FieldContent value={true} data-test-id='serviceTypeText'>
                  {trouble.serviceType}
                </O2FieldContent>
              </O2Field>

              <O2Field
                compact
                label={I18n.t('funnel.troubleDetail.serviceNum')}
              >
                <O2FieldContent value={true} data-test-id='serviceIdText'>
                  {trouble.serviceId}
                </O2FieldContent>
              </O2Field>
              {historyStatusCssClass && (
                <O2Field
                  compact
                  label={I18n.t('funnel.troubleDetail.issueStatus')}
                >
                  <O2FieldContent value={true} data-test-id='stateText'>
                    <O2InlineState className={getCorrectCssClass(trouble)}>
                      {getCorrectStateText(trouble)}
                    </O2InlineState>
                  </O2FieldContent>
                </O2Field>
              )}

              <O2Field
                compact
                label={I18n.t('funnel.troubleDetail.resolutionTerm')}
              >
                <O2FieldContent value={true}>
                  <span className='o2-typo--nowrap'>
                    {formatDate(trouble.solved)}
                  </span>
                </O2FieldContent>
                {trouble.termChangeButton && (
                  <O2FieldControls>
                    <O2Button
                      color='transparent'
                      data-test-id='termChangeLink'
                      onClick={() =>
                        startTtsProcess({
                          flowType: 'TT_TERM_CHANGE',
                          ttId: trouble.id,
                          serviceId: trouble.serviceId,
                        })
                      }
                      className='o2-typo--link'
                    >
                      <Langtext text='funnel.troubleDetail.change' />
                    </O2Button>
                  </O2FieldControls>
                )}
              </O2Field>

              <O2Field compact label={I18n.t('funnel.troubleDetail.issueType')}>
                <O2FieldContent value data-test-id='troubleId'>
                  <Langtext
                    text={`funnel.codelist.historyTroubleNames.${trouble.troubleId}`}
                  />
                </O2FieldContent>
              </O2Field>
              <O2Field
                compact
                label={I18n.t('funnel.troubleDetail.diagnosticResult')}
              >
                <O2FieldContent value data-test-id='diagResultText'>
                  <Langtext
                    text={`funnel.codelist.historyDiagResultText.${trouble.diagResult}`}
                  />
                </O2FieldContent>
              </O2Field>
              <O2Field compact label={I18n.t('funnel.troubleDetail.setTerm')}>
                <O2FieldContent value data-test-id='createdText'>
                  <span className='o2-typo--nowrap'>
                    {formatDate(trouble.created)}
                  </span>
                </O2FieldContent>
              </O2Field>
              {trouble.id && (
                <O2Field
                  compact
                  label={I18n.t('funnel.troubleDetail.technicalReq')}
                >
                  <O2FieldContent value>
                    <span className='o2-typo--nowrap'>{trouble.id}</span>
                  </O2FieldContent>
                </O2Field>
              )}
              <O2Field
                compact
                label={I18n.t('funnel.troubleDetail.internalSubmitter')}
                internal={true}
              >
                <O2FieldContent value data-test-id='internalCreatorText'>
                  <span className='o2-typo--nowrap'>
                    {trouble.internalCreator.includes('null') ? (
                      <Langtext text={'funnel.troubleDetail.dontKnow'} />
                    ) : (
                      trouble.internalCreator
                    )}
                  </span>
                </O2FieldContent>
              </O2Field>
            </O2Pane>
          </O2Indent>
          {(trouble.continueButton || trouble.cancelButton) && (
            <O2Indent>
              <O2ButtonGroup>
                {trouble.continueButton && (
                  <O2Button
                    onClick={() =>
                      startTtsProcess({
                        flowType: 'TT_TROUBLE',
                        ttId: trouble.id,
                        serviceId: trouble.serviceId,
                      })
                    }
                    color='primary'
                    data-test-id='continueButton'
                    indentRight
                  >
                    <Langtext text='funnel.troubleDetail.continueResolving' />
                  </O2Button>
                )}
                {trouble.cancelButton && !isOpen && (
                  <O2Button
                    onClick={() => setOpen(true)}
                    data-test-id='cancelButton'
                  >
                    <Langtext text='funnel.troubleDetail.cancel' />
                  </O2Button>
                )}
              </O2ButtonGroup>
            </O2Indent>
          )}
          {trouble.agreementButton && (
            <>
              <O2Indent>
                <h3 className='o2-typo__heading'>
                  <Langtext text='funnel.troubleDetail.isSolvedQuestion' />
                </h3>
              </O2Indent>

              <O2Indent>
                <O2ButtonGroup>
                  <O2Button
                    onClick={() => {
                      journalApi.pingJournal({
                        event: 'AGREE_WITH_TICKET_RESOLUTION',
                        username: user?.userInfo?.username,
                        params: {
                          serviceId: trouble.serviceId,
                          ttId: trouble.id.toString(),
                        },
                      });
                      startTtsProcess({
                        ttId: trouble.id,
                        flowType: 'TT_VALIDATION',
                        serviceId: trouble.serviceId,
                      });
                    }}
                    color='positive'
                    indentRight
                  >
                    <O2Icon name='checkmark' size='sm' indentRight />
                    <Langtext text='funnel.troubleDetail.worksBtn' />
                  </O2Button>
                  <O2Button
                    onClick={() => notWorkingRedirect(trouble)}
                    color='important'
                    indentRight
                  >
                    <O2Icon name='cross' size='sm' indentRight />
                    <Langtext text='funnel.troubleDetail.notWorksBtn' />
                  </O2Button>
                </O2ButtonGroup>
              </O2Indent>
            </>
          )}
        </O2Section>
      )}
      {isOpen && (
        <O2Indent standalone>
          <O2Pane color='warning'>
            <h3 className='o2web-typo__heading'>
              <Langtext text='funnel.troubleDetail.info.title' />
            </h3>
            <O2Indent standalone>
              <Langtext text='funnel.troubleDetail.info.text' />
            </O2Indent>
            <O2Indent>
              <O2Button
                color='primary'
                indentRight
                data-test-id='cancelSubmitButton'
                onClick={() =>
                  startTtsProcess({
                    ttId: trouble.id,
                    flowType: 'TT_CANCEL',
                    serviceId: trouble.serviceId,
                  })
                }
              >
                <Langtext text='funnel.troubleDetail.info.yesBtn' />
              </O2Button>
              <O2Button onClick={() => setOpen(false)}>
                <Langtext text='funnel.troubleDetail.info.noBtn' />
              </O2Button>
            </O2Indent>
          </O2Pane>
        </O2Indent>
      )}
      {loading && (
        <O2Indent standalone>
          <O2Wrap center>
            <O2Loader />
          </O2Wrap>
        </O2Indent>
      )}
    </>
  );
};

export default TroubleListDetails;
