// imports
import { useState, useEffect } from 'react';
import { getLocale } from '../../src/resourceUtils';
import packageJSON from '../../package.json';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { I18n } from 'react-i18nify-lite';

// types
type EventNames =
  | 'funnel_serviceSelected'
  | 'funnel_problemSelected'
  | 'funnel_addressFirstInteraction'
  | 'funnel_addressSelected'
  | 'funnel_addressSkipped'
  | 'funnel_outageCancel'
  | 'funnel_outageContinue'
  | 'funnel_diagnosticsStart'
  | 'funnel_diagnosticsResult';

const useAnalytics = (page?: string, isLog: boolean = true) => {
  // Configs
  const wireframes = useSelector<any, any>(state => state.appCache.wireframes);

  const analytics = useSelector<any, any>(state => state.analytics);
  const { ttId, serviceCpAssetRefNo } = analytics;

  const isAnalyticsEnabledState = useSelector<any, any>(
    state => state.config.o2Config
  );

  // Adobe Data Layer
  const LAYER = (window as any).adobeDataLayer;

  // Attributes: Set Up
  const [eventName, setEventName] = useState<EventNames | undefined>(undefined);
  // const [isActive, setIsActive] = useState<boolean>(true);
  const isActive =
    isAnalyticsEnabledState['funnelui.clientapi.analytics.enabled'];

  // Attributes: Default
  const selectedPage = _.find(wireframes, { page });
  const pageId = selectedPage?.wfId || undefined;
  // const pageTitle = page;
  const pageTitle = I18n.t(`funnel.${page}.title`);
  const [language, setLanguage] = useState<'cs' | 'en'>(getLocale());
  // TODO: only 404 case is valid here
  const [httpStatusCode, setHttpStatusCode] = useState<number | undefined>(
    undefined
  );
  const [apfAppName, setApfAppName] = useState<'Funnel'>('Funnel');
  const [apfAppVersion, setApfAppVersion] = useState<string>(
    packageJSON.version
  );
  // TODO: create global constant for handling versioned css and plug it here
  const [themeVersion, setThemeVersion] = useState<string | undefined>('2.4.0');

  // Attributes: Funnel (TODO: replace this into redux, so we can persist data per session)
  const [ticketId, setTicketId] = useState<string | undefined>(ttId);
  const [cpAssetRefNo, setCpAssetRefNo] = useState<string | undefined>(
    serviceCpAssetRefNo
  );
  const [serviceType, setServiceType] = useState<string | undefined>(undefined);
  const [troubleSymptom, setTroubleSymptom] = useState<string | undefined>(
    undefined
  );
  const formId = page;
  // const [formId, setFormId] = useState<string | undefined>(undefined);

  // Function: Get Funnel Attributes Object
  const funnelAttributes = () => {
    if (
      !ticketId &&
      !cpAssetRefNo &&
      !serviceType &&
      !troubleSymptom &&
      !formId
    )
      return undefined;

    const obj = {
      ticketId,
      cpAssetRefNo,
      serviceType,
      troubleSymptom,
      formId,
    };

    deleteEmptyFields(obj);

    return obj;
  };

  // Function: Removes undefined fields and mutates object
  // TODO: fix typescript here
  const deleteEmptyFields = (obj: any) => {
    Object.keys(obj).forEach(key =>
      obj[key] === undefined ? delete obj[key] : {}
    );
  };

  // Function: Clear State Variables
  const resetState = () => {
    setEventName(undefined);
    setTicketId(undefined);
    setCpAssetRefNo(undefined);
    setServiceType(undefined);
    setTroubleSymptom(undefined);
    // setFormId(undefined);
  };

  // Event: Event on Page Load
  const pageLoaded = () => {
    const obj = {
      event: 'pageLoaded',
      pageId,
      pageTitle,
      language,
      httpStatusCode,
      apfAppName,
      apfAppVersion,
      themeVersion,
      funnel: funnelAttributes(),
    };

    deleteEmptyFields(obj);

    if (isActive) {
      // hacky solution to fix unnecessary pageReload for hook on selecTrouble (it finds out no namePage)
      if (obj.funnel?.formId) {
        LAYER.push(obj);
      }

      // check if diagnostics has started, fireEvent and reset localstorage
      const isDiagnosticsStart = localStorage.getItem(
        'analytics_diagnostics_start'
      );
      if (isDiagnosticsStart) {
        fireEvent('funnel_diagnosticsResult');
        localStorage.removeItem('analytics_diagnostics_start');
      }

      /*
      if (isLog)
        console.log(`Adobe Analytics:: Page - ${page} has been loaded`);
      */
    }
  };

  // Event: General Fire Event On Callback
  const fireEvent = (event: EventNames) => {
    const obj = {
      event,
      funnel: funnelAttributes(),
    };

    deleteEmptyFields(obj);

    if (isActive) {
      LAYER.push(obj);

      if (event === 'funnel_diagnosticsStart') {
        localStorage.setItem('analytics_diagnostics_start', 'true');
      }

      if (isLog) {
        console.log(
          `Adobe Analytics:: LAYER data changed :: Data :: ${JSON.stringify(
            LAYER
          )}`
        );
        // console.log(LAYER);
      }
    }
  };

  // Event: Event for form cases
  const formSubmit = () => {
    const obj = {
      event: 'formSubmit',
      formId,
    };

    if (isActive) {
      LAYER.push(obj);

      if (isLog) {
        console.log(
          `Adobe Analytics:: LAYER data changed :: Data :: ${JSON.stringify(
            LAYER
          )}`
        );
        // console.log(LAYER);
      }
    }
  };

  // Effect: useEffect on Firing pageLoaded Event
  useEffect(() => {
    pageLoaded();
    // eslint-disable-next-line
  }, []);

  // Effect: useEffect on Firing Funnel Event
  useEffect(() => {
    if (eventName) {
      fireEvent(eventName as EventNames);
      resetState();
    }
    // eslint-disable-next-line
  }, [eventName]);

  // Effect: useEffect for Logging LAYER Output
  useEffect(() => {
    if (isLog && isActive) {
      console.log(
        `Adobe Analytics:: LAYER data changed :: Data :: ${JSON.stringify(
          LAYER
        )}`
      );
      // console.log(LAYER);
    }
    // eslint-disable-next-line
  }, [LAYER]);

  // Effects: useEffects listens for tts params, and assigns them localy
  useEffect(() => {
    if (ttId) setTicketId(ttId);
    // eslint-disable-next-line
  }, [ttId]);

  useEffect(() => {
    if (serviceCpAssetRefNo) setCpAssetRefNo(serviceCpAssetRefNo);
    // eslint-disable-next-line
  }, [serviceCpAssetRefNo]);

  return {
    LAYER,
    eventName,
    setEventName,
    isActive,
    // setIsActive,
    pageId,
    pageTitle,
    language,
    setLanguage,
    httpStatusCode,
    setHttpStatusCode,
    apfAppName,
    setApfAppName,
    apfAppVersion,
    setApfAppVersion,
    themeVersion,
    setThemeVersion,
    ticketId,
    setTicketId,
    cpAssetRefNo,
    setCpAssetRefNo,
    serviceType,
    setServiceType,
    troubleSymptom,
    setTroubleSymptom,
    formId,
    // setFormId,
    pageLoaded,
    fireEvent,
    formSubmit,
  };
};

export default useAnalytics;
