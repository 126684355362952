import React from 'react';
import { O2Indent, O2Button } from 'o2-theme-react';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import { useSelector } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';

const NotSupportedCustomer: React.FC = () => {
  const namePage = 'notSupportedCustomer';
  useAnalytics(namePage);
  const link = useSelector<any, any>(
    state => state.config.o2Config['link.dashboard']
  );

  const redirect = () => {
    window.location.href = link;
  };

  return (
    <SimpleTextPage
      hasBacklink={false}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <Langtext text='funnel.notSupportedCustomer.text' />
      </O2Indent>
      <O2Indent>
        <O2Button onClick={() => redirect()}>
          <Langtext text='funnel.notSupportedCustomer.button' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default NotSupportedCustomer;
