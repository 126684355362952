import React from 'react';
import { O2Section } from 'o2-theme-react';
import PageTitle from './PageTitle';

const SimpleTextPage = (props: any) => {
  const hasBacklink = props.hasBacklink ? props.hasBacklink : true;
  const backlinkTo = hasBacklink ? props.backlinkTo : '/';

  return (
    <>
      <PageTitle to={backlinkTo} backlink={props.backlink}>
        {props.title}
      </PageTitle>

      <O2Section>{props.children}</O2Section>
    </>
  );
};

export default SimpleTextPage;
