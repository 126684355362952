import { useEffect } from 'react';

const usePersistQueryParams = (isDeeplink?: boolean) => {
  const urlParams = new URLSearchParams(window.location.search);

  const getParams = () => {
    const cid = urlParams.get('cid');
    const _ic = urlParams.get('_ic');
    const _il = urlParams.get('_il');
    const _ir = urlParams.get('_ir');

    return {
      cid,
      _ic,
      _il,
      _ir,
    };
  };

  const getParamsString = () => {
    const jwtChecked = urlParams.get('jwtChecked');
    let url;
    if (jwtChecked) url = '&';

    const { cid, _ic, _il, _ir } = getParams();

    if (cid) url = `${url}cid=${cid}&`;
    if (_ic) url = `${url}_ic=${_ic}&`;
    if (_il) url = `${url}_il=${_il}&`;
    if (_ir) url = `${url}_ir=${_ir}&`;

    return url;
  };

  const setParams = () => {
    const { cid, _ic, _il, _ir } = getParams();

    if (cid) localStorage.setItem('cid', cid);
    if (_ic) localStorage.setItem('_ic', _ic);
    if (_il) localStorage.setItem('_il', _il);
    if (_ir) localStorage.setItem('_ir', _ir);
  };

  useEffect(() => {
    if (isDeeplink) {
      setParams();
    }
    // eslint-disable-next-line
  }, []);

  return {
    queryParams: getParams(),
    setParams,
    url: getParamsString(),
  };
};

export default usePersistQueryParams;
