import React from 'react';
import { O2Indent, O2Button } from 'o2-theme-react';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import useAnalytics from '../../../src/hooks/useAnalytics';

const OutagesNotFound: React.FC = () => {
  const namePage = 'outagesNotFound';
  useAnalytics(namePage);
  return (
    <SimpleTextPage
      title={<Langtext text='funnel.outagesNotFound.title' />}
      hasBacklink={false}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <p>
          <Langtext text='funnel.outagesNotFound.text' />
        </p>
      </O2Indent>
      <O2Indent>
        <O2Button
          href='/funnel/outages-select-number'
          color='transparent'
          firstLink
        >
          <Langtext text='funnel.outagesNotFound.button' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default OutagesNotFound;
