import React from 'react';
import { useSelector } from 'react-redux';
import { O2Loader, O2Indent, O2Wrap } from 'o2-theme-react';
import Langtext from '../../src/components/common/Langtext';

const useWaitingPageModificator = () => {
  const ttsInProgress = useSelector<any, any>(
    state => state.pages.ttsInProgress
  );

  if (ttsInProgress) {
    return (
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          background: 'white',
          opacity: '0.88',
        }}
      >
        <div style={{ margin: '30vh auto' }}>
          <O2Indent standalone>
            <O2Wrap center>
              <h1
                className='o2web-typo__heading'
                style={{ textAlign: 'center' }}
              >
                <Langtext text='funnel.transparentWaiting.title' />
              </h1>
            </O2Wrap>
          </O2Indent>
          <O2Indent standalone>
            <O2Wrap center>
              <h3
                className='o2web-typo__heading'
                style={{ textAlign: 'center' }}
              >
                <Langtext text='funnel.transparentWaiting.hint' />
              </h3>
            </O2Wrap>
          </O2Indent>
          <O2Indent standalone>
            <O2Wrap center>
              <O2Loader size='big' />
            </O2Wrap>
          </O2Indent>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default useWaitingPageModificator;
