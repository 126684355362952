import axios from 'axios';
import { format } from 'date-fns';
import { O2PopupMessage, O2PopupMessageItem } from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { connect, useSelector } from 'react-redux';


import StoreState from '../../../redux/store/storeState';
import { RESOURCE_APPLICATION } from '../../../resourceUtils';
import { newId } from './utils/idGenerator';
import { KahlInfo } from '../../../redux/reducers/types';

interface IKahlMessage {
  id: string;
  text: string;
  color: string | '"internal" | "success" | "warning" | "danger" | undefined';
  autoClose: number;
  closeBtn: boolean;
}

interface StateProps {
  kahlInfo: KahlInfo;
}

interface Props extends StateProps {}

const KahlMessages: React.FC<Props> = ({ kahlInfo }) => {
  const [messages, setMessages] = useState([]);
  const locale = useSelector<any, any>(state => state.config.lang);

  useEffect(() => {
    if (kahlInfo.kahlMessageId !== null) {
      handleKahlMessage(kahlInfo);
    }
    // eslint-disable-next-line
  }, [kahlInfo]);

  const handleKahlMessage = (kahlInfo: KahlInfo) => {
    const url = (window as any).g_apfApiLocation + '/api/kahl/public/v1/kahl';
    const timestamp = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
    const sourceApp = RESOURCE_APPLICATION;

    const kahlUrl = `${url}?infoMessageIds=${kahlInfo.kahlMessageId}&timestamp=${timestamp}&sourceApplication=${sourceApp}&locale=${locale}`;

    axios
      .get(kahlUrl, { withCredentials: true })
      .then(res => {
        for (let i = 0; i < res.data.length; i++) {
          let kahlMessageData = res.data[i];

          let kahlMessage = {
            id: newId(),
            text: kahlMessageData.text,
            color: getKahlColor(
              kahlMessageData.errorType,
              kahlMessageData.isInternal
            ),
            autoClose: kahlMessageData.errorType === 1 ? 10 : 5,
            closeBtn: kahlMessageData.errorType <= 3,
          };

          addKahlMessage(kahlMessage);
        }
      })
      .catch(err => {
        let kahlMessage;
        if (kahlInfo.kahlMessageDefaultType === 'success') {
          kahlMessage = {
            id: newId(),
            text: I18n.t('funnel.kahl.default.success'),
            color: 'success',
            autoClose: 10,
            closeBtn: true,
          };
        } else {
          kahlMessage = {
            id: newId(),
            text: I18n.t('funnel.kahl.default.danger'),
            color: 'danger',
            autoClose: 10,
            closeBtn: true,
          };
        }

        addKahlMessage(kahlMessage);
      });
  };

  const addKahlMessage = (kahlMessage: IKahlMessage) => {
    if (kahlMessage.autoClose !== 0) {
      setTimeout(() => {
        handleClose(kahlMessage.id);
      }, kahlMessage.autoClose * 1000);
    }

    setMessages(messages => {
      let kahlMessages: any = [...messages];
      kahlMessages.push(kahlMessage);
      return kahlMessages;
    });
  };

  const getKahlColor = (severity: string, isInternal: boolean) => {
    if (isInternal) {
      return 'internal';
    } else {
      if (severity === '2' || severity === '3') {
        return 'success';
      } else if (severity === '3') {
        return 'warning';
      } else {
        return 'danger';
      }
    }
  };

  const handleClose = (id: string) => {
    setMessages(messages => [
      ...messages.filter((message: IKahlMessage) => message.id !== id),
    ]);
  };
  return (
    <O2PopupMessage>
      {messages &&
        messages.map((message: any) => (
          <O2PopupMessageItem
            key={message.id}
            color={message.color}
            onClose={() => handleClose(message.id)}
            closeBtn={message.closeBtn}
          >
            {message.text}
          </O2PopupMessageItem>
        ))}
    </O2PopupMessage>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  kahlInfo: state.kahlInfo,
});

export default connect(mapStateToProps, null)(KahlMessages);
