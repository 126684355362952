import React from 'react';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import { O2Section, O2Indent, O2InfoMessage, O2Icon } from 'o2-theme-react';
import useAnalytics from '../../../src/hooks/useAnalytics';

const NoOutage: React.FC = (props: any) => {
  const namePage = 'noOutage';
  useAnalytics(namePage);

  return (
    <SimpleTextPage title={<Langtext text='funnel.noOutage.title' />}>
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Section>
        <O2Indent>
          <O2InfoMessage color='good'>
            <Langtext text='funnel.noOutage.text' />
          </O2InfoMessage>
        </O2Indent>
        <O2Indent>
          <a
            href='/'
            onClick={e => {
              e.preventDefault();
              props.history.push('/funnel');
            }}
            className='o2-typo__nowrap'
          >
            <O2Icon name='chevron-left' size='sm' indentRight />
            <Langtext text='funnel.noOutage.buttonText' />
          </a>
        </O2Indent>
      </O2Section>
    </SimpleTextPage>
  );
};

export default NoOutage;
