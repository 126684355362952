import { O2Indent, O2Section, O2Button, O2Radio } from 'o2-theme-react';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Langtext from '../../../src/components/common/Langtext';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import { I18n } from 'react-i18nify-lite';
import shortid from 'shortid';

import PageTitle from '../../../src/components/common/PageTitle';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const SetupLan: React.FC = (props: any) => {
  const namePage = 'lanPorts';
  const { formSubmit } = useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const serviceId = useSelector<any, any>(state => state.pages.selectedValueId);
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);
  const [tripplePlay, setTripplePlay] = useState(page.params.tripplePlay);

  const hdmTripplePlayToImage = useSelector<any, any>(
    state => state.appCache.codeLists.hdmTripplePlayToImage
  );

  const handleSaveChanges = () => {
    formSubmit();
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            tripplePlay: tripplePlay,
            serviceId: serviceId,
          },
          ttsParams: {
            ...ttsParams,
            actionId: 'OK',
            flowType: 'TT_DEVICE_CONF',
          },
        },
        props
      )
    );
  };

  return (
    <>
      <PageTitle
        to='/funnel/select-hdm-function'
        backlink={<Langtext text='funnel.lanPorts.topLink' />}
      >
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.lanPorts.title' />
      </PageTitle>

      <O2Section>
        <O2Indent>
          {serviceId} - {page.params.modemName}
        </O2Indent>
        <O2Indent>
          {page.cards &&
            page.cards.map((card: { keyName: string; text: string }) => (
              <O2Indent standalone={true} key={shortid.generate()}>
                <O2Indent>
                  <div className='o2-typo--weight-important'>
                    <O2Radio
                      data-test-id='tripplePlayRadio'
                      checked={tripplePlay === card.keyName ? true : false}
                      name='o2-99-11-2-radio'
                      label={
                        <Langtext
                          text={`funnel.codelist.hdmTripplePlayToText.${card.keyName}`}
                        />
                      }
                      onChange={() => setTripplePlay(card.keyName)}
                    />
                  </div>
                </O2Indent>
                <O2Indent top='close' className='o2-typo--indent-left'>
                  <O2Indent>
                    <Langtext
                      text={`funnel.codelist.hdmTripplePlayToDescription.${card.keyName}`}
                    />
                  </O2Indent>
                  <O2Indent top='tight'>
                    <img
                      src={hdmTripplePlayToImage[card.keyName]}
                      alt={I18n.t(
                        `funnel.codelist.hdmTripplePlayToText.${card.keyName}`
                      )}
                    />
                  </O2Indent>
                </O2Indent>
              </O2Indent>
            ))}
        </O2Indent>
      </O2Section>
      <O2Section>
        <O2Button
          color='primary'
          onClick={() => handleSaveChanges()}
          data-test-id='submitButton'
        >
          <Langtext text='funnel.lanPorts.saveChanges' />
        </O2Button>
      </O2Section>
    </>
  );
};

export default SetupLan;
