import ImageCard from '../../../src/components/common/ImageCard';
import Langtext from '../../../src/components/common/Langtext';
import PageTitle from '../../../src/components/common/PageTitle';
import { ttsProcess, ttsProcessFiltering } from '../../../src/redux/actions/ttsActions';
import get from 'lodash/get';
import {
  O2Button,
  O2Indent,
  O2Linkbox,
  O2Loader,
  O2Section,
  O2TextField,
  O2Wrap,
  O2WrapItem,
} from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';
// import { logoutAndRedirect } from '../../../src/redux/actions/userActions';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

// import LazyLoadHook from '../../../src/components/common/LazyLoadHook';
const SelectNumberTwo: any = (props: any) => {
  const namePage = 'selectService';
  const { setServiceType, setEventName } = useAnalytics(namePage);
  const user = useSelector<any, any>(state => state.user);
  const dispatch = useDispatch();
  const lazyload = useSelector<any, any>(
    state => state.pages.pages[namePage].lazyLoad
  );
  const stateServices = useSelector<any, any>(state =>
    get(state, `pages.pages[${namePage}].services`, null)
  );
  const lazyLoadBatch = useSelector<any, any>(
    state => state.pages.lazyLoadBatch
  );
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  useHandleInappropriateRefresh();
  const [services, setServices] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  // TODO: FIX UNUSED loadMore with turn on lazyload
  // eslint-disable-next-line
  const [loadMore, setLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchMyOtherServices, setSearchMyOtherServices] = useState('');
  // const [isFetching, setIsFetching] = LazyLoadHook(fetchMoreItems, loadMore);
  const linkWDEInternal = useSelector<any, any>(
    state => state.config.o2Config['link.setCustomerInternal']
  );
  const linkWDEExternal = useSelector<any, any>(
    state => state.config.o2Config['link.setCustomerExternal']
  );
  // replacement for wdeLink (using only this link and no suffix)
  // const customerContextPageLink = useSelector<any, any>(
  //   state => state.config.o2Config['link.customerContextPage']
  // );

  const linkCreateTrouble = useSelector<any, any>(
    state => state.config.o2Config['link.createTrouble']
  );

  useEffect(() => {
    setServices(stateServices);
  }, [stateServices]);

  useEffect(() => {
    if (user.userInfo.internal) {
      setSearchMyOtherServices(linkWDEInternal + encodeURIComponent(window.location.origin + linkCreateTrouble));
    } else {
      setSearchMyOtherServices(linkWDEExternal + encodeURIComponent(window.location.origin + linkCreateTrouble));
    }
  }, [linkWDEInternal, linkWDEExternal, user, linkCreateTrouble]);

  const onSearch = (): void => {
    if (stateServices !== null && stateServices.length > 0) {
      dispatch(
        ttsProcessFiltering({
          page: namePage,
          params: {
            filter: searchValue,
          },
        })
      );
    }
  };

  useEffect(() => {
    setLoadMore(true);
    if (!lazyload) {
      setLoadMore(false);
    }
    // eslint-disable-next-line
  }, [page.lazyLoad, lazyLoadBatch]);

  function fetchMoreItems() {
    setLoading(true);
    setTimeout(() => {
      dispatch(
        ttsProcess({
          page: namePage,
          params: {
            lazyloadBatch: lazyLoadBatch + 1,
          },
        })
      );
      setLoadMore(false);
      setLoading(false);
      // setIsFetching(false);
    }, 3000);
  }

  /*
  const reportBugOtherService = () => {
    logoutAndRedirect(linkCreateTrouble);
  };*/

  const selectService = (data: any, serviceGroup: any) => {
    if (data.status === 'ACTIVE') {
      setServiceType(serviceGroup);
      setEventName('funnel_serviceSelected');
      dispatch(
        ttsProcess(
          {
            page: namePage,
            params: {
              serviceId: data.id,
            },
          },
          props
        )
      );
    }
  };

  return (
    <>
      <PageTitle>
        <Langtext text='funnel.selectService.title' />
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
      </PageTitle>
      <O2Section>
        <O2Indent>
          <O2TextField
            controlSize='medium'
            label={<Langtext text='funnel.selectService.descriptionTitle' />}
            labelNote={I18n.t('funnel.selectService.description')}
            onChange={(e: any) => setSearchValue(e.target.value)}
          >
            <input />
            <O2Button
              icon='search-fill'
              form='addon'
              color='primary'
              onClick={() => onSearch()}
            />
          </O2TextField>
        </O2Indent>
        <>
          <O2Indent>
            <O2Wrap columns={3}>
              {services &&
                services.map(
                  (service: any) =>
                    service.status !== 'DEACTIVATED' && (
                      <O2WrapItem key={shortid.generate()}>
                        <ImageCard
                          isService={true}
                          testId='serviceButtons'
                          title={service.tariffName}
                          subTitle={service.cpName}
                          id={service.id}
                          status={service.state}
                          text={service.deviceId}
                          description={service.address}
                          image={service.imageLink}
                          link={
                            service.state === 'SUSPENDED'
                              ? service.trfLink
                              : null
                          }
                          disabled={service.state !== 'ACTIVE' ? true : false}
                          nextPageParams={
                            service.nextProceedCall
                              ? service.nextProceedCall
                              : null
                          }
                          onClick={(data: any) =>
                            selectService(data, service?.serviceGroup)
                          }
                        />
                      </O2WrapItem>
                    )
                )}
            </O2Wrap>
          </O2Indent>
          {lazyload && !loading && (
            <O2Indent>
              <O2Button
                data-test-id='lazyloadButton'
                onClick={() => fetchMoreItems()}
              >
                <Langtext text='funnel.selectService.buttons.loadMore' />
              </O2Button>
            </O2Indent>
          )}
        </>
        {loading && (
          <O2Indent standalone>
            <O2Wrap center>
              <O2Loader />
            </O2Wrap>
          </O2Indent>
        )}
      </O2Section>
      <O2Section separate>
        <O2Indent>
          <O2Wrap columns={2} mobileCollapsed>
            {!user.userInfo.internal && (
              <O2WrapItem>
                <O2Linkbox href={searchMyOtherServices}>
                  <Langtext text='funnel.selectService.buttons.buttonOtherUser' />
                </O2Linkbox>
              </O2WrapItem>
            )}
            {user.userService !== null && (
              <O2WrapItem>
                <O2Linkbox href={searchMyOtherServices}>
                  <Langtext text='funnel.selectService.buttons.buttonShowOtherServices' />
                </O2Linkbox>
              </O2WrapItem>
            )}
            {/*
            <O2WrapItem>
              <O2Linkbox onClick={() => reportBugOtherService()}>
                <Langtext text='funnel.selectService.buttons.buttonOtherService' />
              </O2Linkbox>
            </O2WrapItem>
            */}
          </O2Wrap>
        </O2Indent>
      </O2Section>
    </>
  );
};

export default SelectNumberTwo;
