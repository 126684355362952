import 'core-js/es/string';
import 'core-js/es/object';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import { initPolyfill } from './initPolyfill';
import { initializeSessionCookie } from './utils';
import { initializeLangTexts } from './resourceUtils';
import 'o2-theme-react/lib/o2-date-picker.css';
import 'o2-theme-react/lib/o2-select-ex.css';
import { initializeStore } from './redux/store/store';


initPolyfill();

let store = initializeStore();

initializeSessionCookie();

const renderInitializationError = (err: object | string) => {
  render(
    <p>
      Při inicializaci došlo k vnitřní chybě aplikace:
      <br />
      {err.toString()}
    </p>,
    document.getElementById('page-root')
  );
};

const renderApplication = () => {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('page-root')
  );
};

initializeLangTexts()
  .then(() => {
    renderApplication();
  })
  .catch(err => {
    console.error('Application failed to initialize: ', err);
    renderInitializationError(err);
  });
