/// <reference path="./react-i18nify-lite.d.ts" />
/// <reference path="./react-deep-force-update.d.ts" />

import { I18n } from 'react-i18nify-lite';
import deepForceUpdate from 'react-deep-force-update';
import * as React from 'react';
import ResourceApi from './api/cmsResourceApi';
import messagesCS from './resources/messagesCs';
import { getCookie } from './utils';

export const RESOURCE_APPLICATION = 'funnel-app';
export const DEFAULT_LOCALE = 'cs';
export const SECOND_LOCALE = 'en';

let _rootComponent: React.FC;
let _availableTranslations: { [name: string]: boolean } = {};

export const initializeLangTexts = (): Promise<void> => {
  return refreshLangTexts(true).then(() => {
    I18n.setLocale(getLocale());
  });
};

export const getLocale = (): any => {
  return getCookie('locale') !== '' && getCookie('locale') !== undefined
    ? getCookie('locale')
    : _availableTranslations[navigator.language]
    ? navigator.language
    : DEFAULT_LOCALE;
};

export const refreshLangTexts = (updateComponents: boolean = false) => {
  let bundledTexts = { cs: messagesCS };
  return ResourceApi.getLangTexts()
    .then(res => {
      const translations = mergeTextTranslations(res.data, bundledTexts);
      _availableTranslations = {};
      Object.getOwnPropertyNames(translations).forEach(
        lang => (_availableTranslations[lang] = true)
      );
      setTranslations(translations, updateComponents);
    })
    .catch(err => {
      console.error('Failed to load lang texts: ', err);
      setTranslations(bundledTexts, updateComponents);
    });
};

const setTranslations = (translations: Object, updateComponents: boolean) => {
  I18n.setTranslations(translations);
  if (_rootComponent && updateComponents) {
    deepForceUpdate(_rootComponent);
  }
};

const mergeTextTranslations = (serverTexts: any, targetTexts: any) => {
  if (
    !serverTexts ||
    !serverTexts[RESOURCE_APPLICATION] ||
    !serverTexts[RESOURCE_APPLICATION]['<ANY>']
  ) {
    return targetTexts;
  }

  let appTexts = serverTexts[RESOURCE_APPLICATION]['<ANY>'];

  Object.getOwnPropertyNames(appTexts).forEach(lang => {
    let langTexts = appTexts[lang];

    let targetLangTexts = targetTexts[lang];
    if (!targetLangTexts) {
      targetLangTexts = targetTexts[lang] = {};
    }

    Object.getOwnPropertyNames(langTexts).forEach(key => {
      let target = targetLangTexts;
      let keyParts = key.split('.');
      keyParts.forEach((keyPart, index) => {
        if (index === keyParts.length - 1) {
          target[keyPart] = langTexts[key];
        } else {
          if (!target[keyPart]) {
            target[keyPart] = {};
          }
          target = target[keyPart];
        }
      });
    });
  });

  return targetTexts;
};

export const includesDangerousHTML = (html: string | null): boolean => {
  if (html && (html.includes('iframe') || html.includes('script'))) {
    return true;
  } else {
    return false;
  }
};
