import { O2Wrap, O2WrapItem } from 'o2-theme-react';
import React from 'react';
import { I18n } from 'react-i18nify-lite';
import { useSelector } from 'react-redux';
import shortid from 'shortid';
import JournalApi from '../../api/journalApi';

const LinkMenu = (props: any) => {
  const { withTranslations, onlyIds } = props;
  const user = useSelector<any, any>(state => state.user);
  const speedTestLink = useSelector<any, any>(
    state => state.config.o2Config['link.speedTest']
  );
  const journalApi = new JournalApi();
  const handleOnClick = (item: { title: string; link: string }) => {
    if (item.link === 'speedTest') {
      let login = user.isLoggedIn
        ? user.userInfo.username
          ? user.userInfo.username
          : user.userInfo.msisdn
        : null;
      journalApi.pingJournal({
        event: 'TRANSITION_TO_SPEED_TEST',
        username: login,
      });
      window.location.href = speedTestLink;
    }
  };

  return (
    <O2Wrap columns={props.columns}>
      {props.data.map((item: any) => (
        <O2WrapItem key={shortid.generate()} style={{ order: item.order }}>
          {!onlyIds && item.link && (
            <a
              data-test-id={item.testId ? item.testId : ''}
              data-test-value={item.troubleId ? item.troubleId : ''}
              onClick={() => handleOnClick(item)}
              href={item.link === 'speedTest' ? null : item.link}
            >
              {withTranslations ? I18n.t(item.title) : item.title}
            </a>
          )}
          {!onlyIds && !item.link && (
            <a
              href='/'
              data-test-id={item.testId ? item.testId : ''}
              data-test-value={item.title ? item.title : ''}
              onClick={e => {
                e.preventDefault();
                props.click(withTranslations ? I18n.t(item.title) : item.title);
              }}
            >
              {withTranslations ? I18n.t(item.title) : item.title}
            </a>
          )}
          {onlyIds &&
            item.link !== null &&
            item.link !== '' &&
            item.link !== ' ' && (
              <a
                href={item.link}
                data-test-id={props.testId ? props.testId : ''}
                data-test-value={item.troubleId ? item.troubleId : ''}
              >
                {I18n.t(`funnel.codelist.troubleNames.${item.troubleId}`)}
              </a>
            )}
          {onlyIds &&
            (item.link === null || item.link === '' || item.link === ' ') && (
              <a
                href='/'
                data-test-id={props.testId ? props.testId : ''}
                data-test-value={item.troubleId ? item.troubleId : ''}
                onClick={e => {
                  e.preventDefault();
                  props.click(item);
                }}
              >
                {I18n.t(`funnel.codelist.troubleNames.${item.troubleId}`)}
              </a>
            )}
        </O2WrapItem>
      ))}
    </O2Wrap>
  );
};

export default LinkMenu;
