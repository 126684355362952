import { sendGet } from './requestUtils';

const WIREFRAMES_API_URL = '/api/funnel/public/v1/';

export default class MatrixAPI {
  async getServiceTypeMatrix() {
    return sendGet<any>(
      `${WIREFRAMES_API_URL}serviceTypeMatrix/list`,
      null,
      'json'
    );
  }

  async getTroubleMatrix() {
    return sendGet<any>(
      `${WIREFRAMES_API_URL}troubleMatrix/list`,
      null,
      'json'
    );
  }
}
