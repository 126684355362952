const lanPorts = (imagesPath: string) => {
  return {
    cards: [
      {
        keyName: 'Internet',
      },
      {
        keyName: 'Single',
      },
      {
        keyName: 'Multi',
      },
    ],
  };
};

export default lanPorts;
