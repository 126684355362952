import HTMLBody from '../../../src/components/common/HTMLBody';
import Langtext from '../../../src/components/common/Langtext';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import get from 'lodash/get';
import { O2Button, O2Indent } from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const InformationChatSelfcare: React.FC = props => {
  const namePage = 'informationChatSelfcare';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const o2Config = useSelector<any, any>(state => state.config.o2Config);
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const [destinationId] = useState(get(page, 'ttsParams.destinationId', null));

  const redirect = () => {
    const getRedirectLink = () => {
      if (localStorage.getItem('webview') === 'true') {
        return o2Config['link.finalPageAfterTTSWebview'];
      } else {
        return o2Config['link.finalPageAfterTTS'];
      }
    };

    window.location.href = getRedirectLink();
  };

  useEffect(() => {
    return () => {
      if (typeof (window as any).$owidget != 'undefined') {
        (window as any).$owidget.addCustomData('WDG_SHOW_ICON', 'false');
      }
    };
  }, []);

  const openChat = () => {
    (window as any).postMessage({
      content: {
        reason: 'ManuallyCalled',
      },
      operation: 'RequestWidgetOpenWindow',
    });
  };

  const backLinkTextResolver = () => {
    if (localStorage.getItem('webview') === 'true') {
      return <Langtext text='funnel.confirmation.topLinkWebView' />;
    } else {
      return <Langtext text='funnel.confirmation.topLinkDefault' />;
    }
  };

  return (
    <SimpleTextPage
      title={
        destinationId ? (
          <Langtext
            text={`funnel.codelist.destinationToTitle.${destinationId}`}
          />
        ) : (
          <Langtext text='funnel.informationChatSelfcare.title' />
        )
      }
      hasBacklink={false}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        {destinationId && <HTMLBody id={destinationId} />}
        {!destinationId && (
          <Langtext text='funnel.informationChatSelfcare.description' />
        )}
      </O2Indent>
      <O2Indent>
        <O2Button color='positive' indentRight onClick={() => openChat()}>
          <Langtext text='funnel.informationChatSelfcare.chatBtn' />
        </O2Button>
        <O2Button color='internal' onClick={() => redirect()}>
          {backLinkTextResolver()}
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default InformationChatSelfcare;
