import React from 'react';
import { O2Indent, O2Section, O2Wrap, O2WrapItem } from 'o2-theme-react';
import ImageCard from '../../../src/components/common/ImageCard';
import PageTitle from '../../../src/components/common/PageTitle';
import { useSelector } from 'react-redux';
import shortid from 'shortid';
import Langtext from '../../../src/components/common/Langtext';
import useAnalytics from '../../../src/hooks/useAnalytics';

const O2tvCannotLogin: React.FC = () => {
  const namePage = 'o2tvCannotLogin';
  useAnalytics(namePage);
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const internetLink = useSelector<any, any>(
    state => state.config.o2Config['link.o2tvCannotLogin.internet']
  );
  const applicationLink = useSelector<any, any>(
    state => state.config.o2Config['link.o2tvCannotLogin.application']
  );
  const setTopBoXLink = useSelector<any, any>(
    state => state.config.o2Config['link.o2tvCannotLogin.setTopBox']
  );

  const getLink = (id: string) => {
    if (id === 'application') return applicationLink;
    else if (id === 'internet') return internetLink;
    else if (id === 'setTopBox') return setTopBoXLink;
  };

  return (
    <>
      <PageTitle
        to='/funnel/deeplink/createticket'
        backlink={<Langtext text='funnel.o2tvCannotLogin.topLink' />}
      >
        <Langtext text='funnel.o2tvCannotLogin.title' />
      </PageTitle>
      <br />
      <br />
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Section>
        <O2Indent>
          <O2Wrap columns={3}>
            {page.cards.map((card: any) => (
              <O2WrapItem key={shortid.generate()}>
                <ImageCard
                  title={card.title}
                  description={card.description}
                  image={card.image}
                  link={getLink(card.id)}
                  withTranslations={true}
                />
              </O2WrapItem>
            ))}
          </O2Wrap>
        </O2Indent>
      </O2Section>
    </>
  );
};

export default O2tvCannotLogin;
