import { O2TableRow, O2TableCell } from 'o2-theme-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Langtext from '../../../src/components/common/Langtext';

const TableRow = (props: {
  type: any;
  troubleId: number;
  issue: string | any;
  service: string;
  serviceType: string;
  date: string | any;
  state: any;
  history: any;
  statusClass: string;
  testId: string;
}) => {
  const ttsServiceTypeHistoryName = useSelector<any, any>(
    state => state.appCache.codeLists['ttsServiceTypeHistoryName']
  );

  return (
    <O2TableRow
      link
      data-test-id={props.testId}
      data-test-value={props.troubleId}
      onClick={() =>
        props.history.push(`/funnel/trouble-list/${props.troubleId}`)
      }
    >
      <O2TableCell link dominant>
        <Link
          to={`/funnel/trouble-list/${props.troubleId}`}
          className='o2-typo--link'
        >
          {props.issue}
        </Link>
      </O2TableCell>
      <O2TableCell
        link
        data-title='Služba'
        data-test-id='serviceId'
        data-test-value={props.service}
      >
        {props.service}
        <br />
        {console.log(ttsServiceTypeHistoryName[props.serviceType])}
        {ttsServiceTypeHistoryName[props.serviceType]}
      </O2TableCell>
      <O2TableCell link data-title='Založeno'>
        <span className='o2-typo__nowrap'>{props.date}</span>
      </O2TableCell>
      <O2TableCell link>
        <span className={`o2-inline-state ${props.statusClass}`}>
          {props.type ? (
            <Langtext text={`funnel.codelist.historyTypeText.${props.type}`} />
          ) : (
            props.state
          )}
        </span>
      </O2TableCell>
      <O2TableCell link linkIcon />
    </O2TableRow>
  );
};

export default TableRow;
