import { O2Button, O2Indent } from 'o2-theme-react';
import React from 'react';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import { useSelector } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';

const Confirmation: React.FC = () => {
  const namePage = 'confirmation';
  useAnalytics(namePage);
  const o2Config = useSelector<any, any>(state => state.config.o2Config);


  const backLinkTextResolver = () => {
    if (localStorage.getItem('webview') === 'true') {
      return <Langtext text='funnel.confirmation.topLinkWebView' />
    } else {
      return <Langtext text='funnel.confirmation.topLinkDefault' />
    }
  }
  const backLinkResolver = () => {
    if (localStorage.getItem('webview') === 'true') {
      return o2Config['link.finalPageAfterTTSWebview'];
    } else {
      return o2Config['link.finalPageAfterTTS'];
    }
  }
  const descriptionTextResolver = () => {
    if (localStorage.getItem('webview') === 'true') {
      return <Langtext text='funnel.confirmation.descriptionWebView' />
    } else {
      return <Langtext text='funnel.confirmation.descriptionDefault' />
    }
  }

  return (
    <SimpleTextPage>
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        {descriptionTextResolver()}
      </O2Indent>
      <O2Indent>
          <O2Button
            color='primary'
            onClick={() => {
              window.location.href = backLinkResolver()
            }}
            data-test-id='submitButton'
          >
            {backLinkTextResolver()}
          </O2Button>
        </O2Indent>
    </SimpleTextPage>
  );
};

export default Confirmation;
