import ES6Promise from 'es6-promise';

ES6Promise.polyfill();

export const initPolyfill = () => {
  // Some polyfills for sh***y IE
  if (!String.prototype.startsWith) {
    // eslint-disable-next-line
    String.prototype.startsWith = function(search: string, pos?: number) {
      return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
    };
  }

  if (!String.prototype.endsWith) {
    // eslint-disable-next-line
    String.prototype.endsWith = function(search: string, thisLen?: number) {
      let len = thisLen;
      if (len === undefined || len > this.length) {
        len = this.length;
      }
      return this.substring(len - search.length, thisLen) === search;
    };
  }

  if (!Array.prototype.find) {
    // HELP: Refactor for new code standard
    // eslint-disable-next-line
    Array.prototype.find = function(callback: Function) {
      for (let e = 0; e < this.length; e++) {
        if (callback(this[e])) {
          return this[e];
        }
      }
    };
  }
};
