import { sendPost } from './requestUtils';
import { JournalParams } from './types';

const API_PATH = '/api/funnel/public/v1/';

export default class JournalApi {
  public pingJournal(params: JournalParams) {
    return sendPost<any>(`${API_PATH}journal`, null, params);
  }
}
