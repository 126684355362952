import React, { useEffect, useState } from 'react';
import { O2Indent, O2Wrap, O2Loader } from 'o2-theme-react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import queryString from 'query-string';
import { 
  getApiPath, 
  composeQueryParamUrl, 
  removeParamFromUrl, 
} from '../../../src/api/requestUtils';
import useWaitingPageModificator from '../../../src/hooks/useWaitingPageModificator';
import usePersistQueryParams from '../../../src/hooks/usePersistQueryParams';
import getURIParams from '../../utils/getUriParams';

const validateBtnType = (btn: string) => {
  switch(btn) {
    case 'TT_TROUBLE':
    case 'TT_CANCEL':
    case 'TT_VALIDATION':
    case 'TT_TERM_CHANGE':
      return true;
    default: return false;
  }
}

const OpenTicket = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const transparentWaiting = useWaitingPageModificator();
  const {
    ttId,
    buttonType,
    token,
  } = getURIParams('/funnel/deeplink/openticket/');
  const location = useLocation();
  const [hasRelevantData, setHasRelevantData] = useState<boolean>(false);
  const user = useSelector<any, any>(state => state.user);
  const o2Config = useSelector<any, any>(state => state.config.o2Config);
  const { jwtChecked } = queryString.parse(location.search);
  const { url } = usePersistQueryParams();

  // testing console logs
  console.log('ttId: ', ttId);
  console.log('buttonType: ', buttonType);
  console.log('token: ', token);

  useEffect(() => {
    if (!user.loading && o2Config !== null) {
      setHasRelevantData(true);
    }
  }, [user, o2Config]);

  useEffect(() => {
    // final page (new deeplinks always redirects to final page when !user & !token)
    if (!user.isLoggedIn && !token && jwtChecked === 'true') {
      history.push('/funnel');
    }
    if (jwtChecked === undefined || jwtChecked !== 'true') {
      const API_PATH = getApiPath('auth/casLogin');
      const QUERY_PARAMS = composeQueryParamUrl({
        redirectTo: encodeURIComponent(
          removeParamFromUrl(window.location.href, 'token') + 
          composeQueryParamUrl({ jwtChecked: true })
        ),
        autologinToken: token ?? undefined,
      });
      const REDIRECT_LINK = `${API_PATH}${QUERY_PARAMS}`;
      window.location.href = REDIRECT_LINK;
    }
    if (jwtChecked === 'true' && hasRelevantData) {
      if (ttId && buttonType && validateBtnType(buttonType)) {
        dispatch(
          ttsProcess(
            {
              page: 'main',
              params: {
                action: 'troubleList',
                ttId: ttId,
              },
              ttsParams: {
                flowType: buttonType,
                ttId: ttId,
              },
            },
            props,
            false,
            url ? url : false
          )
        );
      } else {
        history.push('/funnel');
      }
    }
    // eslint-disable-next-line
  }, [hasRelevantData]);

  return (
    <>
      <O2Indent standalone>
        <O2Wrap center>
          <O2Loader />
        </O2Wrap>
      </O2Indent>
      {transparentWaiting}
    </>
  );
}

export default OpenTicket;