import Langtext from '../../../src/components/common/Langtext';
import ModemLinkbox from '../../../src/components/common/ModemLinkbox';
import PageTitle from '../../../src/components/common/PageTitle';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import { O2Section } from 'o2-theme-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const SelectHdmFunction: React.FC = (props: any) => {
  const namePage = 'selectHdmFunction';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const serviceId = useSelector<any, any>(state => state.pages.selectedValueId);
  const redirect = (option: any) => {
    let actionId = option.keyName.replace('Enabled', '');

    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: serviceId,
          },
          ttsParams: {
            ...ttsParams,
            flowType: 'TT_DEVICE_CONF',
            actionId: actionId,
          },
        },
        props
      )
    );
  };

  return (
    <>
      <PageTitle
        to='/funnel/not-supported-modem'
        backlink={<Langtext text='funnel.selectHdmFunction.topLink' />}
        description={`${serviceId} - ${page.params.modemName}`}
      >
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.selectHdmFunction.title' />
      </PageTitle>
      <O2Section outdent={true}>
        {page.options &&
          page.options.map(
            (option: {
              keyName: string;
              link: string;
              title: string;
              desc: string;
              iconName: string;
              testId: string;
            }) => {
              if (page.params[option.keyName]) {
                return (
                  <ModemLinkbox
                    key={shortid.generate()}
                    href={option.link}
                    title={option.title}
                    description={option.desc}
                    iconName={option.iconName}
                    withTranslations={true}
                    testId={option.testId}
                    onClick={() => redirect(option)}
                  />
                );
              } else {
                return null;
              }
            }
          )}
      </O2Section>
    </>
  );
};

export default SelectHdmFunction;
