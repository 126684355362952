import React from 'react';

const O2Steps = (props: any) => {
  return (
    <div className='o2-steps'>
      <div
        className={`o2-steps__item ${
          props.isActive ? 'o2-steps__item--active' : ''
        }`}
      >
        <div className='o2-steps__step-inner'>
          <div className='o2-steps__symbol'>
            <div className='o2-steps__counter'>
              <div className='o2-steps__counter-inner'>{props.num}.</div>
            </div>
          </div>
          <div className='o2-steps__title'>{props.title}</div>
        </div>
      </div>
    </div>
  );
};

export default O2Steps;
