import {
  O2Indent,
  O2Section,
  O2Button,
  O2TextArea,
  O2Radio,
  O2ButtonGroup,
} from 'o2-theme-react';
import React, { useState } from 'react';
import Langtext from '../../../src/components/common/Langtext';
import { useSelector, useDispatch } from 'react-redux';
import shortid from 'shortid';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import { saveSelectedTerm } from '../../../src/redux/actions/pageActions';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const TechnicianVisit: React.FC = props => {
  const namePage = 'selectTerm';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const troubleId = useSelector<any, any>(
    state => state.pages.pages['setTerm'].troubleId
  );
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);

  const [selected, setSelected] = useState('');
  const [msg, setMsg] = useState('');

  const processSelect = () => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
            worklog: msg,
            termId: selected,
          },
          ttsParams: {
            ...ttsParams,
            actionId: 'TERM_SELECTED',
            flowType: 'TT_TERM_CHANGE',
            ttId: troubleId,
          },
        },
        props
      )
    );
  };

  const processChange = () => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
            worklog: msg,
            termId: selected,
          },
          ttsParams: {
            ...ttsParams,
            flowType: 'TT_TERM_CHANGE',
            ttId: troubleId,
          },
        },
        props
      )
    );
  };

  const saveTerm = (term: { id: string; text: string }) => {
    setSelected(term.id);
    dispatch(saveSelectedTerm(term));
  };

  return (
    <SimpleTextPage
      title={<Langtext text='funnel.selectTerm.title' />}
      hasBacklink={true}
      backlinkTo='/funnel/trouble-list'
      backlink={<Langtext text='funnel.selectTerm.topLink' />}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Section>
        <O2Indent bottom='close'>
          <span className='o2-control__label'>
            <Langtext text='funnel.selectTerm.headline' />
          </span>
        </O2Indent>
        <O2Indent>
          {page &&
            page.terms &&
            page.terms
              .sort((a: any, b: any) => {
                return a.id - b.id;
              })
              .map((term: { id: string; text: string }) => (
                <O2Indent key={shortid.generate()}>
                  <O2Radio
                    onChange={() => saveTerm(term)}
                    data-test-id='termRadios'
                    label={term.text}
                    value={term.id}
                    checked={selected === term.id}
                  />
                </O2Indent>
              ))}
        </O2Indent>
        <O2Indent>
          <O2TextArea
            label={<Langtext text='funnel.selectTerm.message' />}
            value={msg}
            onChange={e => setMsg(e.target.value)}
            rows={4}
            block={true}
          />
        </O2Indent>
      </O2Section>
      <O2Section>
        <O2ButtonGroup>
          <O2Button
            color='primary'
            onClick={() => processSelect()}
            data-test-id='submitButton'
          >
            <Langtext text='funnel.selectTerm.selectBtn' />
          </O2Button>
          <O2Button color='transparent' onClick={() => processChange()}>
            <Langtext text='funnel.selectTerm.changeDateBtn' />
          </O2Button>
        </O2ButtonGroup>
      </O2Section>
    </SimpleTextPage>
  );
};

export default TechnicianVisit;
