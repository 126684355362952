import React from 'react';
import { O2Indent, O2InfoMessage, O2Button, O2Icon } from 'o2-theme-react';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { serviceIdFormatter } from '../../utils';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const InvalidCombination: React.FC = (props: any) => {
  const namePage = 'invalidCombination';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const link = useSelector<any, any>(
    state => state.config.o2Config['link.createTrouble']
  );

  const serviceTypeMatrix = useSelector<any, any>(
    state => state.appCache.serviceTypeMatrix
  );

  const redirect = () => {
    props.history.push(link);
  };

  const getServiceName = () => {
    const matrix = serviceTypeMatrix.find(
      (item: any) => item?.serviceGroupId === page.params?.serviceGroup
    );
    return matrix?.name ? matrix?.name : page.params?.serviceGroup;
  };

  return (
    <SimpleTextPage
      title={<Langtext text='funnel.invalidCombination.title' />}
      hasBacklink={false}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <O2InfoMessage color='bad'>
          <Langtext text='funnel.invalidCombination.service' />{' '}
          <span className='o2-typo--weight-important'>{getServiceName()}</span>{' '}
          <Langtext text='funnel.invalidCombination.onNum' />{' '}
          <span className='o2-typo--weight-important'>
            {serviceIdFormatter(get(page, 'params.serviceId'))}
          </span>{' '}
          <Langtext text='funnel.invalidCombination.message' />
        </O2InfoMessage>
      </O2Indent>
      <O2Indent>
        <O2Button color='internal' onClick={() => redirect()}>
          <O2Icon name='chevron-left' size='sm' indentRight />
          <Langtext text='funnel.invalidCombination.backBtn' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default InvalidCombination;
