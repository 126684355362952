import get from 'lodash/get';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const MluviiAPIControl = () => {
  const user = useSelector<any, any>(state => state.user);
  const widgetConfig = (window as any).widgetConfig;

  useEffect(() => {
    const isUserInternal = get(user, 'userInfo.internal', false);
    const isWidgetInitialized = Boolean(widgetConfig.initialized);
    const isWidgetInitializing = Boolean(widgetConfig.initializing);

    if (!isUserInternal && !isWidgetInitialized && !isWidgetInitializing) {
      widgetConfig.init('residentCare');
    }
  }, [ user, widgetConfig ]);

  return <></>;
};

export default MluviiAPIControl;
