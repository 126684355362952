import HTMLBody from '../../../src/components/common/HTMLBody';
import Langtext from '../../../src/components/common/Langtext';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import get from 'lodash/get';
import { O2Button, O2Indent } from 'o2-theme-react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const TechnicianVisitDate: React.FC = (props: any) => {
  const namePage = 'technicianVisitRequired';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const [destinationId] = useState(get(page, 'ttsParams.destinationId', null));
  const ttsParams = useSelector<any, any>(
    state => state.pages.pages.technicianVisitRequired.ttsParams
  );
  const technicianAddress = useSelector<any, any>(
    state => state.analytics.address
  );

  const setTerm = () => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
          },
          ttsParams: {
            ...ttsParams,
            actionId: 'SUBMIT',
          },
        },
        props
      )
    );
  };

  return (
    <SimpleTextPage
      title={
        destinationId ? (
          <Langtext
            text={`funnel.codelist.destinationToTitle.${destinationId}`}
          />
        ) : (
          <Langtext text='funnel.technicianVisitRequired.title' />
        )
      }
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        {destinationId && (
          <>
            <HTMLBody id={destinationId} suffix={technicianAddress} />
          </>
        )}
        {!destinationId && (
          <>
            <Langtext text='funnel.technicianVisitRequired.text' />
          </>
        )}
      </O2Indent>
      <O2Indent>
        <O2Button color='primary' onClick={() => setTerm()}>
          <Langtext text='funnel.technicianVisitRequired.termBtn' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default TechnicianVisitDate;
