const mainDataMock = (imagesPath: string) => {
  return {
    cards: [
      {
        title: 'funnel.main.cards.issueCard.title',
        image: imagesPath + 'ico-hp-report.png',
        testId: 'troubleReportButton',
        checkDisable: false,
      },
      {
        title: 'funnel.main.cards.historyCard.title',
        image: imagesPath + 'ico-hp-status.png',
        testId: 'troubleHistoryButton',
        checkDisable: true,
      },
      {
        title: 'funnel.main.cards.modemCard.title',
        image: imagesPath + 'ico-hp-modem.png',
        testId: 'deviceConfigButton',
        checkDisable: true,
      },
    ],
    links: [
      {
        title: 'funnel.main.otherLinks.links.downtimeInfo',
        link: '/funnel/outages-select-service',
        testId: 'outagesButton',
      },
      {
        title: 'funnel.main.otherLinks.links.speedMeasurement',
        link: 'speedTest',
      },
      {
        title: 'funnel.main.otherLinks.links.forum',
        link: 'https://www.o2.cz/osobni/podpora',
      },
      {
        title: 'funnel.main.otherLinks.links.instructions',
        link: 'https://www.youtube.com/user/o2cz/playlists',
      },
      {
        title: 'funnel.main.otherLinks.links.coverageMap',
        link: 'https://www.o2.cz/osobni/199436-mapa_pokryti_a_prodejen/',
      },
      {
        title: 'funnel.main.otherLinks.links.modems',
        link: 'https://www.o2.cz/osobni/podpora/internet-wifi-modemy',
      },
      {
        title: 'funnel.main.otherLinks.links.mobileModems',
        link: 'https://www.o2.cz/osobni/techzona-mobilni-modemy/',
      },
      {
        title: 'funnel.main.otherLinks.links.mobiles',
        link: 'https://www.o2.cz/osobni/techzona-mobilni-telefony/',
      },
    ],
  };
};

export default mainDataMock;
