import { sendGet } from './requestUtils';
import get from 'lodash/get';
import { getState } from '../redux/store/store';

const API_PATH = '/api/funnel/public/v1/';

export default class FunnelServicesApi {
  
  static internalServices(koId: any) {
    let store = getState();
    return sendGet(
      `${API_PATH}services`,
      {
        serviceTypes: get(
          store,
          'config.o2Config["funnelui.cpservices.services.param.serviceTypes"]',
          null
        ),
        koId
      },
      'json'
    );
  }
}
