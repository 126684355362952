import { ServiceTypeMatrix, TroubleMatrix } from '../reducers/types';
import MatrixAPI from '../../api/matrixApi';

export type ServiceTypeMatrixAction = {
  type: 'GET_SERVICE_TYPE_MATRIX';
  payload: ServiceTypeMatrix | any;
};

export type TroubleMatrixAction = {
  type: 'GET_TROUBLE_MATRIX';
  payload: TroubleMatrix | any;
};

const initServiceTypeMatrix = (data: any): ServiceTypeMatrixAction => {
  return {
    type: 'GET_SERVICE_TYPE_MATRIX',
    payload: data,
  };
};

export const getServiceTypeMatrix = (): any => {
  const api = new MatrixAPI();
  return (dispatch: any) => {
    api
      .getServiceTypeMatrix()
      .then((res: any) => {
        dispatch(initServiceTypeMatrix(res.data));
      })
      .catch((err: any) => console.log(err));
  };
};

const initTroubleMatrix = (data: any): TroubleMatrixAction => {
  return {
    type: 'GET_TROUBLE_MATRIX',
    payload: data,
  };
};

export const getTroubleMatrix = (): any => {
  const api = new MatrixAPI();
  return (dispatch: any) => {
    api
      .getTroubleMatrix()
      .then((res: any) => {
        dispatch(initTroubleMatrix(res.data));
      })
      .catch((err: any) => console.log(err));
  };
};
