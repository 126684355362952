import {
  O2Indent,
  O2Section,
  O2Button,
  O2InfoMessage,
  O2ControlWrapper,
  O2TextField,
  O2Pane,
} from 'o2-theme-react';
import React, { useState, useEffect } from 'react';

import PageTitle from '../../../src/components/common/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import Langtext from '../../../src/components/common/Langtext';
import { I18n } from 'react-i18nify-lite';
import useAnalytics from '../../../src/hooks/useAnalytics';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { logoutAndRedirect } from '../../../src/redux/actions/userActions';
import { useHistory } from 'react-router-dom';

const SelectNumber: any = (props: any) => {
  const namePage = 'selectNumber';
  const { formSubmit } = useAnalytics(namePage);
  const o2Config = useSelector<any, any>(state => state.config.o2Config);
  const user = useSelector<any, any>(state => state.user);
  const dispatch = useDispatch();
  const [googleToken, setGoogleToken] = useState(null);
  const [phone, setPhone] = useState<string>('');
  const [validation, setValidation] = useState<any>({ valid: false });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const sharedObject = useSelector<any, any>(state => state.sharedObject);
  const history = useHistory();

  useEffect(() => {
    if (
      !user.loading &&
      !user.isLoggedIn
    ) {
      history.push('/funnel');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  useEffect(() => {
    if (
      user.isLoggedIn &&
      sharedObject !== null &&
      sharedObject?.sharedObjectSession?.customer === 'CORP'
    ) {
      logoutAndRedirect(o2Config['link.createTrouble']);
    }
  }, [user, o2Config, sharedObject]);

  useEffect(() => {
    if (executeRecaptcha) {
      executeRecaptcha('selectNumber').then((token: any) => {
        setGoogleToken(token);
      });
    }
  }, [executeRecaptcha]);

  const validate = (value: string) => {
    // eslint-disable-next-line
    const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]{8,}$/;
    const valid = regex.exec(value);
    if (!valid) {
      setValidation({
        valid: false,
        type: 'error',
        message: <Langtext text='funnel.selectNumber.phoneNumErr' />,
      });
      return false;
    } else {
      setValidation({
        valid: true,
      });
      return true;
    }
  };

  const handeKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      proceed();
    }
  };

  const handleChange = (value: string) => {
    setPhone(value.trim());
    if (!validation.valid && validation.type === 'error') {
      validate(value);
    }
  };
  // TODO: add correct redirect
  // TODO: unmock when backend will be ready
  const proceed = () => {
    const isValid = validate(phone);

    if (isValid) {
      dispatch(
        ttsProcess(
          {
            page: namePage,
            params: {
              serviceId: phone,
              googleToken,
            },
          },
          props
        )
      );
    }
  };
  return (
    <>
      <PageTitle>
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.selectNumber.title' />
      </PageTitle>

      <O2Section>
        <O2Indent>
          <Langtext text='funnel.selectNumber.description' />
        </O2Indent>
        <O2Indent>
          <O2InfoMessage>
            <Langtext text='funnel.selectNumber.warning' />
          </O2InfoMessage>
        </O2Indent>
        <O2Indent>
          <O2Pane>
            <O2Indent>
              <O2ControlWrapper>
                <O2TextField
                  data-test-id='serviceIdInput'
                  label={<Langtext text='funnel.selectNumber.phoneNum' />}
                  labelNote={I18n.t('funnel.selectNumber.phoneNumHint')}
                  value={phone}
                  onKeyDown={handeKeyDown}
                  onChange={e => handleChange(e.target.value)}
                  validationType={validation.type}
                  controlSize='medium'
                  validationText={validation.message}
                />
              </O2ControlWrapper>
            </O2Indent>
          </O2Pane>
        </O2Indent>
        <O2Indent>
          <O2Button
            color='primary'
            onClick={() => {
              proceed();
              formSubmit();
            }}
            data-test-id='submitButton'
          >
            <Langtext text='funnel.selectNumber.continueBtn' />
          </O2Button>
        </O2Indent>
      </O2Section>
    </>
  );
};

export default SelectNumber;
