import HTMLBody from '../../../src/components/common/HTMLBody';
import Langtext from '../../../src/components/common/Langtext';
import PageTitle from '../../../src/components/common/PageTitle';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import get from 'lodash/get';
import { O2Button, O2Indent, O2Section } from 'o2-theme-react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const ModemInvolved: React.FC = (props: any) => {
  const dispatch = useDispatch();
  useHandleInappropriateRefresh();
  const namePage = 'solution';
  useAnalytics(namePage);
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);
  const [destinationId] = useState(get(page, 'ttsParams.destinationId', null));
  const [solution, setSolution] = useState(false);
  const saveAllowed = page.params.saveAllowed;

  const proceed = (actionId: string) => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
            contactPhone: page.params.contactPhone,
          },
          ttsParams: {
            ...ttsParams,
            actionId,
          },
        },
        props
      )
    );
  };

  return (
    <>
      <PageTitle>
        {destinationId ? (
          <Langtext
            text={`funnel.codelist.destinationToTitle.${destinationId}`}
          />
        ) : (
          <Langtext text='funnel.solution.title' />
        )}
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
      </PageTitle>
      <O2Section>
        {!solution && (
          <O2Indent className='js-toggle-target'>
            <O2Indent>
              <Langtext text='funnel.solution.text' />
            </O2Indent>
            <O2Indent>
              <O2Button
                color='primary'
                indentRight
                data-test-id='goButton'
                onClick={() => setSolution(true)}
              >
                <Langtext text='funnel.solution.agreeBtn' />
              </O2Button>
              {saveAllowed && (
                <O2Button
                  color='internal'
                  // data-test-id='saveButton1'
                  onClick={() => proceed('SAVE')}
                >
                  <Langtext text='funnel.solution.laterBtn' />
                </O2Button>
              )}
            </O2Indent>
          </O2Indent>
        )}

        {solution && (
          <O2Indent className='js-toggle-target'>
            <O2Indent>
              {destinationId && <HTMLBody id={destinationId} />}
            </O2Indent>

            <O2Indent>
              <O2Indent>
                <h2 className='o2-typo--heading'>
                  <Langtext text='funnel.solution.isFixed' />
                </h2>
              </O2Indent>
              <O2Indent>
                <O2Button
                  color='primary'
                  indentRight
                  onClick={() => proceed('YES')}
                >
                  <Langtext text='funnel.solution.fixedBtn' />
                </O2Button>
                <O2Button
                  color='internal'
                  indentRight
                  onClick={() => proceed('NO')}
                >
                  <Langtext text='funnel.solution.notFixedBtn' />
                </O2Button>
                {saveAllowed && (
                  <O2Button
                    color='transparent'
                    data-test-id='saveButton'
                    onClick={() => proceed('SAVE')}
                  >
                    <Langtext text='funnel.solution.postponeBtn' />
                  </O2Button>
                )}
              </O2Indent>
            </O2Indent>
          </O2Indent>
        )}
      </O2Section>
    </>
  );
};

export default ModemInvolved;
