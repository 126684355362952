import React from 'react';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import useAnalytics from '../../../src/hooks/useAnalytics';

const FinalPage: React.FC = () => {
  const namePage = 'finalpage';
  useAnalytics(namePage);

  return (
    <SimpleTextPage
      title={<Langtext text='funnel.finalPage.title' />}
    >
    </SimpleTextPage>
  );
};

export default FinalPage;
