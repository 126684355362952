import { O2Indent, O2Button, O2InfoMessage } from 'o2-theme-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const Reset: React.FC = (props: any) => {
  const namePage = 'reset';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);

  const action = (type: string) => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
          },
          ttsParams: {
            ...ttsParams,
            flowType: 'TT_DEVICE_CONF',
            actionId: type === 'reset' ? 'OK' : 'scBackup',
          },
        },
        props
      )
    );
  };

  return (
    <SimpleTextPage
      hasBacklink={true}
      backlinkTo='/funnel/select-hdm-function'
      title={<Langtext text='funnel.reset.title' />}
      backlink={<Langtext text='funnel.reset.topLink' />}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <Langtext text='funnel.reset.text' />
      </O2Indent>
      <O2Indent>
        <O2InfoMessage>
          <Langtext text='funnel.reset.messageInfo' />{' '}
          <O2Button
            color='transparent'
            firstLink
            onClick={() => action('copy')}
          >
            <Langtext text='funnel.reset.messageLink' />
          </O2Button>
          .
        </O2InfoMessage>
      </O2Indent>
      <O2Indent>
        <O2Button color='important' onClick={() => action('reset')}>
          <Langtext text='funnel.reset.resetBtn' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default Reset;
