import { outagesServicesMock } from '../../resources/dataSets/pages/outagesServicesMock';
import { Pages } from './types';
import { Action } from '../../../src/redux/actions/ttsActions';
import { PageAction } from '../../../src/redux/actions/pageActions';
import mainDataMock from '../../resources/dataSets/pages/mainMock';
import O2TVCannotLoginDataMock from '../../resources/dataSets/pages/O2tvCannotLoginMock';
import securityParams from '../../resources/dataSets/pages/wifiMock';
import hdmFunctions from '../../resources/dataSets/pages/hdmFunctionsMock';
import lanPorts from '../../resources/dataSets/pages/lanPortsMock';

const imagesPath = process.env.PUBLIC_URL + '/images/';
const main: any = mainDataMock(imagesPath);
const O2TV: any = O2TVCannotLoginDataMock(imagesPath);
const lanPortsCards: any = lanPorts(imagesPath);
const outagesServices = outagesServicesMock(imagesPath);

const initialState: any = {
  ttsInProgress: false,
  ttsParams: {},
  isTtsProcess: false,
  lazyLoadBatch: 0,
  waitingSuccess: {
    isSuccess: false,
    nextPage: null,
  },
  selectedValueId: '',
  pages: {
    main: {
      cards: main.cards,
      linkBoxes: main.linkBoxes,
      links: main.links,
    },
    login: {},
    selectTrouble: {
      links: null,
    },
    selectService: {},
    o2tvCannotLogin: {
      cards: O2TV.cards,
    },
    outagesNotFound: {},
    selectNumber: {},
    troubleList: {},
    viewWifi: {
      safetyParams: securityParams.values,
    },
    configWifi: {
      safetyParams: securityParams.values,
    },
    selectDevice: {},
    selectHdmFunction: {
      options: hdmFunctions.options,
    },
    lanPorts: {
      cards: lanPortsCards.cards,
    },
    backup: {},
    restart: {},
    firmwareUpdate: {},
    recovery: {},
    plannedOutage: {},
    informationSelfcare: {},
    solution: {
      params: {
        saveAllowed: false,
      },
    },
    invalidCombination: {
      saveAllowed: null,
      serviceGroup: null,
      serviceId: null,
    },
    selectTerm: {},
    outagesSelectService: {
      cards: outagesServices.cards,
    },
    outage: {},
    outagesMap: {},
    setTerm: {
      troubleId: null,
    },
    puk: {
      params: {
        serviceId: null,
        tpRefNo: null,
        saveAllowed: null,
      },
    },
    block: {
      params: {
        serviceId: null,
        tpRefNo: null,
        saveAllowed: null,
      },
    },
    saved: {
      params: {
        saveAllowed: null,
      },
    },
    invalidNumber: {
      params: {
        saveAllowed: null,
        serviceId: null,
      },
    },
    technicianVisitRequired: {
      params: {
        saveAllowed: null,
        serviceId: null,
      },
    },
    technicianVisitOffer: {
      params: {
        saveAllowed: null,
        serviceId: null,
      },
    },
    selectSolution: {
      params: {
        saveAllowed: null,
        serviceId: null,
        term: null,
      },
    },
    solutionWithoutInteraction: {
      params: {
        saveAllowed: null,
        serviceId: null,
        term: null,
      },
    },
    cannotUpdateFirmware: {
      date: null,
      key: null,
      modemName: null,
      networkAuthentication: null,
      saveAllowed: null,
      scBackupEnabled: null,
      scFWUpgradeEnabled: null,
      scIPTVEnabled: null,
      scRebootEnabled: null,
      scResetEnabled: null,
      scRestoreEnabled: null,
      scWLANConfigurationEnabled: null,
      serviceId: null,
      ssid: null,
      ssidEnabled: null,
      tripplePlay: null,
    },
    troubleDetail: {
      troubles: [],
    },
    notSupportedModem: {
      isRedirect: true,
    },
  },
};

export default (
  state: Pages = initialState,
  action: Action | PageAction
): Pages => {
  switch (action.type) {
    case 'PROCESS': {
      const pageName = action.payload.pageName;
      var pageData;
      if (state.pages[pageName] && state.pages[pageName].lazyLoad) {
        pageData = state.pages[pageName];
        if (pageData.services && pageData.services.length) {
          pageData.services = pageData.services.concat(
            action.payload.data.services
          );
        } else if (pageData.troubles && pageData.troubles.length) {
          pageData.troubles = pageData.troubles.concat(
            action.payload.data.troubles
          );
        }
        pageData.lazyLoad = action.payload.data.lazyLoad;
      } else {
        // TODO: refactor this, move data operation from reducer
        if (state.pages[pageName as string]) {
          const newPage = Object.assign(
            state.pages[pageName as string],
            action.payload.data
          );
          pageData = {
            ...newPage,
          };
        } else {
          pageData = action.payload.data;
        }
      }
      return {
        ...state,
        pages: {
          ...state.pages,
          [pageName as any]: pageData,
        },
      };
    }
    case 'CLEAR_WAITING_SUCCESS': {
      return {
        ...state,
        waitingSuccess: {
          isSuccess: false,
          nextPage: null,
        },
      };
    }
    case 'START_TTS_PROCESS': {
      return {
        ...state,
        ttsInProgress: true,
      };
    }
    case 'FINISH_TTS_PROCESS': {
      return {
        ...state,
        ttsInProgress: false,
      };
    }
    case 'CLEAR_TTS': {
      return {
        ...state,
        ttsInProgress: false,
        ttsParams: {},
        isTtsProcess: false,
      };
    }
    case 'TTS_PROCESS':
      return {
        ...state,
        isTtsProcess: action.payload.isTtsProcess,
        ttsParams: action.payload.ttsParams,
      };
    case 'TTS_PROCESS_WAITING_SUCCESS': {
      return {
        ...state,
        waitingSuccess: action.payload,
      };
    }
    case 'SAVE_TROUBLE_ID': {
      return {
        ...state,
        pages: {
          ...state.pages,
          setTerm: {
            ...state.pages.setTerm,
            troubleId: action.payload,
          },
        },
      };
    }
    case 'SAVE_TERM': {
      return {
        ...state,
        pages: {
          ...state.pages,
          selectTerm: {
            ...state.pages.selectTerm,
            selectedTerm: action.payload,
          },
        },
      };
    }
    case 'SAVE_SELECTED_VALUE': {
      return {
        ...state,
        selectedValueId: action.payload,
      };
    }
    case 'LAZYLOAD_BATCH': {
      return {
        ...state,
        lazyLoadBatch: action.payload,
      };
    }
    case 'FILTERING_SERVICES_SUCCESS': {
      return {
        ...state,
        pages: {
          ...state.pages,
          selectService: {
            ...state.pages.selectService,
            services: action.payload,
          },
        },
      };
    }
    case 'REDIRECT_NOT_SUPPORTED_MODEM': {
      return {
        ...state,
        pages: {
          ...state.pages,
          notSupportedModem: {
            isRedirect: action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
};
