import React from 'react';
import { O2Indent, O2Button } from 'o2-theme-react';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import { useSelector } from 'react-redux';
import { logoutAndRedirect } from '../../../src/redux/actions/userActions';
import useAnalytics from '../../../src/hooks/useAnalytics';

const NoDevice: React.FC = (props: any) => {
  const namePage = 'noDevice';
  useAnalytics(namePage);
  const logoutConfiguration = useSelector<any, any>(
    state => state.config.o2Config['link.configuration']
  );

  const redirect = () => {
    logoutAndRedirect(logoutConfiguration);
  };

  return (
    <SimpleTextPage
      title={<Langtext text='funnel.noDevice.title' />}
      hasBacklink={false}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <Langtext text='funnel.noDevice.text' />
      </O2Indent>
      <O2Indent>
        <O2Button color='primary' onClick={() => redirect()}>
          <Langtext text='funnel.noDevice.login' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default NoDevice;
