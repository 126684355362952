import { I18n } from 'react-i18nify-lite';

import { COOKIE_NAME } from './constants/cookies';

export const setCookie = (name: string, value: string, domain?: string) => {
  document.cookie = `${name}=${value}; path=/; ${domain ? `domain=${domain}` : ''
    }`;
};

export const getCookie = (name: string) => {
  const b = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
};

export const clearCookies = () => {
  document.cookie = `${COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const initializeSessionCookie = () => {
  const cookieName = 'o2-session-id';

  if (!getCookie(cookieName)) {
    const apfSessionCookieDomain = (window as any).g_apfSessionCookieDomain;
    setCookie(cookieName, generateUUID(), apfSessionCookieDomain);
  }
};

export const generateUUID = () => {
  let d = performance.now(); // Timestamp
  let d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    (c: string) => {
      let r = Math.random() * 16; // random number between 0 and 16
      if (d > 0) {
        // Use timestamp until depleted
        // tslint:disable-next-line:no-bitwise
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        // Use microseconds since page-load if supported
        // tslint:disable-next-line:no-bitwise
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
};

export const emailIsValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isPasswordValid = (passString: string): string => {
  const haveDeniedChars = (pass: string): boolean => {
    // HELP: Refactor for new code standard
    for (let i = 0; i < pass.length; i++) {
      // eslint-disable-next-line
      const forbiddenChar = /^[^0-9a-zA-ZěščřžýáíéúóůňťďĚŠČŘŽÝÁÍÉÚŮŇŤĎ!@#\$%\^&\*()_\+,'\.\?\-=`\[\];\/]/.test(
        pass[i]
      );
      if (forbiddenChar) {
        return true;
      }
    }

    return false;
  };
  // TODO: REPLACE TRANSLATES BY FROM THIS APP REFERENCE LANG TEXT NAMESPACE.
  if (passString.length < 6) {
    return I18n.t('ume.ext.changePassword.passwordMin');
    // HELP:  Change to switch for -> ? tslint:disable-next-line:no-else-after-return
  } else if (passString.length > 25) {
    return I18n.t('ume.ext.changePassword.passwordMax');
  } else if (haveDeniedChars(passString)) {
    return I18n.t('ume.ext.changePassword.passwordRules');
  } else {
    return '';
  }
};

export const serviceIdFormatter = (serviceId: string) => {
  return serviceId.replace(/(.{3})/g, '$1 ').trim();
};