import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addDangerKahlMessage } from '../../src/redux/actions/kahlInfoActions';
import { useHistory } from 'react-router-dom';

const useHandleInappropriateRefresh = () => {
  const lastPage = useSelector<any, any>(state => state.paramsHolder.lastPage);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const kahlId = useSelector<any, any>(
    state => state.config.o2Config['funnelui.kahl.reinit.kahlMessageId']
  );

  useEffect(() => {
    if (lastPage === null) {
      setIsRefreshed(true);
      dispatch(addDangerKahlMessage(kahlId));
      history.push('/funnel?jwtChecked=true');
    }
  }, [dispatch, history, lastPage, kahlId]);

  useSelector<any, any>(state => state.user.langtextDisplay);

  return isRefreshed;
};

export default useHandleInappropriateRefresh;
