import HTMLBody from '../../../src/components/common/HTMLBody';
import Langtext from '../../../src/components/common/Langtext';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import get from 'lodash/get';
import { O2Button, O2Indent } from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const InformationChat: React.FC = () => {
  const namePage = 'informationChat';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const [destinationId] = useState(get(page, 'ttsParams.destinationId', null));

  useEffect(() => {
    return () => {
      if (typeof (window as any).$owidget != 'undefined') {
        (window as any).$owidget.addCustomData('WDG_SHOW_ICON', 'false');
      }
    };
  }, []);

  const openChat = () => {
    (window as any).postMessage({
      content: {
        reason: 'ManuallyCalled',
      },
      operation: 'RequestWidgetOpenWindow',
    });
  };

  return (
    <SimpleTextPage
      title={
        destinationId ? (
          <Langtext
            text={`funnel.codelist.destinationToTitle.${destinationId}`}
          />
        ) : (
          <Langtext text='funnel.informationChat.title' />
        )
      }
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        {destinationId && <HTMLBody id={destinationId} />}
        {!destinationId && <Langtext text='funnel.informationChat.text' />}
      </O2Indent>
      <O2Indent>
        <O2Button color='positive' onClick={() => openChat()}>
          <Langtext text='funnel.informationChat.chatButton' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default InformationChat;
