import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { O2Indent, O2Button } from 'o2-theme-react';
import Langtext from '../../../src/components/common/Langtext';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const TechnicalVisitConfirm: React.FC = (props: any) => {
  const namePage = 'submitTerm';
  const { formSubmit } = useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const troubleId = useSelector<any, any>(
    state => state.pages.pages['setTerm'].troubleId
  );
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);
  const selectedTerm = useSelector<any, any>(
    state => state.pages.pages['selectTerm'].selectedTerm
  );

  const confirm = () => {
    formSubmit();
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
          },
          ttsParams: {
            ...ttsParams,
            actionId: 'CONFIRMED',
            flowType: 'TT_TERM_CHANGE',
            ttId: troubleId,
          },
        },
        props
      )
    );
  };

  const selectTerm = () => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
          },
          ttsParams: {
            ...ttsParams,
            flowType: 'TT_TERM_CHANGE',
            ttId: troubleId,
          },
        },
        props
      )
    );
  };

  return (
    <SimpleTextPage
      title={<Langtext text='funnel.submitTerm.title' />}
      hasBacklink={true}
      backlinkTo='/funnel/trouble-list'
      backlink={<Langtext text='funnel.submitTerm.topLink' />}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <Langtext text='funnel.submitTerm.text' />{' '}
        <span className='o2-typo--weight-important'>{selectedTerm.text}</span>.
      </O2Indent>
      <O2Indent standalone={true}>
        <O2Button
          onClick={() => confirm()}
          color='important'
          indentRight={true}
          data-test-id='submitButton'
        >
          <Langtext text='funnel.submitTerm.confirmBtn' />
        </O2Button>
        <O2Button color='transparent' onClick={() => selectTerm()}>
          <Langtext text='funnel.submitTerm.anotherDateBtn' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default TechnicalVisitConfirm;
