import React from 'react';
import {
  O2Modal,
  O2ModalBody,
  O2ModalFooter,
  O2ModalFooterButton,
  O2Indent,
} from 'o2-theme-react';
import Langtext from './Langtext';

const HotKeysModal = (props: any) => {
  return (
    <O2Modal
      isOpen={props.open}
      toggle={props.closeModal}
      backdrop
      keyboard
      contentLeft
    >
      <O2ModalBody>
        <O2Indent>Wireframe number: {props.number}</O2Indent>
        <O2Indent>Wireframe link: {props.url}</O2Indent>
        <O2Indent standalone>
          <Langtext text='funnel.hotkeyModal.showTemplate' />
        </O2Indent>
      </O2ModalBody>
      <O2ModalFooter>
        <O2ModalFooterButton color='neutral' onClick={props.closeModal}>
          <Langtext text='funnel.hotkeyModal.closeBtn' />
        </O2ModalFooterButton>
      </O2ModalFooter>
    </O2Modal>
  );
};

export default HotKeysModal;
