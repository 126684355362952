import React from 'react';
import { useDispatch } from 'react-redux';
import { O2Indent, O2Button } from 'o2-theme-react';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import useAnalytics from '../../../src/hooks/useAnalytics';

const ConfigurationError: React.FC = (props: any) => {
  const namePage = 'configurationError';
  useAnalytics(namePage);
  const dispatch = useDispatch();

  const process = () => {
    dispatch(
      ttsProcess(
        {
          page: 'main',
          params: {
            action: 'deviceConfiguration',
          },
        },
        props,
        true
      )
    );
  };

  return (
    <SimpleTextPage
      hasBacklink={false}
      title={<Langtext text='funnel.configurationError.title' />}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <Langtext text='funnel.configurationError.text' />
      </O2Indent>
      <O2Indent>
        <O2Button color='primary' onClick={() => process()}>
          <Langtext text='funnel.configurationError.button' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default ConfigurationError;
