const initialState: any = {
  codeLists: {},
  wireframes: [],
  serviceTypeMatrix: [],
  troubleMatrix: [],
};

export default (state: any = initialState, action: any): any => {
  switch (action.type) {
    case 'GET_CODELISTS_SUCCESS':
      return {
        ...state,
        codeLists: {
          ...state.codeLists,
          ...action.payload,
        },
      };
    case 'INIT_WIREFRAMES':
      return {
        ...state,
        wireframes: action.payload,
      };
    case 'GET_SERVICE_TYPE_MATRIX':
      return {
        ...state,
        serviceTypeMatrix: action.payload,
      };
    case 'GET_TROUBLE_MATRIX':
      return {
        ...state,
        troubleMatrix: action.payload,
      };
    default:
      return state;
  }
};
