export type PageAction = {
  type:
    | 'CLEAR_WAITING_SUCCESS'
    | 'SAVE_TROUBLE_ID'
    | 'SAVE_TERM'
    | 'REDIRECT_NOT_SUPPORTED_MODEM';
  payload?: any;
};

export const clearSuccess = (): PageAction => {
  return {
    type: 'CLEAR_WAITING_SUCCESS',
  };
};

export const saveTroubleId = (troubleId: number): PageAction => {
  return {
    type: 'SAVE_TROUBLE_ID',
    payload: troubleId,
  };
};

export const saveSelectedTerm = (term: {
  id: string;
  text: string;
}): PageAction => {
  return {
    type: 'SAVE_TERM',
    payload: term,
  };
};

export const redirectNotSupportedModem = (isRedirect: boolean) => {
  return {
    type: 'REDIRECT_NOT_SUPPORTED_MODEM',
    payload: isRedirect,
  };
};
