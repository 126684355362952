import { O2Indent, O2Button, O2Section, O2ButtonGroup } from 'o2-theme-react';
import React from 'react';

import PageTitle from '../../../src/components/common/PageTitle';
import Langtext from '../../../src/components/common/Langtext';
import { useSelector, useDispatch } from 'react-redux';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const TechnicianVisitOffer: React.FC = props => {
  const namePage = 'technicianVisitOffer';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);

  const proceed = (status: string) => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
          },
          ttsParams: {
            ...ttsParams,
            actionId: status,
          },
        },
        props
      )
    );
  };
  return (
    <>
      <PageTitle>
        <Langtext text='funnel.technicianVisitOffer.title' />
      </PageTitle>
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Section>
        <br />
        <O2Indent>
          <p>
            <Langtext text='funnel.technicianVisitOffer.text1' />
          </p>
          <br />
          <br />
          <p>
            <Langtext text='funnel.technicianVisitOffer.text2' />
          </p>
        </O2Indent>
        <O2Indent>
          <O2ButtonGroup>
            <O2Button
              indentRight
              color='primary'
              onClick={() => proceed('YES')}
            >
              <Langtext text='funnel.technicianVisitOffer.button1Text' />
            </O2Button>
            <O2Button color='internal' onClick={() => proceed('NO')}>
              <Langtext text='funnel.technicianVisitOffer.button2Text' />
            </O2Button>
            <O2Button
              indentRight
              color='transparent'
              onClick={() => proceed('CONTINUE')}
            >
              <Langtext text='funnel.technicianVisitOffer.button3Text' />
            </O2Button>
          </O2ButtonGroup>
        </O2Indent>
      </O2Section>
    </>
  );
};

export default TechnicianVisitOffer;
