import { O2Indent, O2Button, O2Section, O2ButtonGroup } from 'o2-theme-react';
import React from 'react';

import PageTitle from '../../../src/components/common/PageTitle';
import Langtext from '../../../src/components/common/Langtext';
import { useSelector } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';

const ServiceUnavailable: React.FC = props => {
  const namePage = 'serviceUnavailable';
  useAnalytics(namePage);
  const o2TVLink = useSelector<any, any>(
    state => state.config.o2Config['link.o2tv']
  );

  const redirect = () => {
    window.location.href = o2TVLink;
  };

  return (
    <>
      <PageTitle>
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.serviceUnavailable.title' />
      </PageTitle>
      <O2Section>
        <O2Indent>
          <p>
            <Langtext text='funnel.serviceUnavailable.text' />
          </p>
        </O2Indent>
        <O2Indent>
          <O2ButtonGroup>
            <O2Button indentRight color='primary' onClick={() => redirect()}>
              <Langtext text='funnel.serviceUnavailable.buttonText' />
            </O2Button>
          </O2ButtonGroup>
        </O2Indent>
      </O2Section>
    </>
  );
};

export default ServiceUnavailable;
