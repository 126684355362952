import React from 'react';
import { useSelector } from 'react-redux';
import Langtext from '../common/Langtext';
import HeaderLogged from './HeaderLogged';

const Header = () => {
  const apfThemeLocation = (window as any).g_apfThemeLocation;
  const user = useSelector<any, any>(state => state.user);
  const o2Config = useSelector<any, any>(state => state.config.o2Config);

  const isJWTChecked = useSelector<any, any>(
    state => state.config.isJWTChecked
  );
  const slug = window.location.pathname;
  if (slug === '/funnel/') {
    if (!isJWTChecked) return null;
  }

  // header for logged external user
  if (user.isLoggedIn) {
    return <HeaderLogged />;
  }

  return (
    <div className='o2-header'>
      {/*
      <div className='o2-header__top'>
        <div className='o2-header__width'>
          <ul className='o2-header__topnavig'>
            <li className='o2-header__topnavig-item'>
              <a
                href='https://www.o2.cz/osobni/'
                className='o2-header__topnavig-link'
              >
                <Langtext text='funnel.layout.header.userTypesRow.personal' />
              </a>
            </li>
            <li className='o2-header__topnavig-item'>
              <a
                href='https://www.o2.cz/podnikatel/'
                className='o2-header__topnavig-link'
              >
                <Langtext text='funnel.layout.header.userTypesRow.business' />
              </a>
            </li>
            <li className='o2-header__topnavig-item'>
              <a
                href='https://www.o2.cz/firmy-a-organizace/'
                className='o2-header__topnavig-link'
              >
                <Langtext text='funnel.layout.header.userTypesRow.company' />
              </a>
            </li>
          </ul>
        </div>
      </div>
      
      */}
      <div className='o2-header__width'>
        <div className='o2-header__controls js-hfit-bumper'>
          {/*
                    <a
            href='https://www.o2.cz/osobni/vyhledavani/'
            className='o2-header__link o2-header__link--icon'
          >
            <span className='o2-icon o2-icon--search o2-icon--lg'></span>
          </a>
          <a
            href='https://eshop.o2.cz/shop/shoppingcart/'
            className='o2-header__link o2-header__link--icon'
          >
            <span className='o2-icon o2-icon--basket o2-icon--xl'></span>
          </a>
          <a
            href='https://moje.o2.cz/login/'
            className='o2-btn o2-btn--primary o2-btn--small h-show-sm-up-inline'
          >
            <Langtext text='funnel.layout.header.btn.myO2' />
          </a>
          <a
            href='#o2-header-menu'
            className='o2-header__link h-hide-md-up-inline js-toggle'
            data-toggle-profile='hamburger'
          >
            <span className='o2-icon o2-icon--hamburger'></span>
          </a>
          */}

          <a href={o2Config['link.login']} className='o2-header__link'>
            <Langtext text='funnel.layout.header.mainLinks.logIn' />
          </a>
        </div>

        <div className='o2-header__links'>
          <a
            href={o2Config['link.selfcare']}
            className='o2-header__logo js-hfit-starter'
          >
            <img
              src={`${apfThemeLocation}/images/header/logo-header-moje-o2.png`}
              className='o2-header__logo-img'
              alt=''
            />
          </a>
          {/*
          <div
            className='o2-header__navig js-fixed'
            data-fixed-profile='mobileMenu'
            id='o2-header-menu'
          >
            <div className='o2-header__navig-mobile'>
              <a href='2-1-1-zakaznik.html' className='o2-header__logo'>
                <img
                  src={`${apfThemeLocation}/images/header/logo-header-moje-o2.png`}
                  className='o2-header__logo-img o2-header__logo-img--mobile'
                  alt=''
                />
              </a>
              <a
                href='/'
                className='o2-header__navig-close'
                id='o2-header-menu-close'
              >
                <span className='o2-icon o2-icon--sm o2-icon--cross'></span>
              </a>
            </div>
            <div className='o2-header__navig-content'>
              <ul className='o2-header__navig-list js-hfit'>
                <li className='o2-header__navig-item'>
                  <a
                    href='https://www.o2.cz/osobni/mobilni-tarify/'
                    className='o2-header__link'
                  >
                    <Langtext text='funnel.layout.header.mainLinks.calling' />
                  </a>
                </li>
                <li className='o2-header__navig-item'>
                  <a
                    href='https://www.o2.cz/osobni/adsl-vdsl-internet/'
                    className='o2-header__link'
                  >
                    <Langtext text='funnel.layout.header.mainLinks.internet' />
                  </a>
                </li>
                <li className='o2-header__navig-item'>
                  <a
                    href='https://www.o2.cz/osobni/televize/'
                    className='o2-header__link'
                  >
                    <Langtext text='funnel.layout.header.mainLinks.o2TV' />
                  </a>
                </li>
                <li className='o2-header__navig-item'>
                  <a
                    href='https://www.o2.cz/osobni/mobilni-telefony/'
                    className='o2-header__link'
                  >
                    <Langtext text='funnel.layout.header.mainLinks.phonesAndDevices' />
                  </a>
                </li>
                <li className='o2-header__navig-item'>
                  <a
                    href='https://www.o2.cz/osobni/o2-rb-karta/'
                    className='o2-header__link'
                  >
                    <Langtext text='funnel.layout.header.mainLinks.financies' />
                  </a>
                </li>
                <li className='o2-header__navig-item'>
                  <a
                    href='https://www.o2.cz/osobni/pece-a-podpora/'
                    className='o2-header__link o2-header__link--active'
                  >
                    <Langtext text='funnel.layout.header.mainLinks.support' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          */}
        </div>
      </div>
    </div>
  );
};

export default Header;
