import React, { useState } from 'react';

import get from 'lodash/get';
import CmsResourceApi from '../../api/cmsResourceApi';

const HTMLBody = (props: any) => {
  const [pageBody, setPageBody] = useState('');
  const cmsApi = new CmsResourceApi();
  cmsApi
    .getHTMLTemplate(props.id)
    .then(res => {
      const response = get(res, `data.funnel-app.<ANY>.cs[${props.id}]`, null);
      setPageBody(response);
    })
    .catch(err => console.log(err.message));

  // return <div dangerouslySetInnerHTML={{ __html: pageBody + '' + props.suffix }} />;
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `${pageBody} ${
          props.suffix ? `<strong>${props.suffix}</strong>` : ''
        }`,
      }}
    />
  );
};

export default HTMLBody;
