import { sendGet } from './requestUtils';
import { RESOURCE_APPLICATION } from '../../src/resourceUtils';

const API_PATH = '/api/cms-resource/public/v1/resource/';

export default class CmsResourceApi {
  static getLangTexts() {
    return sendGet(`${API_PATH}LANGTEXT/${RESOURCE_APPLICATION}`, null, 'json');
  }

  public getHTMLTemplate(resourceKey: string) {
    return sendGet(
      `${API_PATH}HTML_TEMPLATE/${RESOURCE_APPLICATION}/${resourceKey}`,
      null,
      'json'
    );
  }
}
