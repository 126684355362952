import { Action, ActionLoading } from '../actions/sharedObjectsActions';

const initialState: any = {
  isLoading: false,
  sharedObjectSession: null
};

export default (state: any = initialState, action: Action | ActionLoading): any => {
  switch (action.type) {
    case 'START_SHARED_OBJECTS_SESSION':
      return {
        ...state,
        isLoading: true,
      };
    case 'INIT_SHARED_OBJECTS_SESSION':
      return {
        ...state,
        sharedObjectSession: action.sharedObjectSession,
        isLoading: false,
      };
    default:
      return state;
  }
};