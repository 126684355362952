import React, { useEffect, useState, useRef } from 'react';
import { I18n } from 'react-i18nify-lite';
import { useSelector } from 'react-redux';
import { O2InfoMessage } from 'o2-theme-react';

const Langtext = (text: any) => {
  const langtextMode = useSelector<any, any>(
    state => state.user.langtextDisplay
  );
  const [display, setDisplay] = useState<boolean>(false);
  const refElement = useRef<any>([]);

  useEffect(() => {
    const { current } = refElement;

    const handleOnMouseEnter = () => {
      setDisplay(true);
    };
    const handleOnMouseLeave = () => {
      setDisplay(false);
    };
    current?.addEventListener('mouseenter', handleOnMouseEnter);
    current?.addEventListener('mouseleave', handleOnMouseLeave);
  });

  return (
    <span style={{ position: 'relative' }}>
      {langtextMode && display && (
        <O2InfoMessage
          color='internal'
          style={{
            position: 'absolute',
            top: '-80px',
            fontSize: '14px',
            zIndex: 99999,
          }}
        >
          <p>Langtext key: {text.text}</p>
        </O2InfoMessage>
      )}
      <span
        ref={refElement}
        dangerouslySetInnerHTML={{
          __html:
            text &&
            I18n.t(text.text, { interpolation: { escapeValue: false } }),
        }}
      />
    </span>
  );
};

export default Langtext;
