const hdmFunctions = {
  options: [
    {
      title: 'funnel.selectHdmFunction.wifi.title',
      desc: 'funnel.selectHdmFunction.wifi.description',
      link: '/funnel/view-wifi',
      iconName: 'wifi',
      keyName: 'scWLANConfigurationEnabled',
      testId: 'scWLANConfigurationLink',
    },
    {
      title: 'funnel.selectHdmFunction.lan.title',
      desc: 'funnel.selectHdmFunction.lan.description',
      link: '/funnel/setup-lan',
      iconName: 'lanport',
      keyName: 'scIPTVEnabled',
      testId: 'scIPTVLink',
    },
    {
      title: 'funnel.selectHdmFunction.firmwareUpdates.title',
      desc: 'funnel.selectHdmFunction.firmwareUpdates.description',
      link: '/funnel/update-firmware',
      iconName: 'refresh',
      keyName: 'scFWUpgradeEnabled',
      testId: 'scFWUpgradeLink',
    },
    {
      title: 'funnel.selectHdmFunction.backupSettings.title',
      desc: 'funnel.selectHdmFunction.backupSettings.description',
      link: '/funnel/backup',
      iconName: 'download',
      keyName: 'scBackupEnabled',
      testId: '',
    },
    {
      title: 'funnel.selectHdmFunction.restoreFromBackup.title',
      desc: 'funnel.selectHdmFunction.restoreFromBackup.description',
      link: '/funnel/recovery',
      iconName: 'upload',
      keyName: 'scRestoreEnabled',
      testId: '',
    },
    {
      title: 'funnel.selectHdmFunction.modemRestart.title',
      desc: 'funnel.selectHdmFunction.modemRestart.description',
      link: '/funnel/restart',
      iconName: 'reload',
      keyName: 'scRebootEnabled',
      testId: '',
    },
    {
      title: 'funnel.selectHdmFunction.reset.title',
      desc: 'funnel.selectHdmFunction.reset.description',
      link: '/funnel/reset',
      iconName: 'exclamation-triangle',
      keyName: 'scResetEnabled',
      testId: '',
    },
  ],
};

export default hdmFunctions;
