import { O2Indent, O2Section, O2Button, O2InfoMessage } from 'o2-theme-react';
import React from 'react';
import PageTitle from '../../../src/components/common/PageTitle';
import { useSelector, useDispatch } from 'react-redux';
import Langtext from '../../../src/components/common/Langtext';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const UpdateFw: React.FC = (props: any) => {
  const namePage = 'firmwareUpdate';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const serviceId = useSelector<any, any>(state => state.pages.selectedValueId);
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);

  const proceed = () => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: serviceId,
          },
          ttsParams: {
            ...ttsParams,
            actionId: 'OK',
          },
        },
        props
      )
    );
  };

  return (
    <>
      <PageTitle
        to='/funnel/select-hdm-function'
        backlink={<Langtext text='funnel.firmwareUpdate.topLink' />}
      >
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.firmwareUpdate.title' />
      </PageTitle>

      <O2Section>
        <O2Indent>
          {serviceId}-{page.params.modemName}
        </O2Indent>
        <O2Indent>
          <O2Indent>
            <Langtext text='funnel.firmwareUpdate.text' />
          </O2Indent>
          <O2Indent>
            <O2InfoMessage color='internal'>
              <Langtext text='funnel.firmwareUpdate.lineText' />
            </O2InfoMessage>
          </O2Indent>
        </O2Indent>
      </O2Section>
      <O2Section>
        <O2Button
          onClick={() => proceed()}
          color='primary'
          data-test-id='submitButton'
        >
          <Langtext text='funnel.firmwareUpdate.button' />
        </O2Button>
      </O2Section>
    </>
  );
};

export default UpdateFw;
