import Langtext from '../../../src/components/common/Langtext';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import get from 'lodash/get';
import queryString from 'query-string';
import { O2Button, O2Indent } from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import { useLocation } from 'react-router-dom';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';
import HTMLBody from '../../../src/components/common/HTMLBody';

const InformationWithTwoActions: React.FC = (props: any) => {
  const namePage = 'informationWithTwoActions';
  const dispatch = useDispatch();
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const location = useLocation();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const { id } = queryString.parse(location.search);
  const [destinationId, setDestinationId] = useState<any>(null);

  const destinationToOption1Action = useSelector<any, any>(
    state => state.appCache.codeLists.destinationToOption1Action
  );

  const destinationToOption2Action = useSelector<any, any>(
    state => state.appCache.codeLists.destinationToOption2Action
  );

  const pages = useSelector<any, any>(state => state.pages);
  const ttsParams = pages.ttsParams;

  useEffect(() => {
    if (id !== null && id !== undefined) {
      setDestinationId(id);
    } else {
      setDestinationId(get(page, 'ttsParams.destinationId', null));
    }

    // hide chat when component unmounts
    return () => {
      if (typeof (window as any).$owidget != 'undefined') {
        (window as any).$owidget.addCustomData('WDG_SHOW_ICON', 'false');
      }
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      ttsParams.destinationId !== null &&
      ttsParams.destinationId !== undefined
    ) {
      setDestinationId(ttsParams.destinationId);
    }

    // eslint-disable-next-line
  }, [ttsParams.destinationId]);

  const process = (actionId: string) => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
          },
          ttsParams: {
            ...ttsParams,
            actionId,
          },
        },
        props
      )
    );
  };

  return (
    <SimpleTextPage
      title={
        destinationId ? (
          <Langtext
            text={`funnel.codelist.destinationToTitle.${destinationId}`}
          />
        ) : (
          <Langtext text='funnel.informationWithTwoActions.title' />
        )
      }
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>{destinationId && <HTMLBody id={destinationId} />}</O2Indent>
      {destinationId && (
        <O2Indent>
          <O2Button
            onClick={() => {
              process(destinationToOption1Action[destinationId]);
            }}
          >
            <Langtext
              text={`funnel.codelist.destinationToOption1Text.${destinationId}`}
            />
          </O2Button>
          <O2Button
            onClick={() => {
              process(destinationToOption2Action[destinationId]);
            }}
          >
            <Langtext
              text={`funnel.codelist.destinationToOption2Text.${destinationId}`}
            />
          </O2Button>
        </O2Indent>
      )}
    </SimpleTextPage>
  );
};

export default InformationWithTwoActions;
