const O2TVCannotLoginDataMock = (imagesPath: string) => {
  return {
    cards: [
      {
        title: 'funnel.o2tvCannotLogin.cards.setTopBox.title',
        description: 'funnel.o2tvCannotLogin.cards.setTopBox.description',
        id: 'setTopBox',
        image: imagesPath + 'ico-desktop.png',
      },
      {
        title: 'funnel.o2tvCannotLogin.cards.internetO2TV.title',
        description: 'funnel.o2tvCannotLogin.cards.internetO2TV.description',
        id: 'internet',
        image: imagesPath + 'ico-desktop.png',
      },
      {
        title: 'funnel.o2tvCannotLogin.cards.O2TVapp.title',
        description: 'funnel.o2tvCannotLogin.cards.O2TVapp.description',
        id: 'application',
        image: imagesPath + 'ico-desktop.png',
      },
    ],
  };
};

export default O2TVCannotLoginDataMock;
