import { Action } from '../actions/paramsHolderActions';
import { ParamsHolder } from './types';

const initialState: any = {
  lastPage: null,
  serviceId: null,
};

export default (
  state: ParamsHolder = initialState,
  action: Action
): ParamsHolder => {
  switch (action.type) {
    case 'SET_HOLDERS_FOR_WAITING':
      return {
        ...state,
        lastPage: action.lastPage,
        serviceId: action.serviceId,
      };
    default:
      return state;
  }
};
