import { sendPost } from './requestUtils';
import { ProcessRequestParams, ProcessResponse } from './types';

const API_PATH = '/api/funnel/public/v1/';

export default class TtsApi {
  public call(processParams: ProcessRequestParams) {
    return sendPost<ProcessResponse>(`${API_PATH}process`, null, processParams);
  }
}
