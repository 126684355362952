import React, { useState, useRef } from 'react';
import Map from './Map';

import jtsk2wgs84 from '../../utils/jtsk2wgs84';
import {
  O2Addon,
  O2AddonContent,
  O2ControlWrapper,
  O2Indent,
  O2InfoMessage,
  O2TextField,
} from 'o2-theme-react';
import Langtext from '../../../src/components/common/Langtext';
import useAnalytics from '../../../src/hooks/useAnalytics';
import MapAPI from '../../../src/api/mapApi';

const MapWithAutoComplete = (props: any) => {
  const { setEventName } = useAnalytics();
  const mapRef: any = useRef();
  const [addresses, setAddresses] = useState([]);
  // eslint-disable-next-line
  const [address, setAddress] = useState(null);
  const [input, setInput] = useState('');
  const [coordX, setCoordX] = useState('');
  const [coordY, setCoordY] = useState('');
  const [changedCoords, setChangedCoords] = useState<boolean>(false);

  const getAddresses = async (value: string) => {
    const url = `?mask=${value}&returnCoords=TRUE`;

    const result = await MapAPI.getAddressFromFragment(url);
    setAddresses(result.data.addresses.addresses);
  };

  const handleMap = (value: any) => {
    setEventName('funnel_addressFirstInteraction');
    setInput(value);

    if (value.length > 2) {
      getAddresses(value);
    }
  };

  const mapCallback = (data: any) => {
    let LATITUDE = 0;
    let LONGITUDE = 0;

    if (data.Projection === 'JTSK') {
      const { lat, lon } = jtsk2wgs84(data.X, data.Y);
      LATITUDE = lat;
      LONGITUDE = lon;
    } else {
      LATITUDE = data.Lat;
      LONGITUDE = data.Lon;
    }

    props.mapCallback({
      massTtId: data.TTID,
      localityList: data.Technologies,
      coords: {
        x: LATITUDE,
        y: LONGITUDE,
      },
    });
  };

  const selectAddress = (address: any) => {
    setEventName('funnel_addressSelected');
    setAddress(address);
    setInput(address.description);
    mapRef.current.setupLocation(`${address.lat};${address.lng}`);

    setCoordX(address.lat);
    setCoordY(address.lng);
    setChangedCoords(!changedCoords);

    props.handleMap({
      address: address.description,
      coords: { x: address.lat, y: address.lng },
    });
    setAddresses([]);
  };

  const renderMessage = (msg: any) => {
    if (typeof msg !== 'undefined') {
      return (
        <O2Indent>
          <O2InfoMessage color='good'>
            <O2Addon reversed>
              <O2AddonContent>
                <O2Indent data-test-id='outageBar'>
                  <Langtext text='funnel.common.mapWithAutocomplete.msgs.success' />
                </O2Indent>
              </O2AddonContent>
            </O2Addon>
          </O2InfoMessage>
        </O2Indent>
      );
    }
  };

  return (
    <>
      <O2Indent>
        <O2ControlWrapper>
          <O2TextField
            inputType='filter'
            controlSize='large'
            onChange={e => {
              handleMap(e.target.value);
            }}
            value={input}
            data-test-id='searchAddressInput'
          />
        </O2ControlWrapper>
      </O2Indent>
      {addresses.length > 0 && (
        <O2Indent
          top='close'
          style={{
            border: '1px solid #ebebeb',
            borderRadius: 5,
            background: 'white',
            marginTop: 0,
          }}
        >
          {addresses.map((address: any) => (
            <p
              data-test-id='searchAddressResult'
              style={{ padding: '5px 10px', cursor: 'pointer' }}
              onClick={() => selectAddress(address)}
            >
              {address.description}
            </p>
          ))}
        </O2Indent>
      )}
      {renderMessage(props.outcomeMessage)}
      <O2Indent>
        <Map
          ref={mapRef}
          defaultProps={props.defaultCoords}
          changeCallback={(data: any) => mapCallback(data)}
          defaultZoom={100000}
          coordX={coordX}
          coordY={coordY}
          changedCoords={changedCoords}
          />
      </O2Indent>
    </>
  );
};

export default MapWithAutoComplete;
