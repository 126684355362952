export default {
  funnel: {
    main: {
      topLink: 'Péče a podpora',
      title: 'On-line řešení problémů',
      cards: {
        issueCard: { title: 'Nahlásit poruchu' },
        historyCard: { title: 'Historie poruch' },
        modemCard: { title: 'Nastavení modemu' },
      },
      frequentIssues: {
        title: 'Řešení nejčastějších problémů',
        labels: {
          calls: 'Volání z pevné',
          mobileInternet: 'Mobilní internet',
          sms: 'SMS',
          interet: 'Pevný internet',
          mobileCalls: 'Volání z mobilu',
          mms: 'MMS',
          tv: 'O2 TV',
          internetInMobile: 'Internet v mobilu',
        },
      },
      otherLinks: {
        title: 'Mohlo by se vám hodit',
        links: {
          downtimeInfo: 'Informace o výpadcích',
          instructions: 'Instruktážní videa',
          mobileModems: 'Mobilní modemy',
          speedMeasurement: 'Měření rychlosti',
          coverageMap: 'Mapa pokrytí',
          mobiles: 'Mobilní telefony',
          forum: 'O2 Fórum',
          modems: 'Modemy pro pevný internet',
        },
      },
    },
    common: {
      mapWithAutocomplete: {
        msgs: {
          error: 'V zadané oblasti evidujeme výpadek.',
          success: 'Zvolená oblast je bez výpadku.',
        },
      },
    },
    notLogged: {
      topLink: 'On-line řešení problémů',
      title: 'Nahlásit problém',
      unloggedUserMsg:
        'Abyste mohli naplno využít všech funkcionalit on-line řešení poruch, musíte se přihlásit.',
      loginBtn: 'Přihlásit se',
      continueBtn: 'Pokračovat bez přihlášení',
    },
    selectService: {
      topLink: 'On-line řešení problémů',
      title: 'Nahlásit problém',
      descriptionTitle: 'Vyhledávání služeb',
      description:
        'Zadejte telefonní číslo, číslo služby, název, nebo adresu umístění služby se kterou máte problémy.',
      buttons: {
        loadMore: 'Načíst další',
        buttonOtherUser: 'Vybrat službu jiného z mých zákazníků',
        buttonOtherService: 'Chci nahlásit poruchu na jiné službě',
        buttonShowOtherServices: 'Vyhledat další mé služby',
      },
    },
    selectTrouble: {
      topLink: 'On-line řešení problémů',
      title: 'Nahlásit poruchu na službě',
      anotherServiceLink: 'Jiná služba',
      anotherIssueLink: 'Jiný typ',
      selectIssueTitle: 'Vyberte typ problému',
      selectIssueDescription:
        'Která z následujících možností nejlépe vystihuje váš problém?',
      issueLocationTitle: 'Místo poruchy',
      issueLocationDescription:
        'Zadejte adresu, nebo na mapě zvolte co nejpřesněji místo, kde máte potíže se službou.',
      change: 'Změnit',
      continue: 'Pokračovat',
      noLocationContinue: 'Pokračovat bez určení místa',
      no: 'Ne, nechci',
      yes: 'Ano, chci pokračovat',
      contactNumberTitle: 'Na jakém čísle vás můžeme kontaktovat?',
      contactNumberDescription:
        'Na zadané číslo Vás budeme infomovat o průběhu řešení požadavku.',
      issueNote: 'Poznámka k hlášené poruše',
      reportIssueBtn: 'Nahlásit poruchu',
      placeSelectedOnMap: 'Místo vybráno na mapě',
      links: {
        infoAboutProblems: 'Informace o výpadcích',
        speedMeasurement: 'Měření rychlosti',
        O2Forum: 'O2 Fórum',
        guideVideo: 'Instruktážní videa',
        map: 'Mapa pokrytí',
        modem: 'Modemy pro pevný internet',
        mobileModems: 'Mobilní modemy',
        mobilePhones: 'Mobilní telefony',
      },
      outage: {
        quote1: 'V zadané oblasti evidujeme výpadek.',
        quote2: 'O poruše víme - Chcete ji přesto nahlásit?',
      },
    },
    o2tvCannotLogin: {
      topLink: 'Nahlášení poruchy',
      title: 'Jak sledujete O2 TV?',
      cards: {
        setTopBox: {
          title: 'Přes set-top box',
          description:
            'Sledujete O2 TV na televizi, ke které máte připojený černý set-top box?',
        },
        internetO2TV: {
          title: 'Přes internet na o2tv.cz',
          description:
            'Sledujete vysílání na počítači, v tabletu nebo mobilu pomocí internetového prohlížeče na adrese www.o2tv.cz?',
        },
        O2TVapp: {
          title: 'V aplikaci O2 TV',
          description:
            'Sledujete televizi v aplikaci na mobilu, tabletu, Xboxu nebo přes Google Chromecast?',
        },
      },
    },
    troubleList: {
      topLink: 'On-line řešení problémů',
      title: 'Historie poruch',
      issue: 'Porucha',
      service: 'Služba',
      setupDate: 'Založeno',
      status: 'Stav požadavku',
      inprogress: 'V řešení',
      done: 'Vyřešeno',
      emptyHistoryMsg: 'Historie hlášených poruch je prázdná',
      loadMore: 'Načíst další',
    },
    troubleDetail: {
      topLink: 'Historie poruch',
      title: 'Detail poruchy',
      serviceType: 'Typ služby',
      serviceNum: 'Číslo služby',
      issueStatus: 'Stav řešení',
      solutionTerm: 'Termín řešení',
      change: 'Změnit',
      resolutionTerm: 'Termín vyřešení',
      issueType: 'Typ problému',
      diagnosticResult: 'Výsledek diagnostiky',
      setTerm: 'Založeno',
      technicalReq: 'Technický požadavek',
      internalSubmitter: 'Interní zadavatel',
      continueResolving: 'Pokračovat v řešení',
      cancel: 'Stornovat řešení poruchy',
      isSolvedQuestion: 'Problém se službou jsme vyřešili. Funguje vše jak má?',
      worksBtn: 'Funguje',
      notWorksBtn: 'Nefunguje',
      dontKnow: 'Nezjištěno',
      info: {
        title: 'Opravdu chcete stornovat řešení poruchy?',
        text:
          'Řešení poruchy bude zastaveno a už ho nebude možné znovu obnovit.',
        yesBtn: 'Stornovat',
        noBtn: 'Nestornovat',
      },
    },
    outagesSelectService: {
      topLink: 'On-line řešení problémů',
      title: 'Informace o výpadcích',
      text:
        'Zvolte typ služby, na které chcete ověřit, zda není ovlivněná hromadným výpadkem, případně probíhající plánovanou výlukou.',
      fixedServices: 'Pevné služby',
      mobileServices: 'Mobilní služby',
    },
    waiting: {
      title: 'Probíhá kontrola',
      text:
        'Kontrolujeme stav vaší služby. Chvilku nám to bude trvat. Počkejte prosím...',
    },
    informationChat: {
      topLink: 'On-line řešení problémů',
      title: 'Kontaktujte nás',
      text:
        'Nepodařilo se nám dát dohromady dostatek informací o poruše. Kontaktujte nás prosím na chatu, kde se s vámi na problém podíváme podrobněji.',
      chatButton: 'Zahájit on-line chat',
    },
    informationWithTwoActions: {
      topLink: 'On-line řešení problémů',
      title: 'Kontaktujte nás',
      text:
        'Nepodařilo se nám dát dohromady dostatek informací o poruše. Kontaktujte nás prosím na chatu, kde se s vámi na problém podíváme podrobněji.',
    },
    informationSelfcare: {
      topLink: 'On-line řešení problémů',
      title: 'Vyčerpán limit pro stahování dat',
      description:
        'Díky dosažení povoleného limitu pro čerpání dat došlo ke snížení rychlosti Vašeho internetu v mobilu. Plná rychlost bude obnovena na začátku nového zúčtovacího období.',
      button: 'Přejít do moje O2',
    },
    informationChatSelfcare: {
      topLink: 'On-line řešení problémů',
      title: 'Problém se službou',
      description:
        'Zaznamenali jsme problém při diagnostice. Ověřte stav služby v Moje O2, nebo nás kontaktujte na on-line chatu.',
      chatBtn: 'Zahájit on-line chat',
      detailsBtn: 'Přejít na detail služby',
    },
    invalidCombination: {
      topLink: 'On-line řešení problémů',
      title: 'Chyba ověřování',
      backBtn: 'Zpět na zadání problému',
      service: 'Služba',
      onNum: 'na čísle',
      message: 'neexistuje. Zvolte, prosím, jinou kombinaci.',
    },
    deviceConfigRequired: {
      topLink: 'On-line řešení problémů',
      title: 'Špatně nastavený modem',
      description:
        'Zjistili jsme, že váš modem není správně nastavený pro využití služby O2 TV Multi, a proto je nutné ho přenastavit. To můžete provést pohodlně on-line.',
      settingModem: 'Nastavení modemu',
    },
    address: {
      topLink: 'Výběr zásahu',
      title: 'Zaslání set-top boxu',
      text: 'Zadejte adresu, na kterou chcete set-top box zaslat',
      firstName: 'Jméno',
      lastName: 'Příjmení',
      companyName: 'Obchodní jméno',
      companyNameHint: 'Pouze pro firmy a živnostníky.',
      address: 'Adresa',
      addressHint: 'Jste si jistí, že je adresa správně?',
      tryAddressManually: 'Zkuste ji zadat ručně',
      continue: 'Pokračovat',
      modal: {
        title: 'Ruční zadání adresy',
        street: 'Ulice',
        flatNum: 'Číslo popisné/evidenční',
        flatNumHint: 'Ev. č. zadejte ve tvaru "č.ev. 8"',
        houseNum: 'Číslo orientační',
        city: 'Obec',
        cityPart: 'Část obce',
        postalCode: 'PSČ',
        country: 'Stát',
        saveBtn: 'Uložit',
        canelBtn: 'Zrušit',
      },
    },
    selectDevice: {
      title: 'Nastavení modemu',
      text: 'Vyberte službu, na níž chcete nastavit modem',
      anotherUserInfo:
        'Pro nastavení modemu na dalších službách zvolte jiného zákazníka',
      switchUsersBtn: 'Vybrat jiného zákazníka',
    },
    notSupportedCustomer: {
      topLink: 'On-line řešení problémů',
      text: 'Nastavení modemu proveďte z detailu služby v Moje O2',
      button: 'Moje O2',
    },
    notSupportedModem: {
      topLink: 'On-line řešení problémů',
      title: 'Nepodporovaný modem',
      msg:
        'Nejpíš máte typ modemu, který nelze nastavit automaticky. Vyberte z nabídky své zařízení a pokračujte podle návodu pro manuální nastavení.',
      btn: 'Přejít na návody',
    },
    selectHdmFunction: {
      topLink: 'Seznam modemů',
      title: 'Návody pro váš modem',
      wifi: {
        title: 'Bezdrátová Wi-Fi síť',
        description: 'Nastavení názvu sítě, hesla a zabezpečení',
      },
      lan: {
        title: 'LAN porty',
        description: 'Nastavení zdířek pro síťové kabely pro internet a O2TV',
      },
      firmwareUpdates: {
        title: 'Aktualizace Firmware',
        description: 'Udržuje váš modem zabezpečený a stabilní',
      },
      backupSettings: {
        title: 'Záloha nastavení',
        description: 'Zálohuje uživatelská nastavení modemu',
      },
      restoreFromBackup: {
        title: 'Obnovení ze zálohy',
        description: 'Obnoví vaše předem uložené nastavení',
      },
      modemRestart: {
        title: 'Restart modemu',
        description: 'Vypne a znovu zapne modem',
      },
      reset: {
        title: 'Reset do továrního nastavení',
        description: 'Všechno uživatelské nastavení bude smazáno',
      },
    },
    selectNumber: {
      topLink: 'On-line řešení problémů',
      title: 'Nahlásit problém se službou',
      description:
        'Zadejte telefonní číslo nebo číslo služby, se kterou máte problémy.',
      warning:
        'Chystáte se nahlásit problém na službě, kterou nemáte oprávnění spravovat. Některé způsoby řešení mohou být nedostupné.',
      phoneNum: 'Telefonní číslo nebo číslo služby',
      phoneNumHint:
        'Číslo služby naleznete na jakémkoliv vyúčtování. Pokud číslo neznáte, kontaktujte nás na telefonu 800 02 02 02.',
      phoneNumErr:
        'Číslo služby se nám nepodařilo dohledat. Zkontrolujte, prosím, jestli jste číslo zadali ve správném tvaru (např. 777222111 nebo 8001234567).',
      continueBtn: 'Pokračovat',
    },
    nonAuthorized: {
      title: 'Nedostatečná oprávnění!',
      description: 'Nemáte dostatečné opravnění pro aplikaci FUNNEL.',
    },
    configWifi: {
      topLink: 'Výběr nastavení',
      title: 'Nastavení bezdrátové Wi-Fi sítě',
      changeBtn: 'Změnit nastavení',
      confirm: 'Potvrdit',
      decline: 'Zrušit',
      password: 'Heslo',
      passwordValidation:
        'Pro zvolenou úroveň zabezpečení je heslo povinný údaj',
      security: 'Zabezpečení',
      wifiName: 'Název Wi-Fi sítě',
      wifiNameValidation: 'Název je povinný údaj',
      status: 'Stav',
      securityParams: {
        strongOne: 'síť bude zabezpečena heslem',
        strongTwo: 'nejsilnější forma zabezpečení modemu',
        weakOne: 'síť nebude zabezpečena heslem',
        weakTwo: 'vysoké riziko zneužití neoprávněnou osobou',
      },
    },
    lanPorts: {
      topLink: 'Výběr nastavení',
      title: 'Nastavení LAN portů',
      saveChanges: 'Uložit změny',
    },
    backup: {
      topLink: 'Výběr nastavení',
      title: 'Záloha nastavení',
      text:
        'Tlačítkem uložíte veškeré nastavení modemu. Můžete ho potom kdykoliv snadno obnovit.',
      backup: 'Zálohovat nastavení',
    },
    configurationError: {
      topLink: 'On-line řešení problémů',
      title: 'Ověření se nezdařilo',
      text:
        'Uvedenou linku se nám v systému nepodařilo nalézt. Zkuste prosím jiné číslo.',
      button: 'Zpět na výběr čísla',
    },
    noDevice: {
      topLink: 'On-line řešení problémů',
      title: 'Nemáte žádné pevné služby',
      text:
        'Pod tímto O2 účtem nemáte vedený žádný Internet nebo O2TV, jejichž DSL modem byste mohli nastavit.',
      login: 'Přihlásit se k jinému účtu',
    },
    firmwareUpdate: {
      topLink: 'Výběr nastavení',
      title: 'Aktualizace firmware',
      text:
        'Aktualizace firmware pomáhá zvýšit rychlost a bezpečnost vaší domácí sítě.',
      lineText:
        'Váš modem nemá aktuální verzi firmware. Doporučujeme provést aktualizaci.',
      button: 'Aktualizovat firmware',
    },
    cannotUpdateFirmware: {
      topLink: 'Výběr nastavení',
      title: 'Aktualizace firmware',
      text:
        'Aktualizace firmware pomáhá zvýšit rychlost a bezpečnost vaší domácí sítě.',
      lineText: 'Máte nainstalovanou nejnovější verzi.',
    },
    unavailable: {
      topLink: 'On-line řešení problémů',
      title: 'Budeme vás kontaktovat',
      infotext:
        'Vaši poruchu jsme předali k prověření našim specialistům, kteří Vás budou v případě potřeby kontaktovat.',
    },
    internalNoContext: {
      title: 'Chybějcí kontext uživatele',
      infotext:
        'Jako interní uživatel musíte mít nastavený zákaznický kontext. Lze nastavit ',
      linkText: 'zde',
    },
    reset: {
      topLink: 'Výběr nastavení',
      title: 'Restart modemu',
      text:
        'Nastavení modemu bude vráceno do stavu, ve kterém bylo při prvním nastavení. Všechno uživatelské nastavení bude smazáno.',
      messageInfo: 'Důrazně doporučujeme nejprve',
      messageLink: 'provést zálohu nastavení',
      resetBtn: 'Resetovat modem',
    },
    badpay: {
      topLink: 'On-line řešení problémů',
      title: 'Problém se zákaznickým účtem',
      text:
        'Ověřte své vyúčtování v Moje O2, nebo nás kontaktujte na on-line chatu.',
      button: 'Přejít na vyúčtování',
    },
    duplicity: {
      topLink: 'On-line řešení problémů',
      title: 'Problém již byl nahlášen',
      text:
        'V systému je již zaevidován poruchový lístek se stejným problémem na zvoleném čísle. Tento lístek bude uzavřen z důvodu duplicity.',
      button: 'Přejít na historii poruch',
    },
    restart: {
      topLink: 'Výběr nastavení',
      title: 'Restart modemu',
      text:
        'Vypne a znovu zapne modem jedním kliknutím. Restartem zařízení nepřijdete o žádné nastavení.',
      restartBtn: 'Restartovat modem',
    },
    recovery: {
      topLink: 'Výběr nastavení',
      title: 'Obnovení ze zálohy',
      text: 'Obnovte nastavení modemu do stavu ve kterém bylo uloženo.',
      messageInfo: 'Datum poslední zálohy',
      recoverBtn: 'Obnovit nastavení',
    },
    PUK: {
      topLink: 'On-line řešení problémů',
      title: 'Zjištění PUK',
      text: 'Pro zjištění PUK přejděte do OneCrm.',
      btn: 'Přesměrování do OneCrm',
    },
    block: {
      title: 'Formulář blokace',
      text1:
        'Po zablokování SIM karta přestane fungovat. Poté je třeba se dostavit na nejbližší značkovou O2 prodejnu a nechat si vystavit novou.',
      text2: 'Pro zablokování SIM karty přejděte do CC',
      buttonText: 'Přesměrováni do CC',
    },
    technicianVisitOffer: {
      title: 'Možný placený výjezd technika',
      text1:
        'Automatická diagnostika vyhodnotila možnou závadu na lince. Pro odstranění této závady je potřeba naplánovat výjezd technika na opravu. Technik prověří celou linku od ústředny až k vám domů. S největší pravděpodobností se jedná o závadu na lince. Existuje ale jistá pravděpodobnost, že závada je ve vašem modemu, případně v jeho špatném zapojení. Pokud naplánujete výjezd technika, a technik zjistí závadu na lince, bezplatně vám linku opraví.',
      text2:
        'V případě, že by technik shledal závadu na vašem zařízení (modemu, rozbočovači, počítači, atd.), případně v nesprávném zapojení těchto vašich zařízení, je oprávněn tento výjezd zpoplatnit částkou 660 Kč.',
      button1Text: 'Ano, souhlasím',
      button2Text: 'Ne, nesouhlasím',
      button3Text: 'Zpět na návod',
    },
    serviceUnavailable: {
      title: 'Nemáte službu O2TV Multi',
      text:
        'Pro sledování služby O2TV současně na dvou set-top-boxech je nutné mít aktivní službu O2TV Multi.',
      buttonText: 'Přejít do Moje O2TV',
    },
    information: {
      topLink: 'On-line řešení problémů',
      title: 'Kontaktujte nás',
      text:
        'Nepodařilo se nám dát dohromady dostatek informací o poruše. Kontaktujte nás prosím na chatu, kde se s vámi na problém podíváme podrobněji.',
    },
    invalidNumber: {
      topLink: 'On-line řešení problémů',
      title: 'Neznámé číslo',
      text:
        'Telefonní číslo jsme nemohli ověřit. Ujistěte se, že jste ho zadali správně a jedná se o číslo v síti O2.',
      buttonText: 'Zpět na zadání problému',
    },
    solutionWithoutInteraction: {
      title: 'Problém na modemu',
      text:
        'Zjistili jsme, že vaše služba nefunguje správně. Následující postup opravy poruchy vyžaduje vaší fyzickou přítomnost u zařízení a jeho zapojení.',
      agreeBtn: 'Jdu na to',
      laterBtn: 'Budu to řešit později',
      isFixed: 'Porucha byla vyřešena. Funguje vám to?',
      postponeBtn: 'Uložit na později',
      modal: {
        text:
          'Zbýva pouze 15 minut k automatickému uzavření vašeho požadavku. Potřebujete na vyzkoušení návodu více času?',
        moreTimeBtn: 'Potřebuji více času',
        okBtn: 'Nepotřebuji',
      },
    },
    solution: {
      title: 'Problém na modemu',
      text:
        'Zjistili jsme, že vaše služba nefunguje správně. Následující postup opravy poruchy vyžaduje vaší fyzickou přítomnost u zařízení a jeho zapojení.',
      agreeBtn: 'Jdu na to',
      laterBtn: 'Budu to řešit později',
      isFixed: 'Porucha byla vyřešena. Funguje vám to?',
      fixedBtn: 'Problém vyřešen',
      notFixedBtn: 'Problém přetrvává',
      postponeBtn: 'Uložit na později',
      modal: {
        text:
          'Zbýva pouze 15 minut k automatickému uzavření vašeho požadavku. Potřebujete na vyzkoušení návodu více času?',
        moreTimeBtn: 'Potřebuji více času',
        okBtn: 'Nepotřebuji',
      },
    },
    brokenDevice: {
      topLink: 'On-line řešení problémů',
      title: 'Modem je rozbitý',
      description:
        'Vaše zařízení je s největší pravděpodobností vadné, a proto je potřeba si pořídit nové. V případě, že máte stávající zařízení stále v záruce, je možné na něj uplatnit reklamaci v jedné ze značkových O2 prodejen. V opačném případě je nutné pořídit zařízení nové. Vyberte z následujících možností, která je pro Vás nejpřijatelnější.',
      freeBtn: 'Reklamovat set-top-box v O2 Prodejně',
      payedBtn: 'Objednat nový set-top-box',
    },
    selectSolution: {
      topLink: 'On-line řešení problémů',
      title: 'Modem je rozbitý',
      description:
        'Vaše zařízení je s největší pravděpodobností vadné, a proto je potřeba si pořídit nové. V případě, že máte stávající zařízení stále v záruce, je možné na něj uplatnit reklamaci v jedné ze značkových O2 prodejen. V opačném případě je nutné pořídit zařízení nové. Vyberte z následujících možností, která je pro Vás nejpřijatelnější.',
      freeBtn: 'Reklamovat set-top-box v O2 Prodejně',
      payedBtn: 'Objednat nový set-top-box',
    },
    technicianVisitRequired: {
      topLink: 'On-line řešení problémů',
      title: 'Oprava vyžaduje návštěvu technika',
      termBtn: 'Sjednat termín',
      text:
        ' K vyřešení problému je nutné, aby vás navštívil náš technik na adrese',
    },
    hotkeyModal: {
      closeBtn: 'Zavřít',
      showTemplate: 'Zobrazení šablony: Ctrl + Alt + h',
    },
    setTerm: {
      title: 'Datum návštěvy technika',
      topLink: 'Historie hlášených poruch',
      selectDateLabel: 'Vyberte preferované datum',
      dateFormatNote: 'Zadávejte ve formátu dd.mm.rrrr',
      btnLabel: 'Vyhledat možné termíny',
      morning: 'Dopoledne',
      afternoon: 'Odpoledne',
    },
    submitTerm: {
      title: 'Datum návštěvy technika',
      text: 'Technik k vám přijede ',
      anotherDateBtn: 'Vybrat jiný termín',
      confirmBtn: 'Závazně objednat',
      topLink: 'Zpět',
    },
    termNotFound: {
      title: 'Termín nenalezen',
      topLink: 'Historie hlášených poruch',
      text: 'Nepodařilo se najít vhodný termín.',
      button: 'Zpět na výběr termínu',
    },
    selectTerm: {
      title: 'Datum návštěvy technika',
      topLink: 'Historie hlášených poruch',
      headline: 'Vyberte vhodný termín',
      selectBtn: 'Zvolit termín',
      changeDateBtn: ' Změnit preferované datum',
      message: 'Poznámka',
    },
    outagesSelectNumber: {
      title: 'Internet? O2TV? Pevna linka?',
      topLink: 'On-line řešení problémů',
      headline: 'Zadejte číslo služby',
      readyBtn: 'Hotovo',
      phoneNumErr:
        'Číslo služby se nám nepodařilo dohledat. Zkontrolujte, prosím, jestli jste číslo zadali ve správném tvaru (např. 777222111 nebo 800 1234567).',
    },
    outagesNotFound: {
      title: 'Číslo neexistuje',
      topLink: 'On-line řešení problémů',
      text:
        'Vámi zadané telefonní číslo neexistuje nebo není číslem pevné služby O2.',
      button: 'Zpět na vyber čísla',
    },
    outage: {
      topLink: 'On-line řešení problémů',
      noOutageTitle: 'Neregistrujeme žádný výpadek',
      noOutageText: 'Na vašem čísle aktuálně neregistrujeme žádný výpadek.',
      outageTitle: 'Zaznamenali jsme výpadek',
      outageText:
        'Údržba sítě trvá déle, než jsme čekali. Budeme Vás informovat pomocí SMS, až to bude hotové. Děkujeme za pochopení.',
      title: 'Zaznamenali jsme výpadek',
      text: 'O ukončení výpadku vás budeme informovat pomocí SMS.',
      buttonText: 'Návrat na On-line řešení problémů',
    },
    outagesMap: {
      title: 'Mapa výpadku',
      topLink: 'On-line řešení problémů',
      mapInfo:
        'Vyhledejte nebo označte na mapě místo, v kterém chcete zjistit výpadek mobilního signálu.',
      city: 'Mesto',
      cityPart: 'Mestka cast',
      street: 'Ulice',
      outage: {
        quote1: 'V zadané oblasti evidujeme výpadek.',
      },
    },
    noOutage: {
      title: 'Neregistrujeme žádný výpadek',
      text: 'Na vašem čísle aktuálně neregistrujeme žádný výpadek.',
      buttonText: 'Návrat na On-line řešení problémů',
    },
    plannedOutage: {
      title: 'Plánovaná údržba',
      topLink: 'On-line řešení problémů',
      text1: 'Dáváme do pořádku naši síť.',
      text2: 'by to mělo být vše zase v provozu. Děkujeme za pochopení.',
      at: 've',
      buttonText: 'Návrat na On-line řešení problémů',
    },
    confirmation: {
      topLinkDefault: 'BTN default',
      topLinkWebView: 'BTN webview',
      descriptionDefault: 'O ukončení výpadku vás budeme informovat pomocí SMS.',
      descriptionWebView: '<h3>Restartujte telefon</h3><p>Vypněte a znovu zapněte svůj telefon. Tato jednoduchá operace často pomůže.</p><h3>Vyzkoušejte SIM kartu v jiném telefonu</h3><p>Chyba může být ve vašem přístroji. Pro ověření dejte SIM kartu do jiného telefonu a zkuste, jestli služba funguje. Pokud ano, máte jistotu, že jde o chybu telefonu.</p><h3>Vyzkoušejte službu v jiné lokalitě</h3><p>Dochází ke stejnému problému i na jiné (dostatečně vzdálené) lokalitě? Pokud ne, potíže způsobuje špatný signál.</p>',
    },
    finalPage: {
      title: 'Něco se nepovedlo',
    },
    problemHere: {
      title: 'Problém na naší straně',
      topLink: 'On-line řešení problémů',
      text:
        'Objevili jsme poruchu na naší straně. Jakmile chybu odstraníme, pošleme vám SMS.',
      text_two: 'Očekáváme, že to bude',
    },
    signalsProblem: {
      title: 'Mám potíže se signálem',
      topLink: 'Řešení nejčastějších problémů',
      headline: 'Restartujte telefon',
      questionOne: {
        question: 'Restartujte telefon',
        answer:
          'Vypněte a znovu zapněte svůj telefon. Tato jednoduchá operace často pomůže.',
      },
      questionTwo: {
        question: 'Vyzkoušejte SIM kartu v jiném telefonu',
        answer:
          'Chyba může být ve vašem přístroji. Pro ověření dejte SIM kartu do jiného telefonu a zkuste, jestli služba funguje. Pokud ano, máte jistotu, že jde o chybu telefonu.',
      },
      questionThree: {
        question: 'Vyzkoušejte službu v jiné lokalitě',
        answer:
          'Dochází ke stejnému problému i na jiné (dostatečně vzdálené) lokalitě? Pokud ne, potíže způsobuje špatný signál.',
      },
    },
    saved: {
      topLink: 'On-line řešení problémů',
      title: 'Prodloužili jsme čas na vyřešení problému',
      text:
        'Na vyzkoušení našeho postupu máte nyní 24 hodin. Po této době dojde k automatickému uzavření požadavku. K postupu řešení poruchy se můžete zpětně vrátit z historie hlášených poruch.',
      button: 'Zobrazit řešení poruchy',
    },
    modemAutoSetup: {
      topLink: 'Nastavení DSL modemu',
      title: 'Nastavení DSL modemu',
      text: 'Pokud jste nastavili vše potřebné, klikněte na Ukončit nastavení.',
      button: 'Ukončit nastavení',
    },
    beforeChat: {
      topLink: '',
      title: 'Bohužel vám teď nemůžeme pomoci',
      text:
        ' Identifikovali jsme, že z Vašeho počítače je odesíláno nadměrné množství spammů. Proto bylo odesílání pošty na Vaší lince dočasně pozastaveno. Může se jednat o záměrné rozesílání spammů, nebo nevědomé rozesílání, způsobené virem ve Vašem počítači. V takovém případě doporučujeme provést kontrolu počítače a jeho odvirování.<br /> Bližší informace k blokaci odesílání pošty a jejímu případnému zrušení Vám poskytnou naši specialisté na on-line chatu.',
      button: 'Zahájit on-line chat',
    },
    kahl: {
      default: {
        success: 'Úspešně provedeno',
        danger: 'Obecná chyba',
      },
    },
    error: {
      topLink: 'On-line řešení problémů',
      title: 'Něco se pokazilo',
      description:
        'Omlouvame se, ale vypada to, ze nektery ze systemu nefunguje spravne. Zkuste akci opakovat pozdeji.',
    },
    transparentWaiting: {
      title: 'Váš požadavek zpracováváme prosíme o strpení',
      hint:
        'Neobnovujte prosím stránku jinak bude potřeba zadat poruchu znovu děkujeme.',
    },
    layout: {
      header: {
        userTypesRow: {
          personal: 'Osobní',
          business: 'Podnikatelé',
          company: 'Firmy a veřejná správa',
        },
        mainLinks: {
          calling: 'Volání',
          internet: 'Internet',
          o2TV: 'O2 TV',
          phonesAndDevices: 'Telefony a zařízení',
          financies: 'Finance',
          support: 'Podpora',
          more: 'Více',
          logIn: 'Přihlásit se',
        },
        btn: {
          myO2: 'Moje O2',
        },
      },
      footer: {
        careAndSupport: 'Péče a podpora',
        privacy: 'Soukromí',
        about: 'O nás',
        contacts: 'Kontakty',
        newsInMyO2: 'Novinky v Moje O2',
        downloadMyO2: 'Stáhnout aplikaci Moje O2',
        cookie: 'Cookies',
        language: {
          cs: 'Čeština',
          en: 'English',
        },
      },
    },
  },
};
