import React from 'react';
import { O2Indent, O2Button } from 'o2-theme-react';

import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';

const DeviceConfigRequired: React.FC = () => {
  const namePage = 'deviceConfigRequired';
  useAnalytics(namePage);
  const linkConfiguration = useSelector<any, any>(
    state => state.config.o2Config['link.configuration']
  );

  const redirect = () => {
    window.location.href = window.location.origin + linkConfiguration;
  };

  return (
    <SimpleTextPage
      title={<Langtext text='funnel.deviceConfigRequired.title' />}
      hasBacklink={false}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <Langtext text='funnel.deviceConfigRequired.description' />
      </O2Indent>
      <O2Indent>
        <O2Button
          onClick={() => {
            redirect();
          }}
          indentRight
          color='primary'
        >
          <Langtext text='funnel.deviceConfigRequired.settingModem' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default withRouter(DeviceConfigRequired);
