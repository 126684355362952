import ImageCard from '../../../src/components/common/ImageCard';
import Langtext from '../../../src/components/common/Langtext';
import PageTitle from '../../../src/components/common/PageTitle';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import get from 'lodash/get';
import {
  O2Button,
  O2Indent,
  O2Linkbox,
  O2Loader,
  O2Section,
  O2Wrap,
  O2WrapItem,
} from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';
import { logoutAndRedirect } from '../../../src/redux/actions/userActions';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';
import useWaitingPageModificator from '../../../src/hooks/useWaitingPageModificator';

// import LazyLoadHook from '../../../src/components/common/LazyLoadHook';

const SelectDevice: any = (props: any) => {
  const namePage = 'selectDevice';
  const { formSubmit } = useAnalytics(namePage);
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  useHandleInappropriateRefresh();
  const lazyload = useSelector<any, any>(
    state => state.pages.pages[namePage].lazyLoad
  );
  const stateCards = useSelector<any, any>(state =>
    get(state, `pages.pages[${namePage}].services`, null)
  );
  const lazyLoadBatch = useSelector<any, any>(
    state => state.pages.lazyLoadBatch
  );

  const logoutConfiguration = useSelector<any, any>(
    state => state.config.o2Config['link.configuration']
  );

  const transparentWaiting = useWaitingPageModificator();

  const redirect = () => {
    logoutAndRedirect(logoutConfiguration);
  };
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  // TODO: FIX UNUSED loadMore with turn on lazyload
  // eslint-disable-next-line
  const [loadMore, setLoadMore] = useState(true);
  // const [isFetching, setIsFetching] = LazyLoadHook(fetchMoreItems, loadMore);
  useEffect(() => {
    if (stateCards) {
      setCards(stateCards);
      setLoading(false);
    }
  }, [stateCards]);

  useEffect(() => {
    setLoadMore(true);
    if (!lazyload) {
      setLoadMore(false);
    }
    // eslint-disable-next-line
  }, [page.lazyLoad, lazyLoadBatch]);

  function fetchMoreItems() {
    setTimeout(() => {
      dispatch(
        ttsProcess({
          page: namePage,
          params: {
            lazyloadBatch: lazyLoadBatch + 1,
          },
        })
      );
      setLoadMore(false);
      // setIsFetching(false);
    }, 3000);
  }

  useEffect(() => {
    const params = props.match.params;
    if (params.deeplink === 'configuredevice' && params.serviceId) {
      dispatch(
        ttsProcess(
          {
            page: 'main',
            params: {
              action: 'deviceConfiguration',
              serviceId: params.serviceId,
            },
          },
          props
        )
      );
    }
    if (
      props.deeplink === 'configuredevice' &&
      params.serviceId === undefined
    ) {
      dispatch(
        ttsProcess({
          page: 'main',
          params: {
            action: 'deviceConfiguration',
          },
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  const proceed = (data: any) => {
    formSubmit();

    setTimeout(() => {
      dispatch(
        ttsProcess(
          {
            page: namePage,
            params: {
              serviceId: data.id,
            },
            ttsParams: {
              flowType: 'TT_DEVICE_CONF',
            },
          },
          props
        )
      );
    }, 500);
  };

  return (
    <>
      <PageTitle>
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.selectDevice.title' />
      </PageTitle>
      <O2Section>
        <O2Indent>
          <Langtext text='funnel.selectDevice.text' />
        </O2Indent>
        {!loading && (
          <>
            <O2Indent>
              <O2Wrap columns={3}>
                {cards.map(
                  (service: any) =>
                    service.status !== 'DEACTIVATED' && (
                      <O2WrapItem key={shortid.generate()}>
                        <ImageCard
                          title={service.tariffName}
                          subTitle={service.cpName}
                          id={service.id}
                          text={service.deviceId}
                          description={service.address}
                          image={service.imageLink}
                          //  link={service.state !== 'ACTIVE' ? service.trfLink : null}
                          onClick={() => proceed(service)}
                        />
                      </O2WrapItem>
                    )
                )}
              </O2Wrap>
            </O2Indent>
            {lazyload && (
              <O2Indent>
                <O2Button onClick={() => fetchMoreItems()}>Load more</O2Button>
              </O2Indent>
            )}
          </>
        )}
        {loading && !loading && (
          <O2Indent standalone>
            <O2Wrap center>
              <O2Loader />
            </O2Wrap>
          </O2Indent>
        )}
      </O2Section>
      <O2Section>
        <O2Indent>
          <Langtext text='funnel.selectDevice.anotherUserInfo' />
        </O2Indent>
        <O2Indent>
          <O2Wrap columns={2} mobileCollapsed={true}>
            <O2WrapItem>
              <O2Linkbox
                onClick={() => {
                  redirect();
                }}
              >
                <Langtext text='funnel.selectDevice.switchUsersBtn' />
              </O2Linkbox>
            </O2WrapItem>
          </O2Wrap>
        </O2Indent>
      </O2Section>
      {transparentWaiting}
    </>
  );
};

export default SelectDevice;
