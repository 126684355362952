export type Action = {
  type: 'SET_HOLDERS_FOR_WAITING';
  lastPage: string;
  serviceId: string;
};

export const setWaitingHolders = (
  serviceId: string,
  lastPage: string
): Action => {
  return {
    type: 'SET_HOLDERS_FOR_WAITING',
    lastPage,
    serviceId,
  };
};
