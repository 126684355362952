// @ts-nocheck
import React, { useState } from 'react';
import {
  O2Popover,
  O2PopoverBody,
  // O2Linkbox,
  // O2PopoverFooter,
  // O2Icon,
  O2Header,
  O2Pane,
  O2Indent,
} from 'o2-theme-react';
import { useSelector } from 'react-redux';

const Header = () => {
  const apfThemeLocation = (window as any).g_apfThemeLocation;
  const logo = `${apfThemeLocation}/images/header/logo-header-moje-o2.png`;
  const avatarResident = `${apfThemeLocation}/images/header/customer-avatar-resident.png`;
  // const avatarBusiness = `${apfThemeLocation}/images/header/customer-avatar-business.png`;

  const o2Config = useSelector<any, any>(state => state.config.o2Config);
  const user = useSelector<any, any>(state => state.user);
  const sharedObject = useSelector<any, any>(state => state.sharedObject);

  const [isUserOpen, setUserState] = useState(false);
  // const [open, setOpen] = useState(false);

  const handleUserClick = (e: any) => {
    e.preventDefault();
    setUserState(!isUserOpen);
  };

  const User = () => {
    return (
      <O2Popover
        className='o2-popover--top o2-popover__user'
        size='complex'
        target='userElm'
        hideArrow={true}
        isOpen={isUserOpen}
      >
        <O2PopoverBody>
          <O2Pane noBorder>
            <O2Indent>
              <img
                src={avatarResident}
                className='o2-popover__user-avatar'
                alt={
                  user.userInfo.internal && sharedObject
                    ? sharedObject?.sharedObjectSession?.loginName
                    : user.userInfo.username
                }
              />
            </O2Indent>
            <O2Indent>
              <h3 className='o2-popover__user-name'>
                O2ID:{' '}
                {user.userInfo.internal && sharedObject
                  ? sharedObject?.sharedObjectSession?.loginName
                  : user.userInfo.username}
              </h3>
              {/* <div className="o2-popover__user-info">frantisek.mzourek@seznam.cz</div> */}
            </O2Indent>
            <O2Indent standalone>
              <div className='o2-popover__user-group'>
                <a
                  href={o2Config['link.dashboard']}
                  className='o2-popover__user-action'
                >
                  <div className='o2-icon o2-icon--home o2-popover__user-action-icon'></div>
                  <div className='o2-popover__user-action-text'>
                    Moje služby
                  </div>
                </a>
                {/*
                  <a
                    href={o2Config['link.manageContactPerson']}
                    className='o2-popover__user-action'
                  >
                    <div className='o2-icon o2-icon--settings o2-popover__user-action-icon'></div>
                    <div className='o2-popover__user-action-text'>Nastavení</div>
                  </a>
                */}

                <a
                  href={o2Config['link.logout']}
                  className='o2-popover__user-action'
                >
                  <div className='o2-icon o2-icon--exit o2-popover__user-action-icon'></div>
                  <div className='o2-popover__user-action-text'>Odhlásit</div>
                </a>
              </div>
            </O2Indent>
          </O2Pane>
        </O2PopoverBody>
      </O2Popover>
    );
  };

  return (
    <>
      <O2Header>
        <O2Header.Controls>
          <O2Header.Link
            href={o2Config['link.wcms']}
            icon
            className='h-show-md-up-inline'
          >
            O2.cz
          </O2Header.Link>
          <O2Header.Link
            user
            avatar={avatarResident}
            onClick={handleUserClick}
            id='userElm'
          >
            O2ID:{' '}
            {user.userInfo.internal && sharedObject
              ? sharedObject?.sharedObjectSession?.loginName
              : user.userInfo.username}
          </O2Header.Link>
          {/*
        <O2Header.Link icon onClick={() => setOpen(true)} className="h-hide-md-up-inline">
          <O2Icon name="hamburger" />
        </O2Header.Link>
        */}
        </O2Header.Controls>

        <O2Header.Links>
          <O2Header.Logo
            src={logo}
            href={o2Config['link.selfcare']}
          ></O2Header.Logo>
        </O2Header.Links>
      </O2Header>
      <User />
    </>
  );
};

export default Header;
