const initialState: any = {
  isLoggedIn: false,
  loading: true,
  langtextDisplay: false,
  userContext: null,
  userService: null,
};

export default (state: any = initialState, action: any): any => {
  switch (action.type) {
    case 'INIT_USER':
      return {
        ...state,
        isLoggedIn: action.user.isLoggedIn,
        loading: action.user.loading,
        userInfo: action.user.userInfo,
      };
    case 'GET_USER_INFO':
      return {
        ...state,
        isLoggedIn: action.user.isLoggedIn,
        loading: action.user.loading,
        userInfo: action.user.userInfo,
        hasRole_OP_FUNNEL_CC: action.user.hasRole_OP_FUNNEL_CC,
      };
    case 'SET_LANGTEXTS_DISPLAY_MODE':
      return {
        ...state,
        langtextDisplay: action.langtextDisplay,
      };
    case 'GET_USER_CONTEXT_SUCCESS':
      return {
        ...state,
        userContext: action.payload,
        loading: action.loading,
      };
    case 'GET_USER_SERVICE_SUCCESS':
      return {
        ...state,
        userService: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
