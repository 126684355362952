import React from 'react';
import {
  O2Linkbox,
  O2Icon,
  O2Addon,
  O2AddonPane,
  O2AddonContent,
} from 'o2-theme-react';
import Langtext from './Langtext';

const ModemLinkbox = (props: {
  href?: string;
  iconName: string;
  title: string;
  description: string;
  onClick?: any;
  withTranslations?: boolean;
  testId?: string;
}) => {
  return (
    <O2Linkbox
      onClick={() => props.onClick(props.href)}
      data-test-id={props.testId}
    >
      <O2Addon middle reversed>
        <O2AddonPane indent middle>
          <O2Icon name={props.iconName} size='xl' />
        </O2AddonPane>
        <O2AddonContent>
          <div>
            <Langtext text={props.title} />
          </div>
          <div className='o2-typo__weight--minor'>
            <Langtext text={props.description} />
          </div>
        </O2AddonContent>
      </O2Addon>
    </O2Linkbox>
  );
};

export default ModemLinkbox;
