import { O2Indent, O2Button } from 'o2-theme-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import HTMLBody from '../../../src/components/common/HTMLBody';
import SimpleTextPage from '../common/SimpleTextPage';
import Langtext from '../common/Langtext';
import useAnalytics from '../../hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../hooks/useHandleInappropriateRefresh';

const InformationSelfcare: React.FC = () => {
  const namePage = 'informationSelfcare';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const o2Config = useSelector<any, any>(state => state.config.o2Config);
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const [destinationId] = useState(get(page, 'ttsParams.destinationId', null));

  const redirect = () => {
    const getRedirectLink = () => {
      if (localStorage.getItem('webview') === 'true') {
        return o2Config['link.finalPageAfterTTSWebview'];
      } else {
        return o2Config['link.finalPageAfterTTS'];
      }
    }
    
    window.location.href = getRedirectLink();
  };

  const backLinkTextResolver = () => {
    if (localStorage.getItem('webview') === 'true') {
      return <Langtext text='funnel.confirmation.topLinkWebView' />
    } else {
      return <Langtext text='funnel.confirmation.topLinkDefault' />
    }
  }

  return (
    <SimpleTextPage
      title={
        destinationId ? (
          <Langtext
            text={`funnel.codelist.destinationToTitle.${destinationId}`}
          />
        ) : (
          <Langtext text='funnel.informationSelfcare.title' />
        )
      }
      hasBacklink={false}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        {destinationId && <HTMLBody id={destinationId} />}
          {!destinationId && (
            <Langtext text='funnel.informationSelfcare.description' />
          )}
      </O2Indent>
      <O2Indent>
        <O2Button color='primary' onClick={() => redirect()}>
          {backLinkTextResolver()}
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default InformationSelfcare;
