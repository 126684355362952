import {
  O2Box,
  O2BoxContainer,
  O2BoxContent,
  O2Indent,
  O2InlineState,
} from 'o2-theme-react';
import React from 'react';
import Langtext from './Langtext';

const ImageCardContainer = (props: any) => {

  return (
    <div
      onClick={() => props.onClick(props.data)}
      data-test-id={props.data.testId}
      style={{ height: '100%' }}
    >
      {(props.data.disabled || props.disable) && (
        <O2Box
          link
          href={props.data.link}
          inactive
          stacked={true}
          className='o2-typo--align-center'
          style={{ height: '100%' }}
        >
          {props.children}
        </O2Box>
      )}
      {!props.data.disabled && !props.disable && (
        <O2Box
          link
          href={props.data.link}
          stacked={true}
          className='o2-typo--align-center'
          style={{ height: '100%' }}
        >
          {props.children}
        </O2Box>
      )}
    </div>
  );
};

const ImageCard = (props: any) => {
  const { onClick, withTranslations } = props;
  return (
    <ImageCardContainer data={props} onClick={onClick} disable={props.disabled ? true : false}>
      <O2BoxContainer>
        <O2BoxContent>
          <O2Indent>
            <img
              src={props.image}
              alt={props.title}
              className='o2-imagesize__small'
            />
          </O2Indent>
          <O2Indent color='transparent'>
            <h2 className='o2-typo--heading'>
              {withTranslations ? <Langtext text={props.title} /> : props.title}
            </h2>
          </O2Indent>
          {props.subTitle && (
            <O2Indent color='transparent'>
              {withTranslations ? (
                <Langtext text={props.subTitle} />
              ) : (
                props.subTitle
              )}
            </O2Indent>
          )}
          {props.text && (
            <O2Indent color='transparent' data-test-id='serviceButtons'>
              {withTranslations ? <Langtext text={props.text} /> : props.text}
            </O2Indent>
          )}
          {props.description && (
            <O2Indent>
              {withTranslations ? (
                <Langtext text={props.description} />
              ) : (
                props.description
              )}
            </O2Indent>
          )}
          {props.status && (
            <O2Indent color='transparent'>
              {props.isService && props.status === 'SUSPENDED' && (
                <O2InlineState state='warn'> Pozastaveno</O2InlineState>
              )}
              {!props.isService && (
                <O2InlineState
                  state={
                    props.status ? props.status.toLowerCase() : props.status
                  }
                >
                  {withTranslations ? (
                    <Langtext text={props.status} />
                  ) : (
                    props.status
                  )}
                </O2InlineState>
              )}
            </O2Indent>
          )}
        </O2BoxContent>
      </O2BoxContainer>
    </ImageCardContainer>
  );
};

export default ImageCard;
