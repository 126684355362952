const getURIParams = (replace: string) => {
  let result: any = {};
  const pathname = window.location.pathname.trim();
  const isLastCharSlash = pathname.charAt(pathname.length - 1) === '/' ? true : false;
  const toArray = pathname.replace(replace, '').split('/');
  if (isLastCharSlash) toArray.pop();
  let chunkedArray = [];
  while (toArray.length > 0) {
    chunkedArray.push(toArray.splice(0, 2));
  }
  chunkedArray.forEach(el => {
    result[el[0]] = el[1];
  });
  return result;
}

export default getURIParams;