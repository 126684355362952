import Langtext from '../../../src/components/common/Langtext';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import { O2Button, O2Indent } from 'o2-theme-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';

const InvalidNumber: React.FC = (props: any) => {
  const namePage = 'invalidNumber';
  useAnalytics(namePage);
  const dispatch = useDispatch();

  const reportTroubleAgain = () => {
    dispatch(
      ttsProcess(
        {
          page: 'main',
          params: {
            action: 'createTrouble',
          },
        },
        props
      )
    );
  };

  return (
    <SimpleTextPage
      title={<Langtext text='funnel.invalidNumber.title' />}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <Langtext text={`funnel.invalidNumber.text`} />
      </O2Indent>
      <O2Indent>
        <O2Button color='primary' onClick={reportTroubleAgain}>
          <Langtext text='funnel.invalidNumber.buttonText' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default InvalidNumber;
