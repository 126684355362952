import { Config } from './types';

const initialState: Config = {
  lang: 'cs',
  isJWTChecked: false,
};

export default (state: Config = initialState, action: any): any => {
  switch (action.type) {
    case 'INIT_APP':
      return {
        ...state,
        lang: action.config.lang,
        captchaKey: action.config.captchaKey,
        o2Config: action.config.o2Config,
      };
    case 'TOGGLE_LANG':
      return {
        ...state,
        lang: action.config.lang,
      };
    case 'CHECK_JWT':
      return {
        ...state,
        isJWTChecked: true,
      };
    default:
      return state;
  }
};
