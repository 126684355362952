import React from 'react';
import { O2Indent, O2Button } from 'o2-theme-react';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import { useSelector } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const Badpay: React.FC = (props: any) => {
  const namePage = 'badPay';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const eBill = useSelector<any, any>(
    state => state.config.o2Config['link.ebill']
  );

  const redirect = () => {
    window.location.href = eBill;
  };

  return (
    <SimpleTextPage
      hasBacklink={false}
      title={<Langtext text='funnel.badpay.title' />}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <Langtext text='funnel.badpay.text' />
      </O2Indent>
      <O2Indent>
        <O2Button color='primary' onClick={() => redirect()}>
          <Langtext text='funnel.badpay.button' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default Badpay;
