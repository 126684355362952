import { Action } from '../actions/ttsActions';

const initialState: any = {
  ttId: undefined,
  serviceCpAssetRefNo: undefined,
};

export default (state: any = initialState, action: Action): any => {
  switch (action.type) {
    case 'TTS_PROCESS_ANALYTICS':
      return {
        ...state,
        ttId: action.payload.ttId,
        serviceCpAssetRefNo: action.payload.serviceCpAssetRefNo,
        address: action.payload.address,
      };
    default:
      return state;
  }
};
