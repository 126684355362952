import React from 'react';
import { O2Section, O2Indent, O2Icon } from 'o2-theme-react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearTTSParams } from '../../../src/redux/actions/ttsActions';

const PageTitle = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let backlinkRoute = '';
  if (props.to === '/funnel' || props.to === '/funnel/' || props.to === '/') {
    backlinkRoute = '/funnel/';
  } else {
    backlinkRoute = props.to;
  }

  const clearTTSParamsAndRedirect = (pathToRedirect: any) => {
    dispatch(clearTTSParams());
    history.push(pathToRedirect);
  };

  return (
    <O2Section>
      {props.to && props.isExternal && (
        <O2Indent>
          <a
            href={props.to}
            className='o2-typo__nowrap'
            data-test-id='backLink'
          >
            <O2Icon name='chevron-left' size='sm' indentRight />
            {props.backlink || 'On-line řešení problémů'}
          </a>
        </O2Indent>
      )}
      {props.to && (!props.isExternal || props.isExternal === undefined) && (
        <O2Indent>
          <a
            href='/funnel'
            data-test-id='backLink'
            onClick={e => {
              e.preventDefault();
              clearTTSParamsAndRedirect(backlinkRoute);
            }}
            className='o2-typo__nowrap'
          >
            <O2Icon name='chevron-left' size='sm' indentRight />
            {props.backlink || 'On-line řešení problémů'}
          </a>
        </O2Indent>
      )}
      <O2Indent>
        <h1 className='o2web-typo__heading'>{props.children}</h1>
      </O2Indent>
      {props.description && <O2Indent>{props.description}</O2Indent>}
    </O2Section>
  );
};

export default PageTitle;
