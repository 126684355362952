import Langtext from '../../../src/components/common/Langtext';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import { O2Button, O2Icon, O2Indent, O2InfoMessage } from 'o2-theme-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const PlannedOutage: React.FC = () => {
  const namePage = 'plannedOutage';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const term = get(page, 'params.term', null);

  const formatDate = (date: any) => {
    let dateObj = new Date(date);
    let month = String(dateObj.getMonth() + 1);
    let day = String(dateObj.getDate());
    let hour = String(dateObj.getHours());
    let currentMinutes: any = dateObj.getMinutes();
    currentMinutes = ('0' + currentMinutes).slice(-2);
    let minute = String(currentMinutes);

    if (Number(day) < 10) {
      day = '0' + day;
    }
    if (Number(month) < 10) {
      month = '0' + month;
    }

    return (
      <>
        <span className='o2-typo--weight-important'>{` ${day}.${month} `}</span>
        <Langtext text='funnel.plannedOutage.at' />
        <span className='o2-typo--weight-important'>{` ${hour}:${minute} `}</span>
      </>
    );
  };

  return (
    <SimpleTextPage
      title={<Langtext text='funnel.plannedOutage.title' />}
      hasBacklink={false}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <O2InfoMessage color='neutral'>
          <Langtext text='funnel.plannedOutage.text1' />
          {term ? formatDate(term) : ''}
          <Langtext text='funnel.plannedOutage.text2' />
        </O2InfoMessage>
      </O2Indent>
      <O2Indent>
        <Link to='/funnel?jwtChecked=true'>
          <O2Button color='transparent' firstLink>
            <O2Icon name='chevron-left' size='sm' indentRight />
            <Langtext text='funnel.plannedOutage.buttonText' />
          </O2Button>
        </Link>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default PlannedOutage;
