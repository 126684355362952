import { getState } from '../../../src/redux/store/store';
import get from 'lodash/get';
import { O2Indent } from 'o2-theme-react';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

const Map = forwardRef((props: any, ref) => {
  // url configs
  const mapUrlLocalhost = 'http://funnel-ui-loc.apps.dev01-osh.cz.o2/position';
  const localhostUrl =
    'http://funnel-ui-loc.apps.dev01-osh.cz.o2/funnel/select-trouble';

  // default configs & states
  const [mapLoaded, setMapLoaded] = useState(false);
  const { coordX, coordY } = props;
  const zoom = 0;
  const defaultPosition = '49.7666302;15.2965231';

  // get init position
  const getPosition = () => {
    return coordX && coordY ? `${coordX};${coordY}` : defaultPosition;
  };

  // set position to map
  const setPosition = () => {
    const iframe: HTMLIFrameElement | any = document.querySelector('#map');
    const MAP_API = iframe.contentWindow.ComMapAPI;

    if (coordX && coordY) {
      MAP_API.LocatePos(getPosition(), zoom);
      MAP_API.SetUserPointWGS84(coordX, coordY, true);
    }
  };

  // create map on didMount only
  useEffect(() => {
    createMap(props);
    // eslint-disable-next-line
  }, []);

  // push upward when map loaded
  useEffect(() => {
    if (props.mapLoaded) {
      props.mapLoaded(mapLoaded);
    }
    // eslint-disable-next-line
  }, [mapLoaded]);

  // set position when coords change
  useEffect(() => {
    setPosition();
    // eslint-disable-next-line
  }, [props.changedCoords, props.coordX, props.coordY]);

  useImperativeHandle(ref, () => ({
    setupLocation(coordinates: string) {
      const iframe: HTMLIFrameElement | any = document.querySelector('#map');
      if (iframe.contentWindow && iframe.contentWindow.ComMapAPI) {
        iframe.contentWindow.ComMapAPI.LocatePos(
          coordinates,
          props.defaultZoom
        );
      }
    },
  }));

  const setCallback = (mapApi: any) => {
    mapApi.SetOnChangeUserPointWGS84Callback((data: any) => {
      props.changeCallback(data);
    }, false);
  };

  const createMap = (props: any) => {
    const store = getState();
    const URL = window.location.href;
    const mapFromConfig = get(
      store,
      'config.o2Config["funnelui.position.url"]',
      null
    );
    const mapUrl = URL === localhostUrl ? mapUrlLocalhost : mapFromConfig;
    const iframe: HTMLIFrameElement | any = document.querySelector('#map');

    // check if deeplink position exists and assign
    let deepLinkPosition: boolean | string = false;
    if (localStorage.getItem('coordX') && localStorage.getItem('coordY')) {
      deepLinkPosition = `JTSK;${localStorage.getItem(
        'coordX'
      )};${localStorage.getItem('coordY')}`;
    }

    if (iframe) {
      iframe.src = mapUrl;

      const checkMap = setInterval(() => {
        const MAP_API = iframe.contentWindow.ComMapAPI;
        if (MAP_API) {
          setMapLoaded(true);
          setCallback(MAP_API);

          if (deepLinkPosition) {
            MAP_API.LocatePos(deepLinkPosition, zoom);
            MAP_API.SetUserPoint(
              localStorage.getItem('coordX'),
              localStorage.getItem('coordY'),
              true
            );
          } else {
            if (coordX && coordY) {
              setPosition();
            }
          }

          clearInterval(checkMap);
        }
      }, 1000);
    }
  };

  return (
    <O2Indent>
      <iframe
        style={{ width: '100%', minHeight: 300 }}
        title='map'
        id='map'
        data-test-id='mapIframe'
        name='o2mapiframe'
      ></iframe>
    </O2Indent>
  );
});

export default Map;
