// @ts-nocheck
// import { toggleLocale } from 'src/redux/actions/configAction';
import { 
  // DEFAULT_LOCALE, 
  // getLocale, 
  // SECOND_LOCALE 
} from '../../../src/resourceUtils';
import React from 'react';
import { useSelector } from 'react-redux';
import Langtext from '../common/Langtext';

const Footer = (props: any) => {
  const customerCareLink = useSelector<any, any>(
    state => state.config.o2Config['link.customerCare']
  );

  const isJWTChecked = useSelector<any, any>(
    state => state.config.isJWTChecked
  );
  const slug = window.location.pathname;
  if (slug === '/funnel/') {
    if (!isJWTChecked) return null;
  }

  const openCookieSetting = () => {
    if (typeof __cmp === 'function') {
      __cmp('showGDPRScreenAdvanced');
    }
  }

  return (
    <div className='o2-footer'>
      <div className='o2-footer__width'>
        <ul className='o2-footer__menu'>
          {/*<li className='o2-footer__menu-item'>
            <a
              href='/'
              onClick={e => {
                e.preventDefault();
                toggleLanguage();
              }}
              className='o2-footer__link'
            >
              <Langtext
                text={`funnel.layout.footer.language.${
                  locale === 'cs' ? 'en' : 'cs'
                }`}
              />
            </a>
          </li> */}
          <li className='o2-footer__menu-item'>
            <a href={customerCareLink} className='o2-footer__link'>
              <Langtext text='funnel.layout.footer.careAndSupport' />
            </a>
          </li>
          <li className='o2-footer__menu-item'>
            <a
              href='https://www.o2.cz/osobni/202009-soukromi/'
              className='o2-footer__link'
            >
              <Langtext text='funnel.layout.footer.privacy' />
            </a>
          </li>
          <li className='o2-footer__menu-item'>
            <a href='https://www.o2.cz/spolecnost/' className='o2-footer__link'>
              <Langtext text='funnel.layout.footer.about' />
            </a>
          </li>
          <li className='o2-footer__menu-item'>
            <a
              href='https://www.o2.cz/spolecnost/236847-kontakty/'
              className='o2-footer__link'
            >
              <Langtext text='funnel.layout.footer.contacts' />
            </a>
          </li>
          <li className='o2-footer__menu-item'>
            <a
              href='https://www.o2.cz/firmy-a-organizace/podpora/zmeny-v-moje-o2'
              className='o2-footer__link'
            >
              <Langtext text='funnel.layout.footer.newsInMyO2' />
            </a>
          </li>
          <li className='o2-footer__menu-item'>
            <a
              href='https://www.o2active.cz/aplikace/mojeo2#_il=mojeo2-footerlink'
              className='o2-footer__link'
            >
              <Langtext text='funnel.layout.footer.downloadMyO2' />
            </a>
          </li>
          <li className='o2-footer__menu-item'>
            <a
              href='#top'
              onClick={openCookieSetting}
              className='o2-footer__link'
            >
              <Langtext text='funnel.layout.footer.cookie' />
            </a>
          </li>
        </ul>
        <div className='o2-footer__copy'>&#169; O2 Czech Republic a.s.</div>
      </div>
    </div>
  );
};

export default Footer;
