import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { O2Indent, O2Button } from 'o2-theme-react';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import Langtext from '../../../src/components/common/Langtext';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const Restart: React.FC = (props: any) => {
  const namePage = 'restart';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);

  const process = () => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
          },
          ttsParams: {
            ...ttsParams,
            flowType: 'TT_DEVICE_CONF',
            actionId: 'OK',
          },
        },
        props
      )
    );
  };

  return (
    <SimpleTextPage
      backlinkTo='/funnel/select-hdm-function'
      hasBacklink={true}
      title={<Langtext text='funnel.restart.title' />}
      backlink={<Langtext text='funnel.restart.topLink' />}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        {page.params.serviceId} - {page.params.modemName}
      </O2Indent>
      <O2Indent>
        <Langtext text='funnel.restart.text' />
      </O2Indent>
      <O2Indent standalone={true}>
        <O2Button color='important' onClick={() => process()}>
          <Langtext text='funnel.restart.restartBtn' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default Restart;
