import Langtext from '../../../src/components/common/Langtext';
import PageTitle from '../../../src/components/common/PageTitle';
import {
  O2Button,
  O2ControlWrapper,
  O2Indent,
  O2Modal,
  O2ModalBody,
  O2ModalFooter,
  O2ModalFooterButton,
  O2ModalHeader,
  O2Section,
  O2SelectEx,
  O2TextField,
} from 'o2-theme-react';
import React, { useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { useDispatch } from 'react-redux';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import MapAPI from '../../../src/api/mapApi';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const countries = [
  { value: '0', label: 'Česká Republika' },
  { value: '1', label: 'Slovensko' },
  { value: '2', label: 'Německo' },
  { value: '3', label: 'Uganda' },
];

interface AddressModalData {
  country?: string;
  postalCode?: string;
  cityPart?: string;
  city?: string;
  flatNum?: string;
  houseNum?: string;
  street?: string;
}

const Address: React.FC = props => {
  const namePage = 'address';
  const { formSubmit } = useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  //TODO: NO DONE LOGIC WITH FORM sending form data to BE!!!
  // eslint-disable-next-line
  const [name, setName] = useState('');
  // eslint-disable-next-line
  const [surname, setSurname] = useState('');
  // eslint-disable-next-line
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  // eslint-disable-next-line
  const [modalAddress, setModalAddress] = useState<AddressModalData>({});
  const [countrySelectValue, setCountrySelectValue] = useState('0');
  const [validationTypeAddress, setValidationTypeAddress] = useState<
    'warning' | false
  >(false);
  const [addresses, setAddresses] = useState([]);
  const [addressInput, setAddressInput] = useState('');

  const getAddresses = async (value: string) => {
    const url = `?mask=${value}&returnCoords=TRUE`;

    const result = await MapAPI.getAddressFromFragment(url);
    setAddresses(result.data);
  };

  const handleAddress = (value: any) => {
    setAddressInput(value);

    if (value.length > 2) {
      getAddresses(value);
    }
  };

  const selectAddress = (address: any) => {
    setAddress(address);
    setAddressInput(address);
    setAddresses([]);
  };

  const onKeyStandaloneAddress = () => {
    if (addressInput.length > 10 && addressInput !== '') {
      setValidationTypeAddress(false);
    } else {
      setValidationTypeAddress('warning');
    }
  };

  const submit = () => {
    formSubmit();
    console.log(namePage);
    console.log(name);
    console.log(surname);
    console.log(companyName);
    console.log(address);
    console.log(props);
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            firstname: name,
            surname,
            companyName,
            address,
            // actionId: 'SUBMIT',
          },
        },
        props
      )
    );
  };

  const addAddressManually = (obj: {
    name: string;
    value: string;
    key?: any;
  }) => {
    if (obj.name === 'country') {
      setCountrySelectValue(obj.key);
    }
    setModalAddress({
      ...modalAddress,
      [obj.name]: obj.value,
    });
  };

  const onSave = () => {
    let address = `${modalAddress.street ? modalAddress.street : ''} ${
      modalAddress.houseNum ? modalAddress.houseNum : ''
    }/${modalAddress.flatNum ? modalAddress.flatNum : ''} ${
      modalAddress.city ? modalAddress.city : ''
    } ${modalAddress.cityPart ? modalAddress.cityPart : ''} ${
      modalAddress.postalCode ? modalAddress.postalCode : ''
    }, ${modalAddress.country ? modalAddress.country : countries[0].label}`;
    setAddress(address);
    setAddressInput(address);
    setOpen(false);
  };

  return (
    <>
      <PageTitle>
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.address.title' />
      </PageTitle>
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Section>
        <O2Indent>
          <p>
            <Langtext text='funnel.address.text' />
          </p>
        </O2Indent>
        <O2Indent>
          <O2ControlWrapper>
            <O2TextField
              block
              indentBottom
              label={<Langtext text='funnel.address.firstName' />}
              onChange={e => setName(e.target.value)}
            />
          </O2ControlWrapper>
          <O2ControlWrapper>
            <O2TextField
              block
              indentBottom
              label={<Langtext text='funnel.address.lastName' />}
              onChange={e => setSurname(e.target.value)}
            />
          </O2ControlWrapper>
          <O2ControlWrapper>
            <O2TextField
              block
              indentBottom
              label={<Langtext text='funnel.address.companyName' />}
              labelNote={I18n.t('funnel.address.companyNameHint')}
              onChange={e => setCompanyName(e.target.value)}
            />
          </O2ControlWrapper>
          <O2ControlWrapper>
            <O2TextField
              block
              indentBottom
              label={<Langtext text='funnel.address.address' />}
              placeholder='Např. Na Petynce 123, Praha 6'
              onKeyDown={() => {
                onKeyStandaloneAddress();
              }}
              validationType={validationTypeAddress}
              validationText={
                validationTypeAddress
                  ? I18n.t('funnel.address.addressHint')
                  : ''
              }
              value={addressInput}
              onChange={e => handleAddress(e.target.value)}
            />
            {addresses.length > 0 && (
              <O2Indent
                top='close'
                style={{
                  border: '1px solid #ebebeb',
                  borderRadius: 5,
                  background: 'white',
                  marginTop: 0,
                }}
              >
                {addresses.map((address: any) => (
                  <p
                    style={{ padding: '5px 10px', cursor: 'pointer' }}
                    // onClick={() => selectAddress(address)}
                    onClick={() => selectAddress(address.address)}
                  >
                    {address.address}
                  </p>
                ))}
              </O2Indent>
            )}
          </O2ControlWrapper>
          <a
            href='/'
            className='o2-typo--link'
            onClick={e => {
              e.preventDefault();
              setOpen(!isOpen);
            }}
            style={{ fontSize: '0.9rem' }}
          >
            <Langtext text='funnel.address.tryAddressManually' />
          </a>
        </O2Indent>
        <O2Indent>
          <O2Button color='primary' onClick={() => submit()}>
            <Langtext text='funnel.address.continue' />
          </O2Button>
        </O2Indent>
      </O2Section>

      <O2Modal contentLeft isOpen={isOpen} toggle={() => setOpen(false)}>
        <O2ModalHeader>
          <Langtext text='funnel.address.modal.title' />
        </O2ModalHeader>
        <O2ModalBody style={{ overflowY: 'scroll', height: '450px' }}>
          <O2Indent top='close'>
            <O2ControlWrapper>
              <O2TextField
                label={<Langtext text='funnel.address.modal.street' />}
                controlSize='large'
                onChange={(e: any) =>
                  addAddressManually({ name: 'street', value: e.target.value })
                }
              />
            </O2ControlWrapper>
          </O2Indent>
          <O2Indent top='close'>
            <O2ControlWrapper inline>
              <O2TextField
                label={<Langtext text='funnel.address.modal.flatNum' />}
                labelNote={I18n.t('funnel.address.modal.flatNumHint')}
                controlSize='small'
                onChange={(e: any) =>
                  addAddressManually({ name: 'flatNum', value: e.target.value })
                }
              />
              <O2TextField
                label={<Langtext text='funnel.address.modal.houseNum' />}
                controlSize='small'
                onChange={(e: any) =>
                  addAddressManually({
                    name: 'houseNum',
                    value: e.target.value,
                  })
                }
              />
            </O2ControlWrapper>
          </O2Indent>
          <O2Indent top='close'>
            <O2ControlWrapper>
              <O2TextField
                label={<Langtext text='funnel.address.modal.city' />}
                controlSize='large'
                onChange={(e: any) =>
                  addAddressManually({ name: 'city', value: e.target.value })
                }
              />
            </O2ControlWrapper>
          </O2Indent>
          <O2Indent top='close'>
            <O2ControlWrapper>
              <O2TextField
                label={<Langtext text='funnel.address.modal.cityPart' />}
                controlSize='large'
                onChange={(e: any) =>
                  addAddressManually({
                    name: 'cityPart',
                    value: e.target.value,
                  })
                }
              />
            </O2ControlWrapper>
          </O2Indent>
          <O2Indent top='close'>
            <O2ControlWrapper inline>
              <O2TextField
                label={<Langtext text='funnel.address.modal.postalCode' />}
                controlSize='small'
                onChange={(e: any) =>
                  addAddressManually({
                    name: 'postalCode',
                    value: e.target.value,
                  })
                }
              />
              <O2SelectEx
                label={<Langtext text='funnel.address.modal.country' />}
                options={countries}
                controlSize='large'
                value={countrySelectValue}
                onChangeEx={(option: any) =>
                  addAddressManually({
                    name: 'country',
                    value: option.label,
                    key: option.value,
                  })
                }
              />
            </O2ControlWrapper>
          </O2Indent>
        </O2ModalBody>
        <O2ModalFooter>
          <O2ModalFooterButton
            icon='checkmark'
            color='success'
            onClick={() => onSave()}
          >
            <Langtext text='funnel.address.modal.saveBtn' />
          </O2ModalFooterButton>
          <O2ModalFooterButton icon='block' onClick={() => setOpen(false)}>
            <Langtext text='funnel.address.modal.canelBtn' />
          </O2ModalFooterButton>
        </O2ModalFooter>
      </O2Modal>
    </>
  );
};

export default Address;
