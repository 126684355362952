import {
  O2Indent,
  O2Section,
  O2Button,
  O2ControlWrapper,
  O2DateField,
  O2Checkbox,
  O2Loader,
} from 'o2-theme-react';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import PageTitle from '../../../src/components/common/PageTitle';
import Langtext from '../../../src/components/common/Langtext';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import { I18n } from 'react-i18nify-lite';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const SetTerm: React.FC = (props: any) => {
  const namePage = 'setTerm';
  const { formSubmit } = useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const dispatch = useDispatch();

  const [date, setDate] = useState('');
  const [morning, setMorning] = useState(false);
  const [afternoon, setAfternoon] = useState(false);
  const [allowMorning, setAllowMorning] = useState(false);
  const [allowAfternoon, setAllowAfternoon] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const todayMidnight = new Date(new Date().setHours(0, 0, 0, 0));
  const now = new Date(Date.now());

  const [calendarShow, setCalendarShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCalendarShow(true);
    }, 1000);
    termScheduleControl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    termScheduleControl();
    if ((morning || afternoon) && date !== '') {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afternoon, morning, date]);

  const termScheduleControl = () => {
    if (date !== '') {
      const splitDate = date.split('.');
      const chossenDate = new Date(
        parseInt(splitDate[2]),
        parseInt(splitDate[1]) - 1,
        parseInt(splitDate[0])
      );

      if (chossenDate.getDate() === now.getDate()) {
        if (now.getHours() < 8) {
          setAllowMorning(true);
          setAllowAfternoon(true);
        } else if (now.getHours() > 8 && now.getHours() < 15) {
          setAllowMorning(false);
          setAllowAfternoon(true);
        } else if (now.getHours() > 15) {
          setAllowMorning(false);
          setAllowAfternoon(false);
        }
      } else if (chossenDate.getTime() > todayMidnight.getTime()) {
        setAllowMorning(true);
        setAllowAfternoon(true);
      } else {
        setAllowMorning(false);
        setAllowAfternoon(false);
      }
    }
  };

  const handleDate = (e: any) => {
    setAfternoon(false);
    setMorning(false);
    setDate(e.target.value);
  };

  const searchTerms = () => {
    formSubmit();
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
            morning,
            afternoon,
            date,
          },
          ttsParams: {
            ...ttsParams,
            actionId: 'SEARCH_TERM',
            flowType: 'TT_TERM_CHANGE',
            ttId: page.troubleId,
          },
        },
        props
      )
    );
  };

  const renderCalendar = () => {
    if (calendarShow) {
      return (
        <O2Indent>
          <O2ControlWrapper>
            <O2DateField
              data-test-id='dateInput'
              label={<Langtext text='funnel.setTerm.selectDateLabel' />}
              note={I18n.t('funnel.setTerm.dateFormatNote')}
              onChange={e => handleDate(e)}
              dateFormat={'dd.MM.yyyy'}
            />
          </O2ControlWrapper>
        </O2Indent>
      );
    } else {
      return (
        <O2Indent>
          <O2ControlWrapper>
            <O2Loader />
          </O2ControlWrapper>
        </O2Indent>
      );
    }
  };

  return (
    <>
      <PageTitle>
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.setTerm.title' />
      </PageTitle>

      <O2Section>
        {renderCalendar()}
        <O2Indent>
          <O2Indent top='close'>
            <O2ControlWrapper>
              <O2Checkbox
                disabled={!allowMorning}
                checked={morning}
                data-test-id='morningCheckbox'
                label={<Langtext text='funnel.setTerm.morning' />}
                onChange={() => setMorning(!morning)}
              />
            </O2ControlWrapper>
          </O2Indent>
          <O2Indent top='close'>
            <O2ControlWrapper>
              <O2Checkbox
                disabled={!allowAfternoon}
                checked={afternoon}
                data-test-id='afternoonCheckbox'
                label={<Langtext text='funnel.setTerm.afternoon' />}
                onChange={() => setAfternoon(!afternoon)}
              />
            </O2ControlWrapper>
          </O2Indent>
        </O2Indent>
      </O2Section>
      <O2Section>
        <O2Button
          color='primary'
          disabled={!allowSubmit}
          onClick={() => searchTerms()}
          data-test-id='submitButton'
        >
          <Langtext text='funnel.setTerm.btnLabel' />
        </O2Button>
      </O2Section>
    </>
  );
};

export default SetTerm;
