export const outagesServicesMock = (imagesPath: string) => {
  return {
    cards: [
      {
        title: 'funnel.outagesSelectService.fixedServices',
        image: imagesPath + 'ico-desktop.png',
        type: 'fixed',
      },
      {
        title: 'funnel.outagesSelectService.mobileServices',
        image: imagesPath + 'ico-mobile.png',
        type: 'mobile',
      },
    ],
  };
};
