import { O2Indent } from 'o2-theme-react';
import React from 'react';
import Langtext from '../../../src/components/common/Langtext';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const ExtendedTime: React.FC = (props: any) => {
  const namePage = 'saved';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();

  return (
    <SimpleTextPage
      hasBacklink={false}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <Langtext text='funnel.saved.text' />
      </O2Indent>
    </SimpleTextPage>
  );
};

export default ExtendedTime;
