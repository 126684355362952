import { applyMiddleware, createStore, Store, AnyAction } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '../reducers';
import StoreState from './storeState';

let store: Store<StoreState>;

export const initializeStore = () => {
  store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
  return store;
};

export const getStore = () => {
  return store;
};

export const getState = (): StoreState => {
  return store.getState();
};

export const thunkDispatch = () =>
  store.dispatch as ThunkDispatch<StoreState, void, AnyAction>;
