import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { O2Indent, O2Wrap, O2Loader } from 'o2-theme-react';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import queryString from 'query-string';
import {
  getApiPath,
  composeQueryParamUrl,
  removeParamFromUrl,
} from '../../../src/api/requestUtils';
import useWaitingPageModificator from '../../../src/hooks/useWaitingPageModificator';
import usePersistQueryParams from '../../../src/hooks/usePersistQueryParams';
import getURIParams from '../../utils/getUriParams';

const CreateTicket = (props: any) => {
  const dispatch = useDispatch();
  const {
    serviceId,
    troubleId,
    contactPhone,
    token,
    worklog,
    x,
    y,
  } = getURIParams('/funnel/deeplink/createticket/');
  const location = useLocation();
  const history = useHistory();
  const [hasRelevantData, setHasRelevantData] = useState<boolean>(false);
  const user = useSelector<any, any>(state => state.user);
  const sharedObjects = useSelector<any, any>(state => state.sharedObjects);
  const o2Config = useSelector<any, any>(state => state.config.o2Config);
  const { jwtChecked } = queryString.parse(location.search);
  const transparentWaiting = useWaitingPageModificator();
  const { url } = usePersistQueryParams();

  //mám načteny všechny prerekvizity + se zavolaly shared objects pokud mají segment services se nevolali pokud ne tak volali
  //data jsou relevantní k vyhodnocení pokud mám přítomný segment pokud ne tak pokud jsem provolal a mám výsledek z services
  useEffect(() => {
    if (
      !user.loading &&
      o2Config !== null &&
      sharedObjects?.isLoading === false &&
      ((sharedObjects?.sharedObjectSession?.customer &&
        sharedObjects?.sharedObjectSession?.customer.segment) ||
        user.userService !== null)
    ) {
      setHasRelevantData(true);
    }
  }, [user, o2Config, sharedObjects]);

  useEffect(() => {
    if (jwtChecked === undefined || jwtChecked !== 'true') {
      const API_PATH = getApiPath('auth/casLogin');
      const QUERY_PARAMS = composeQueryParamUrl({
        redirectTo: encodeURIComponent(
          removeParamFromUrl(window.location.href, 'token') +
            composeQueryParamUrl({ jwtChecked: true })
        ),
        autologinToken: token ?? undefined,
      });
      const REDIRECT_LINK = `${API_PATH}${QUERY_PARAMS}`;

      window.location.href = REDIRECT_LINK;
    }
    if (jwtChecked === 'true' && hasRelevantData) {
      if (
        !serviceId &&
        (sharedObjects?.sharedObjectSession?.customer?.segment === 'CORP' ||
          sharedObjects?.sharedObjectSession?.customer?.segment === 'GOV')
      ) {
        history.push('/funnel/not-supported-customer');
      } else {
        dispatch(
          ttsProcess(
            {
              page: 'main',
              params: {
                action: 'createTrouble',
                serviceId,
                troubleSymptomId: troubleId,
                contactPhone,
                ...(worklog && { worklog: decodeURIComponent(worklog) }),
                coordinateX: x,
                coordinateY: y,
              },
              ...(troubleId && {
                ttsParams: {
                  flowType: 'TT_TROUBLE',
                },
              }),
            },
            props,
            false,
            url ? url : false
          )
        );
      }
    }
    // eslint-disable-next-line
  }, [hasRelevantData]);

  return (
    <>
      <O2Indent standalone>
        <O2Wrap center>
          <O2Loader />
        </O2Wrap>
      </O2Indent>
      {transparentWaiting}
    </>
  );
};

export default CreateTicket;
