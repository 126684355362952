import { O2Indent, O2Button, O2Section, O2ButtonGroup } from 'o2-theme-react';
import React from 'react';
import { useSelector } from 'react-redux';
import useHandleInappropriateRefresh from '../../hooks/useHandleInappropriateRefresh';
import PageTitle from '../common/PageTitle';
import JournalApi from '../../api/journalApi';
import Langtext from '../common/Langtext';
import useAnalytics from '../../hooks/useAnalytics';

const Block: React.FC = props => {
  const namePage = 'block';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const user = useSelector<any, any>(state => state.user);

  const blockLink = useSelector<any, any>(
    state => state.config.o2Config['link.blockSIM']
  );

  const journalApi = new JournalApi();

  const serviceId = useSelector<any, any>(
    state => state.pages.pages.block.params.serviceId
  );
  const tpRefNo = useSelector<any, any>(
    state => state.pages.pages.block.params.tpRefNo
  );

  const redirect = () => {
    journalApi.pingJournal({
      event: 'SIM_CARD_BLOCK_REDIRECT',
      username: user?.userInfo?.username,
      params: {
        serviceId,
      },
    });
    window.location.href = blockLink + tpRefNo;
  };

  return (
    <>
      <PageTitle>
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.block.title' />
      </PageTitle>
      <O2Section>
        <O2Indent>
          <Langtext text='funnel.block.text1' />
          <br />
          <br />
          <Langtext text='funnel.block.text2' />
        </O2Indent>
        <O2Indent>
          <O2ButtonGroup>
            <O2Button indentRight color='primary' onClick={() => redirect()}>
              <Langtext text='funnel.block.buttonText' />
            </O2Button>
          </O2ButtonGroup>
        </O2Indent>
      </O2Section>
    </>
  );
};

export default Block;
