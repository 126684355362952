import SessionApi from '../../api/sessionApi';
import { Config } from '../reducers/types';
import get from 'lodash/get';
import { getLocale } from '../../../src/resourceUtils';
import { I18n } from 'react-i18nify-lite';

export type Action =
  | {
      type: 'INIT_APP';
      config: Config;
    }
  | {
      type: 'TOGGLE_LANG';
      lang: string;
    };

export const initApp = (): any => {
  return (dispatch: any) => {
    SessionApi.getConfig()
      .then((res: any) => {
        const response = get(res, 'data', null);
        if (response) {
          dispatch(initAppSuccess(response));
        }
        // TODO: add error handling
      })
      .catch((err: any) => console.log(err));
  };
};

export const checkJWT = () => {
  return {
    type: 'CHECK_JWT',
  };
};

export const initAppSuccess = (response: any): any => {
  return {
    type: 'INIT_APP',
    config: {
      lang: getLocale(),
      captchaKey: process.env.CAPTCHA_KEY,
      o2Config: response ? response : null,
    },
  };
};

export const toggleLocale = (switchTo: string): any => {
  var now = new Date();
  now.setTime(now.getTime() + 48 * 3600 * 1000);
  document.cookie = `locale=${switchTo};${now.toUTCString()};path=/`;

  I18n.setLocale(switchTo);
  return {
    type: 'TOGGLE_LANG',
    config: {
      lang: switchTo,
    },
  };
};
