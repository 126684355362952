import { O2Indent, O2Button } from 'o2-theme-react';
import React from 'react';

import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import { useSelector, useDispatch } from 'react-redux';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const SelectSolution: React.FC = props => {
  const namePage = 'selectSolution';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);

  const process = (actionId: string) => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
          },
          ttsParams: {
            ...ttsParams,
            actionId,
          },
        },
        props
      )
    );
  };

  return (
    <SimpleTextPage
      title={<Langtext text='funnel.selectSolution.title' />}
      hasBacklink={false}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <Langtext text='funnel.selectSolution.description' />
      </O2Indent>
      <O2Indent>
        <O2Button indentRight onClick={() => process('FREE')}>
          <Langtext text={'funnel.selectSolution.freeBtn'} />
        </O2Button>
        <O2Button onClick={() => process('PAYED')}>
          <Langtext text={'funnel.selectSolution.payedBtn'} />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default SelectSolution;
