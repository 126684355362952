import HTMLBody from '../../../src/components/common/HTMLBody';
import Langtext from '../../../src/components/common/Langtext';
import PageTitle from '../../../src/components/common/PageTitle';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import get from 'lodash/get';
import { O2Button, O2Indent, O2Section } from 'o2-theme-react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const SolutionWithoutInteraction: React.FC = (props: any) => {
  const namePage = 'solutionWithoutInteraction';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);
  const [destinationId] = useState(get(page, 'ttsParams.destinationId', null));
  const [saveAllowed] = useState(get(page, 'params.saveAllowed', true));
  const [solution, setSolution] = useState(false);

  const proceed = (actionId: string) => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId: page.params.serviceId,
          },
          ttsParams: {
            ...ttsParams,
            actionId,
          },
        },
        props
      )
    );
  };

  return (
    <>
      <PageTitle>
        {destinationId ? (
          <Langtext
            text={`funnel.codelist.destinationToTitle.${destinationId}`}
          />
        ) : (
          <Langtext text='funnel.solutionWithoutInteraction.title' />
        )}
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
      </PageTitle>
      <O2Section>
        {!solution && !saveAllowed && (
          <O2Indent className='js-toggle-target'>
            <O2Indent>
              <Langtext text='funnel.solutionWithoutInteraction.text' />
            </O2Indent>
            <O2Indent>
              <O2Button
                color='primary'
                indentRight
                data-test-id='goButton'
                onClick={() => setSolution(true)}
              >
                <Langtext text='funnel.solutionWithoutInteraction.agreeBtn' />
              </O2Button>
              <O2Button
                color='internal'
                data-test-id='saveButton'
                onClick={() => proceed('SAVE')}
              >
                <Langtext text='funnel.solutionWithoutInteraction.laterBtn' />
              </O2Button>
            </O2Indent>
          </O2Indent>
        )}

        {(solution || saveAllowed) && (
          <O2Indent className='js-toggle-target'>
            <O2Indent>
              {destinationId && <HTMLBody id={destinationId} />}
            </O2Indent>

            <O2Indent>
              <O2Indent>
                <h2 className='o2-typo--heading'>
                  <Langtext text='funnel.solutionWithoutInteraction.isFixed' />
                </h2>
              </O2Indent>
              <O2Indent>
                <O2Button
                  color='transparent'
                  data-test-id='saveButton'
                  onClick={() => proceed('SAVE')}
                >
                  <Langtext text='funnel.solutionWithoutInteraction.postponeBtn' />
                </O2Button>
              </O2Indent>
            </O2Indent>
          </O2Indent>
        )}
      </O2Section>
    </>
  );
};

export default SolutionWithoutInteraction;
