import React, { useState, useEffect } from 'react';
import {
  O2Indent,
  O2Section,
  O2Field,
  O2FieldContent,
  O2FieldView,
  O2InlineState,
  O2FieldEdit,
  O2Radio,
  O2TextField,
  O2Button,
} from 'o2-theme-react';
import { useSelector, useDispatch } from 'react-redux';
import PageTitle from '../../../src/components/common/PageTitle';
import { I18n } from 'react-i18nify-lite';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import shortid from 'shortid';
import Langtext from '../../../src/components/common/Langtext';
import get from 'lodash/get';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const ViewWifi: React.FC = (props: any) => {
  const namePage = 'viewWifi';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const ttsParams = useSelector<any, any>(state => state.pages.ttsParams);
  const dispatch = useDispatch();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const serviceId = useSelector<any, any>(state => state.pages.selectedValueId);

  const isActive = false;
  const [status, setStatus] = useState(
    I18n.t(
      `funnel.codelist.hdmSsidEnabledToText.${get(
        page,
        'params.ssidEnabled',
        null
      )}`
    )
  );
  const [statusId, setStatusId] = useState(
    get(page, 'params.ssidEnabled', null)
  );
  const [wifiName, setWifiName] = useState(get(page, 'params.ssid', null));
  const [safetyLevel, setSafetyLevel] = useState(
    page.params.networkAuthentication
  );
  const [password, setPassword] = useState(page.params.key);

  useEffect(() => {
    setStatusComplex(page.params.ssidEnabled);
    setWifiName(page.params.ssid);
    setSafetyLevel(page.params.networkAuthentication);
    setPassword(page.params.key);
  }, [
    page.params.ssidEnabled,
    page.params.ssid,
    page.params.networkAuthentication,
    page.params.key,
  ]);

  const setStatusComplex = (statusId: string) => {
    setStatus(I18n.t(`funnel.codelist.hdmSsidEnabledToText.${statusId}`));
    setStatusId(statusId);
  };

  const switchConfig = () => {
    dispatch(
      ttsProcess(
        {
          page: namePage,
          params: {
            serviceId,
          },
          ttsParams: {
            ...ttsParams,
            flowType: 'TT_DEVICE_CONF',
            actionId: 'OK',
          },
        },
        props
      )
    );
  };

  return (
    <>
      <PageTitle
        backlink={<Langtext text='funnel.viewWifi.topLink' />}
        to='/funnel/select-hdm-function'
      >
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
        <Langtext text='funnel.viewWifi.title' />
      </PageTitle>
      <O2Section>
        <O2Indent>
          {serviceId} - {page.params.modemName}
        </O2Indent>
        <O2Indent>
          {status && (
            <>
              <O2Field
                label={I18n.t('funnel.viewWifi.status')}
                active={isActive}
              >
                <O2FieldContent>
                  <O2FieldView>
                    <O2InlineState
                      state={statusId === '1' ? 'active' : 'error'}
                    >
                      {status}
                    </O2InlineState>
                  </O2FieldView>
                  <O2FieldEdit>
                    <O2Indent top='close'>
                      <O2Radio
                        checked={statusId === '1' ? true : false}
                        data-test-id='ssidEnabledRadio'
                        label={
                          <Langtext
                            text={`funnel.codelist.hdmSsidEnabledToText.1`}
                          />
                        }
                        name='o2-99-11-1-radio-state'
                        onClick={() => setStatusComplex('1')}
                      />
                    </O2Indent>
                    <O2Indent top='close'>
                      <O2Radio
                        checked={statusId === '0' ? true : false}
                        data-test-id='ssidEnabledRadio'
                        label={
                          <Langtext
                            text={`funnel.codelist.hdmSsidEnabledToText.0`}
                          />
                        }
                        name='o2-99-11-1-radio-state'
                        onClick={() => setStatusComplex('0')}
                      />
                    </O2Indent>
                  </O2FieldEdit>
                </O2FieldContent>
              </O2Field>
            </>
          )}
          <O2Field active={isActive} label={I18n.t('funnel.viewWifi.wifiName')}>
            <O2FieldContent>
              <O2FieldView>{wifiName}</O2FieldView>
              <O2FieldEdit>
                <O2TextField
                  data-test-id='ssidInput'
                  note='Pod tímto názvem naleznete svou Wi-Fi síť.'
                  value={wifiName}
                  onChange={e => setWifiName(e.target.value)}
                />
              </O2FieldEdit>
            </O2FieldContent>
          </O2Field>
          <O2Field label={I18n.t('funnel.viewWifi.security')} active={isActive}>
            <O2FieldContent>
              <O2FieldView>
                <O2Indent top='close'>
                  <span className='o2-typo--indent-right-close'>
                    <Langtext
                      text={`funnel.codelist.hdmNetworkAuthenticationToText.${safetyLevel}`}
                    />
                  </span>
                  {safetyLevel === 'High' && (
                    <span className='o2-typo--weight-minor'>WPA2 Personal</span>
                  )}
                </O2Indent>
                <O2Indent top='close'>
                  <ul className='o2-typo--weight-minor'>
                    {page.safetyParams
                      .filter(
                        (param: { type: string; text: string }) =>
                          param.type === safetyLevel
                      )
                      .map((param: { type: string; text: string }) => (
                        <li key={shortid.generate()}>
                          <Langtext text={param.text} />
                        </li>
                      ))}
                  </ul>
                </O2Indent>
              </O2FieldView>
              <O2FieldEdit>
                <O2Indent top='close'>
                  <O2Radio
                    checked={safetyLevel === 'High' ? true : false}
                    label={
                      <Langtext
                        text={`funnel.codelist.hdmNetworkAuthenticationToText.High`}
                      />
                    }
                    data-test-id='networkAuthenticationRadio'
                    name='o2-99-11-1-radio-security'
                    onClick={() => setSafetyLevel('High')}
                  />
                  <div className='o2-typo--indent-left-checkbox o2-typo--weight-minor'>
                    <ul>
                      {page.safetyParams
                        .filter(
                          (param: { type: string; text: string }) =>
                            param.type === 'High'
                        )
                        .map((param: { type: string; text: string }) => (
                          <li key={shortid.generate()}>
                            <Langtext text={param.text} />
                          </li>
                        ))}
                    </ul>
                  </div>
                </O2Indent>
                <O2Indent top='close'>
                  <O2Radio
                    checked={safetyLevel === 'None' ? true : false}
                    data-test-id='networkAuthenticationRadio'
                    label={
                      <Langtext
                        text={`funnel.codelist.hdmNetworkAuthenticationToText.None`}
                      />
                    }
                    name='o2-99-11-1-radio-security'
                    onClick={() => setSafetyLevel('None')}
                  />
                  <div className='o2-typo--indent-left-checkbox o2-typo--weight-minor'>
                    <ul>
                      {page.safetyParams
                        .filter(
                          (param: { type: string; text: string }) =>
                            param.type === 'None'
                        )
                        .map((param: { type: string; text: string }) => (
                          <li key={shortid.generate()}>
                            <Langtext text={param.text} />
                          </li>
                        ))}
                    </ul>
                  </div>
                </O2Indent>
                <O2Indent top='close'>
                  <O2Radio
                    checked={safetyLevel === 'Low' ? true : false}
                    data-test-id='networkAuthenticationRadio'
                    label={
                      <Langtext
                        text={`funnel.codelist.hdmNetworkAuthenticationToText.Low`}
                      />
                    }
                    name='o2-99-11-1-radio-security'
                    onClick={() => setSafetyLevel('Low')}
                  />
                </O2Indent>
              </O2FieldEdit>
            </O2FieldContent>
          </O2Field>
          <O2Field label={I18n.t('funnel.viewWifi.password')} active={isActive}>
            <O2FieldContent>
              <O2FieldView>{password}</O2FieldView>
              <O2FieldEdit>
                <O2TextField
                  data-test-id='keyInput'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </O2FieldEdit>
            </O2FieldContent>
          </O2Field>
        </O2Indent>
        <O2Indent>
          <O2Button
            indentRight={true}
            onClick={() => switchConfig()}
            data-test-id='submitButton'
          >
            <Langtext text='funnel.viewWifi.changeBtn' />
          </O2Button>
        </O2Indent>
      </O2Section>
    </>
  );
};

export default ViewWifi;
