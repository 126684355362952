import { O2Indent, O2Button } from 'o2-theme-react';
import React, { useState } from 'react';

import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import { useSelector } from 'react-redux';
import HTMLBody from '../../../src/components/common/HTMLBody';
import get from 'lodash/get';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const BrokenModem: React.FC = props => {
  const namePage = 'brokenDevice';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const [destinationId] = useState(
    get(page, 'ttsParams.destinationId', 'F0440')
  );
  const destinationToOption1Link = useSelector<any, any>(
    state => state.appCache.codeLists['destinationToOption1Link']
  );
  const destinationToOption2Link = useSelector<any, any>(
    state => state.appCache.codeLists['destinationToOption2Link']
  );

  const redirect = (link: string) => {
    window.location.href = link;
  };

  return (
    <SimpleTextPage
      title={
        destinationId ? (
          <Langtext
            text={`funnel.codelist.destinationToTitle.${destinationId}`}
          />
        ) : (
          <Langtext text='funnel.brokenDevice.title' />
        )
      }
      hasBacklink={false}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        {destinationId && <HTMLBody id={destinationId} />}
        {!destinationId && <Langtext text='funnel.brokenDevice.description' />}
      </O2Indent>
      <O2Indent>
        <O2Button
          indentRight
          onClick={() => redirect(destinationToOption1Link[destinationId])}
        >
          <Langtext
            text={`funnel.codelist.destinationToButton1Text.${destinationId}`}
          />
        </O2Button>
        <O2Button
          onClick={() => redirect(destinationToOption2Link[destinationId])}
        >
          <Langtext
            text={`funnel.codelist.destinationToButton2Text.${destinationId}`}
          />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default BrokenModem;
