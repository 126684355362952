import React from 'react';
import PageTitle from '../../../src/components/common/PageTitle';
import Langtext from '../../../src/components/common/Langtext';
import useAnalytics from '../../../src/hooks/useAnalytics';

const NonAuthorized: React.FC = () => {
  const namePage = 'nonAuthorized';
  useAnalytics(namePage);
  return (
    <PageTitle
      description={<Langtext text='funnel.nonAuthorized.description' />}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <Langtext text='funnel.nonAuthorized.title' />
    </PageTitle>
  );
};

export default NonAuthorized;
