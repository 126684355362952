import { O2Indent } from 'o2-theme-react';
import React from 'react';

import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import Langtext from '../../../src/components/common/Langtext';
import { useSelector } from 'react-redux';

const InternalNoContext: React.FC = () => {
  const namePage = 'noContext';
  let wdeLink = useSelector<any, any>(
    state => state.config.o2Config['link.internalUserContext']
  );

  return (
    <SimpleTextPage title={<Langtext text='funnel.internalNoContext.title' />}>
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <p>
          <Langtext text='funnel.internalNoContext.infotext' />{' '}
          <a href={wdeLink} data-test-id='lintToContext'>
            <Langtext text='funnel.internalNoContext.linkText' />
          </a>
          .
        </p>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default InternalNoContext;
