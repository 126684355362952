import { O2Indent, O2Button } from 'o2-theme-react';
import React from 'react';
import { useSelector } from 'react-redux';
import Langtext from '../../../src/components/common/Langtext';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import JournalApi from '../../../src/api/journalApi';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';

const PUK: React.FC = () => {
  const namePage = 'puk';
  useAnalytics(namePage);
  useHandleInappropriateRefresh();

  const user = useSelector<any, any>(state => state.user);

  const pukLink = useSelector<any, any>(
    state => state.config.o2Config['link.displayPUK']
  );
  const journalApi = new JournalApi();

  const serviceId = useSelector<any, any>(
    state => state.pages.pages.puk.params.serviceId
  );
  const tpRefNo = useSelector<any, any>(
    state => state.pages.pages.puk.params.tpRefNo
  );

  const redirect = () => {
    journalApi.pingJournal({
      event: 'PUK_VIEW_REDIRECT',
      username: user?.userInfo?.username,
      params: {
        serviceId,
      },
    });
    window.location.href = pukLink + tpRefNo;
  };

  return (
    <SimpleTextPage
      title={<Langtext text='funnel.PUK.title' />}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <Langtext text='funnel.PUK.text' />
      </O2Indent>
      <O2Indent>
        <O2Button color='internal' onClick={() => redirect()}>
          <Langtext text='funnel.PUK.btn' />
        </O2Button>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default PUK;
