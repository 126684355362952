import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import { O2Indent, O2Loader, O2Wrap } from 'o2-theme-react';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { 
  getApiPath, 
  composeQueryParamUrl, 
  removeParamFromUrl, 
} from '../../../src/api/requestUtils';
import useWaitingPageModificator from '../../../src/hooks/useWaitingPageModificator';
import usePersistQueryParams from '../../../src/hooks/usePersistQueryParams';
import getURIParams from '../../utils/getUriParams';


const CreateTTWithMap = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    token,
    x,
    y,
  } = getURIParams('/funnel/deeplink/createTTwithMap/');
  const history = useHistory();
  const [hasRelevantData, setHasRelevantData] = useState<boolean>(false);
  const user = useSelector<any, any>(state => state.user);
  const o2Config = useSelector<any, any>(state => state.config.o2Config);
  const { jwtChecked } = queryString.parse(location.search);
  const transparentWaiting = useWaitingPageModificator();
  const { url } = usePersistQueryParams();

  const executeProcess = () => {
    // console.log('@@DEBUG_CREATETTWITHMAP :: executing process', user);
    if (x && y) {
      // console.log('@@DEBUG_CREATETTWITHMAP :: i have x, y - process success', user);
      localStorage.setItem('coordX', x);
      localStorage.setItem('coordY', y);
      dispatch(
        ttsProcess(
          {
            page: 'main',
            params: {
              action: 'createTrouble',
              coordinateX: x,
              coordinateY: y,
            },
          },
          props,
          false,
          url ? url : false
        )
      );
    } else {
      // console.log('@@DEBUG_CREATETTWITHMAP :: dont have x,y, go to finalPage', user);
      history.push('/funnel');
    }
  };

  useEffect(() => {
    if (!user.loading && o2Config !== null) {
      setHasRelevantData(true);
    }
  }, [user, o2Config]);

  useEffect(() => {
    localStorage.setItem('coordX', x ? x : '0');
    localStorage.setItem('coordY', y ? y : '0');
  }, [x, y]);

  useEffect(() => {
    if (jwtChecked === undefined || jwtChecked !== 'true') {
      // console.log('@@DEBUG_CREATETTWITHMAP :: dont have jwt, to be checked', user);
      const API_PATH = getApiPath('auth/casLogin');
      const QUERY_PARAMS = composeQueryParamUrl({
        redirectTo: encodeURIComponent(
          removeParamFromUrl(window.location.href, 'token') + 
          composeQueryParamUrl({ jwtChecked: true })
        ),
        autologinToken: token ?? undefined,
      });
      const REDIRECT_LINK = `${API_PATH}${QUERY_PARAMS}`;

      window.location.href = REDIRECT_LINK;
    }
    if (jwtChecked === 'true' && hasRelevantData) {
      // console.log('@@DEBUG_CREATETTWITHMAP :: i have relevant data', user);
      executeProcess();
    }
    // eslint-disable-next-line
  }, [hasRelevantData]);

  return (
    <>
      <O2Indent standalone>
        <O2Wrap center>
          <O2Loader />
        </O2Wrap>
      </O2Indent>
      {transparentWaiting}
    </>
  );
};

export default CreateTTWithMap;
