import CodeListAPI from '../../api/codeListApi';

// TODO: add interfaces
export const getCodeListsSuccess = (data: any): any => {
  return {
    type: 'GET_CODELISTS_SUCCESS',
    payload: data,
  };
};

export const getCodeLists = (): any => {
  const api = new CodeListAPI();
  return (dispatch: any) => {
    const endpoints = api.getCodeListEndpoints();
    Object.values(endpoints).forEach((value: any) => {
      api
        .codeListCall(value)
        .then(res => {
          dispatch(getCodeListsSuccess({ [value]: res.data }));
        })
        .catch(err => console.log(err));
    });
  };
};
