import Langtext from '../../../src/components/common/Langtext';
import SimpleTextPage from '../../../src/components/common/SimpleTextPage';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import {
  O2Button,
  O2ControlWrapper,
  O2Indent,
  O2Pane,
  O2TextField,
} from 'o2-theme-react';
import React, { useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { useDispatch } from 'react-redux';
import useAnalytics from '../../../src/hooks/useAnalytics';

const OutagesSelectNumber: React.FC = (props: any) => {
  const namePage = 'outagesSelectNumber';
  const { formSubmit } = useAnalytics(namePage);
  const dispatch = useDispatch();
  const [validation, setValidation] = useState<any>({ valid: false });
  const [phone, setPhone] = useState<any>('');

  const validate = (value: string) => {
    // eslint-disable-next-line
    const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]{8,}$/;
    const valid = regex.exec(value);
    if (!valid) {
      setValidation({
        valid: false,
        type: 'error',
        message: I18n.t('funnel.outagesSelectNumber.phoneNumErr'),
      });
      return false;
    } else {
      setValidation({
        valid: true,
      });
      return true;
    }
  };

  const handleChange = (value: string) => {
    setPhone(value.trim());
    if (!validation.valid && validation.type === 'error') {
      validate(value);
    }
  };

  const process = () => {
    formSubmit();
    const isValid = validate(phone);
    setTimeout(() => {
      if (isValid) {
        dispatch(
          ttsProcess(
            {
              page: `${namePage}`,
              params: {
                serviceId: `${phone}`,
              },
            },
            props
          )
        );
      }
    }, 500);
  };

  return (
    <SimpleTextPage
      title={<Langtext text='funnel.outagesSelectNumber.title' />}
      hasBacklink={false}
    >
      <span style={{ display: 'none' }} data-test-id='pageId'>
        {namePage}
      </span>
      <O2Indent>
        <O2Pane color='white'>
          <O2Indent>
            <h4 className='o2web-typo__heading'>
              <Langtext text='funnel.outagesSelectNumber.headline' />
            </h4>
          </O2Indent>
          <O2Indent>
            <O2ControlWrapper>
              <O2TextField
                onChange={e => handleChange(e.target.value)}
                placeholder='Např. 354435303'
                validationType={validation.type}
                validationText={validation.message}
              />
            </O2ControlWrapper>
          </O2Indent>
          <O2Indent>
            <O2Button color='primary' onClick={() => process()}>
              <Langtext text='funnel.outagesSelectNumber.readyBtn' />
            </O2Button>
          </O2Indent>
        </O2Pane>
      </O2Indent>
    </SimpleTextPage>
  );
};

export default OutagesSelectNumber;
